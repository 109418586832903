import 'array-flat-polyfill';
import 'url-search-params-polyfill';
import 'velocity-animate';
import 'velocity-animate/velocity.ui';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import axios from 'axios';
import {
  initiateDataDogSession,
  initLogs,
} from 'src/components/App/actions/dataDogHelper';
import checkVersionByInterval from 'src/common/utils/SystemVersion/utils/checkVersionByInterval';
import {
  applicationLoadFromSessionStorage,
  fetchBootstrapEnums,
  setCurrentGroup,
  setCurrentNetwork,
  userLoadFromSessionStorage,
  setAuthSession,
} from 'src/common/utils/Session/actions';
import { logoutUser } from 'actions/Login';
import { GLOBAL_STATE_SESSION_STORAGE_KEY } from 'src/reducers/globalReducer';
import { extendLodash } from '@unite-us/client-utils';
import { AppContainer } from 'react-hot-loader';
import {
 LAUNCH_DARKLY_KEY,
 ROUTE_BASEPATH,
 AUTH_URL,
 CLIENT_ID,
 DATADOG_LOGS_ENABLED,
} from 'src/config/env/env.config';
import { get as getCookie, remove as removeCookie, set as setCookie } from 'es-cookie';
import fetchEmployees from 'src/api/core/Employees/fetchEmployees';
import fetchRoles from 'src/api/core/Roles/fetchRoles';
import fetchMyProviders from 'src/actions/Login/fetchMyProviders';
import { updateGlobalState } from 'src/actions/Global/globalActions';
import 'src/styles/main.scss';
// eslint-disable-next-line
import 'src/styles/tailwind.css';
import 'src/styles/main-additions.scss';
import trackThunks from 'src/common/middlewares/TrackThunks';
import reducers from 'src/reducers';
import ProviderUS from 'src/components/App/ProviderUS';
import FeatureFlags from 'src/common/utils/FeatureFlags';
import { COOKIE_SESSION_KEY } from 'src/common/constants';
import {
  clearSession,
  setAxiosHeaders,
  secureProtocol,
  redirectUserToAuth,
  authRedirectUrl,
  loadKeyFromSessionStorage,
} from 'src/common/utils/utils';
import 'src/config/chat';
import Notifier from 'src/common/helpers/Notifier';
import {
  addAuthorizationHeader,
  addEmployeeIDHeader,
  addProviderIDHeader,
} from 'src/api/config';

extendLodash();

const {
  location,
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: reduxDevToolsExt,
} = window;

let locationCookie = location;

if (ROUTE_BASEPATH !== '/') {
  locationCookie = {
    ...window.location,
    pathname: `/${window.location.pathname.split('/').splice(2).join('/')}`,
  };
}

const initGrantFlow = () => {
  // Remove the existing session cookie and clear the session storage cache so we come back to a clean slate
  removeCookie(COOKIE_SESSION_KEY);
  clearSession();
  // Set uniteusCallbackUrl to the URL to which we will return after a successful authentication
  setCookie('uniteusCallbackUrl', JSON.stringify(locationCookie), { secure: secureProtocol });
  redirectUserToAuth();
};

const axiosHeaderInterceptor = () => {
  axios.interceptors.request.use((config) => {
    // do not change the auth header if we are making a request to ipinfo.io
    if (config.url === 'https://ipinfo.io/json') {
      return config;
    }

    const authCookie = getCookie(COOKIE_SESSION_KEY);
    const newConfig = config;

    if (authCookie) {
      const { access_token } = JSON.parse(authCookie);
      newConfig.headers.Authorization = `Bearer ${access_token}`;
    }

    return newConfig;
  });
};

const initSession = async (reqParams) => {
  let data;
  try {
    const res = await axios.post(`${AUTH_URL}/oauth2/token`, reqParams);
    data = res.data;

    const expiresIn = data.expires_in;
    const expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + expiresIn);

    if (!data.refresh_token && window.Rollbar) {
      window.Rollbar.error(
        `Setting COOKIE_SESSION_KEY (1).
        Auth response: ${JSON.stringify(data, 0, 2)},
        Request params: ${JSON.stringify(reqParams, 0, 2)}`,
      );
    }

    setCookie(
      COOKIE_SESSION_KEY,
      JSON.stringify(data),
      { secure: secureProtocol, expires: expirationDate },
    );
    axiosHeaderInterceptor();
    setAxiosHeaders(data.access_token);
    addAuthorizationHeader(data.access_token);
  } catch (e) {
    if (e.message === 'Network Error') {
      if (window.Rollbar) {
        window.Rollbar.error(e);
      }

      return { stop: true };
    }

    return initGrantFlow();
  }

  return data;
};

const UNAUTHENTICATED_ROUTES = [
  'account',
  'account-confirmation',
  'global-consent',
];
const isUnauthenticatedRoute = (pathname = '') => {
  const partialPath = pathname.split('/')[1];
  return partialPath && UNAUTHENTICATED_ROUTES.includes(partialPath);
};

const userFromAuthCookie = (token) => {
  const {
    email,
    sub,
    impersonator_user_uuid,
    user_intercom_hmac,
  } = JSON.parse(atob(token.split('.')[1]));
  return {
    email,
    id: sub,
    impersonation: impersonator_user_uuid,
    user_intercom_hmac,
  };
};

const getAuthToken = async () => {
  const tokenFromCookie = getCookie(COOKIE_SESSION_KEY);
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const impersonateSession = params.get('session');
  let reqParams;
  // Check for impersonation flow and exit quickly
  if (impersonateSession) {
    const decodedSession = JSON.parse(window.atob(impersonateSession));
    const { auth } = decodedSession;
    const { token, expires_at } = auth;
    const newAuthObj = {
      access_token: token,
      expires_in: expires_at,
    };

    const expirationDate = new Date();
    // In the case of impersonation, expires_at is expressed in seconds from token issuance, just like "expires_in".
    expirationDate.setSeconds(expirationDate.getSeconds() + expires_at);

    setCookie(
      COOKIE_SESSION_KEY,
      JSON.stringify(newAuthObj),
      { secure: secureProtocol },
    );
    axiosHeaderInterceptor();
    setAxiosHeaders(token);
    addAuthorizationHeader(token);
    return newAuthObj;
  }

  if (isUnauthenticatedRoute(window.location.pathname)) {
    return { unauthenticatedRoute: true };
  }
  if (code) {
    reqParams = {
      client_id: CLIENT_ID,
      redirect_uri: authRedirectUrl,
      grant_type: 'authorization_code',
      code,
    };
  } else if (tokenFromCookie) {
    const authObj = JSON.parse(tokenFromCookie);
    const { access_token } = authObj;

    if (authObj.refresh_token) { // Not impersonation
      return initSession({
        client_id: CLIENT_ID,
        refresh_token: authObj.refresh_token,
        grant_type: 'refresh_token',
      });
    }

    axiosHeaderInterceptor();
    setAxiosHeaders(access_token);
    addAuthorizationHeader(access_token);

    return authObj;
  } else {
    return initGrantFlow();
  }

  return initSession(reqParams);
};

const configureStore = (initialState) => {
  const composeEnhancers = reduxDevToolsExt ?
    reduxDevToolsExt({
      actionsBlacklist: ['THUNK_START', 'THUNK_END'],
      trace: true,
    }) :
    compose;

  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(trackThunks, ReduxThunk)),
  );

  return store;
};

const initCore = async (userObject, dispatch) => {
  try {
    const { id: userId, impersonation, user_intercom_hmac } = userObject;
    const sessionGlobalState = loadKeyFromSessionStorage(GLOBAL_STATE_SESSION_STORAGE_KEY);
    if (sessionGlobalState) {
      const { currentEmployee } = sessionGlobalState;
      if (currentEmployee) {
        addEmployeeIDHeader(currentEmployee.id);
        addProviderIDHeader(currentEmployee.provider.id);
      }
      return dispatch(updateGlobalState(sessionGlobalState));
    }

    let employees;
    let roles;

    try {
      const [employeeResult, rolesResult] = await Promise.all([
        fetchEmployees({
          userId,
          page: 1,
          size: 500,
        })(dispatch),
        fetchRoles()(dispatch),
      ]);
      employees = employeeResult.employees;
      roles = rolesResult;
    } catch (error) {
      return logoutUser()(dispatch);
    }

    dispatch(updateGlobalState({
      employees,
      roles,
      impersonation,
      intercom: {
        userHash: user_intercom_hmac,
      },
    }));
    await dispatch(fetchMyProviders({
      employees,
      impersonation,
    }));
    return true;
  } catch (err) {
    return false;
  }
};

const renderApp = async () => {
  if (DATADOG_LOGS_ENABLED) {
    initLogs();
  }
  const authObj = await getAuthToken() || {};
  const {
    access_token = '',
    refresh_token = '',
    expires_in = 0,
    unauthenticatedRoute = false,
  } = authObj;

  if (!access_token && !refresh_token && !expires_in && !unauthenticatedRoute) {
    return;
  }

  const store = configureStore();

  Notifier.setDispatch(store.dispatch);

  if (process.env.NODE_ENV === 'production') {
    checkVersionByInterval(store.dispatch);
  }

  const featureFlagPromise = () => store.dispatch(FeatureFlags.init({ clientId: LAUNCH_DARKLY_KEY }));
  const userObject = userFromAuthCookie(authObj.access_token);
  initiateDataDogSession(userObject.id);
  await Promise.all([
    store.dispatch(setCurrentGroup(sessionStorage.uniteusApiCurrentGroup)),
    store.dispatch(setCurrentNetwork(sessionStorage.uniteusApiCurrentNetwork)),
    store.dispatch(setAuthSession(access_token, refresh_token, expires_in)),
    store.dispatch(applicationLoadFromSessionStorage(sessionStorage.uniteusApiToken)),
    store.dispatch(userLoadFromSessionStorage()),
    store.dispatch(fetchBootstrapEnums()),
    initCore(userObject, store.dispatch),
  ]).then(async () => {
    await featureFlagPromise();
  });

  ReactDOM.render(
    <AppContainer>
      <ProviderUS store={store} />
    </AppContainer>,
    document.getElementById('container'),
  );
};

renderApp();

if (module.hot) {
  const orgError = console.error; // eslint-disable-line no-console
  console.error = (...args) => { // eslint-disable-line no-console
    // args will now have length of 2 when this error gets thrown. We still want to ignore it
    // "Warning: [react-router] You cannot change <Router routes>; it will be ignored"
    // "
    //     in Router (created by ProviderUS)
    //     in ProviderUS"
    // (todo) revisit this when/if react-router is ever updated to the latest
    if (args && args.length === 2 && typeof args[0] === 'string' &&
      args[0].indexOf('You cannot change <Router routes>;') > -1) {
      // React route changed
    } else {
      orgError.apply(console, args);
    }
  };
  module.hot.accept('src/components/App/ProviderUS', () => {
    renderApp();
  });
}
