import PropTypes from 'prop-types';

export const mergePhoneNumbers = (
  formValues,
  comparison,
  newestClientIndex,
) => {
  if (!formValues.phone_numbers) {
    return comparison?.phone_numbers?.[3] || [];
  }

  const equalValues = comparison.phone_numbers?.[3] || [];
  const selectedPhoneNumbers = formValues.phone_numbers || [];
  const allPhones = [...selectedPhoneNumbers, ...equalValues];

  const findPrimaryPhone = (clientIndex) => formValues.phone_numbers?.find(
    (phone) => comparison.phone_numbers[clientIndex]?.some(
      (e) => e.phone_number === phone.phone_number && e.is_primary,
    ),
  );

  let primaryPhone = findPrimaryPhone(newestClientIndex);

  if (!primaryPhone) {
    const oldestClientIndex = newestClientIndex ? 0 : 1;
    primaryPhone = equalValues.find((phone) => phone.is_primary) || findPrimaryPhone(oldestClientIndex);
  }

  const mergedPhoneNumbers = allPhones.map((phoneNumber) => ({
    ...phoneNumber,
    is_primary: phoneNumber.phone_number === primaryPhone?.phone_number,
  }));

  return mergedPhoneNumbers;
};

mergePhoneNumbers.propTypes = {
  formValues: PropTypes.object.isRequired,
  clientsComparison: PropTypes.object.isRequired,
  comparison: PropTypes.object.isRequired,
  newestClientIndex: PropTypes.number.isRequired,
};
