/* eslint-disable */

const configuration = {
  "title": "Eligibility Assessment",
  "pages": [
   {
    "name": "page1",
    "elements": [
     {
      "type": "dropdown",
      "name": "qIsEnrolled",
      "title": "Is the client enrolled in Medicaid?",
      "description": "Check clients status on https://epaces.emedny.org/",
      "isRequired": true,
      "uuid": "34f2b223-1ad3-468f-a194-44605d6883c9",
      "choices": [
       {
        "value": "76822cca-4538-4cc0-854e-d71191f621ef",
        "text": "YES",
        "uuid": "dd4866fd-630e-4168-921e-da539d6f999f"
       },
       {
        "value": "b763b1cb-bffb-4ee9-84a1-3a8b44ce5434",
        "text": "NO",
        "uuid": "7a949cce-0039-4b14-9746-cde7a1608de0"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qSelectScreening",
      "visibleIf": "{qIsEnrolled} = '76822cca-4538-4cc0-854e-d71191f621ef'",
      "title": "Select screening to validate HRSN Social Needs",
      "isRequired": true,
      "uuid": "0544745d-b97d-4ecb-80b5-12300a31af7b",
      "choices": [
       {
        "value": "item1",
        "text": "Screening with Food and Housing",
        "uuid": "065a181b-dcfb-47aa-acc7-9aa5479764ee"
       },
       {
        "value": "item2",
        "text": "Screening with no needs",
        "uuid": "3254c1c4-c314-4de0-bb4b-e33ebf0b6ee8"
       },
       {
        "value": "item3",
        "text": "Screening with no needs",
        "uuid": "40dee5d3-9584-41ce-a70c-373aeeac79a7"
       }
      ]
     },
     {
      "type": "html",
      "name": "qIdentifiedNeedsHtml",
      "visibleIf": "{qSelectScreening} notempty and {qIsEnrolled} = '76822cca-4538-4cc0-854e-d71191f621ef'",
      "uuid": "8530a1f8-7452-4a31-9ed0-84a5cd5a2f97"
     },
     {
      "type": "dropdown-multiselect",
      "name": "qOtherNeeds",
      "visibleIf": "{qSelectScreening} notempty",
      "title": "Are there any needs not identified in the screenings that you'd like to add?",
      "description": "If there are additional needs, add them here and then ask the follow up questions in the next section.",
      "uuid": "0a5a18a3-54e2-43db-8ce3-706378b44c4d",
      "choices": [
       {
        "value": "utilities_id",
        "text": "Utilities",
        "uuid": "7e70fa09-e63c-472f-8406-be4f15f4fb48"
       },
       {
        "value": "transportation_id",
        "text": "Transportation",
        "uuid": "016d78cd-f23e-4df2-a31e-117ee5e0bf99"
       },
       {
        "value": "food_id",
        "text": "Food",
        "uuid": "046840f7-9ba1-4caa-a985-fc22bfdfdc25"
       },
       {
        "value": "housing_id",
        "text": "Housing",
        "uuid": "51bb6271-8c26-416d-882b-674e2e480ee3"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qReceiveHelp",
      "visibleIf": "{qSelectScreening} notempty",
      "title": "Does the client want to receive help for unmet needs?",
      "isRequired": true,
      "uuid": "7e2486cf-3529-4670-bb82-7183b9c3766e",
      "choices": [
       {
        "value": "yes",
        "text": "YES",
        "uuid": "a872c870-8970-410c-9531-618bac57b7b8"
       },
       {
        "value": "no",
        "text": "NO",
        "uuid": "2a26789d-d6e9-4544-962c-0445263997f5"
       }
      ]
     },
     {
      "type": "checkbox",
      "name": "qReceivingServices",
      "visibleIf": "{qReceiveHelp} = 'yes'",
      "title": "Are you receiving any services for the unmet needs?",
      "description": "If YES, check which ones of these they are receiving. if NO, leave blank.",
      "uuid": "c58db662-cad3-4339-b1d2-ec9fb237bb29",
      "choices": [
       {
        "value": "item1",
        "text": "Supportive Housing",
        "uuid": "768e8335-6cc7-468e-ae9e-1d263d1becfb"
       },
       {
        "value": "item2",
        "text": "Healthy Homes",
        "uuid": "1b0f8d16-e5d9-42a6-9302-252a58532375"
       },
       {
        "value": "item3",
        "text": "Nutritional Counseling",
        "uuid": "9c19eac2-f0a0-459b-83c0-11e534126676"
       },
       {
        "value": "item4",
        "text": "Medically Tailored Meals (MTM) In-lieu of Services (ILOS)",
        "uuid": "c7b39fa2-4f01-4892-bb94-0f8ef277c23e"
       },
       {
        "value": "item5",
        "text": "Health Home Enrollment (under Enhanced Populations)",
        "uuid": "7ac49a8e-7c94-435f-984e-b2f15a049657"
       },
       {
        "value": "item6",
        "text": "Office for Persons with Developmental Disabilities (OPWDD) Waiver",
        "uuid": "9bba1884-59d1-4b24-92fd-ee01af79e988"
       },
       {
        "value": "item7",
        "text": "Traumatic Brain Injury (TBI) Waiver",
        "uuid": "0100a2df-1202-4654-9d3f-bae6f83e6c7f"
       },
       {
        "value": "item8",
        "text": "Nursing Home Transition Diversion (NHTD) Waiver",
        "uuid": "38ae5c88-6354-4dfa-82ee-74121a063a2e"
       },
       {
        "value": "item9",
        "text": "Children's Waiver",
        "uuid": "150acc2f-02ff-4e09-ba61-05fbc26336ae"
       },
       {
        "value": "item10",
        "text": "Medicaid Advantage Program (MAP)",
        "uuid": "e384fc0d-626f-47a0-9c46-a1d72be4e4d5"
       },
       {
        "value": "item11",
        "text": "Partial Capitation Managed Long-Term Care (MLTCP)",
        "uuid": "1db81275-36c4-4f23-bed7-ab006ab26d56"
       },
       {
        "value": "item12",
        "text": "Other",
        "uuid": "a3904e04-eeb8-4e41-a9c7-c7deb1eabc85"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qIsEnhancedPopulation",
      "visibleIf": "{qReceiveHelp} = 'yes'",
      "title": "Is the member part of an enhanced population",
      "isRequired": true,
      "uuid": "55fbcade-46de-4d18-8317-f929211c9368",
      "choices": [
       {
        "value": "yes",
        "text": "YES",
        "uuid": "ff9e54ce-b355-4f84-ad7d-1968a738e9a7"
       },
       {
        "value": "no",
        "text": "NO",
        "uuid": "71d7f4ce-aacc-4451-a7e6-d7caa888b6f2"
       }
      ]
     },
     {
      "type": "checkbox",
      "name": "qSelectEnhanced",
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "title": "ENHANCED POPULATIONS",
      "description": "Available Options (select all that apply)",
      "isRequired": true,
      "uuid": "6a98c18a-a34f-4455-8946-4c086ba84f8b",
      "choices": [
       {
        "value": "item1",
        "text": "Medicaid High Utilizer (adults and children)",
        "uuid": "5b8ef62a-9c07-49db-9243-77ae718dd460"
       },
       {
        "value": "item2",
        "text": "Individuals Enrolled in a NYS Health Home",
        "uuid": "3b84f63b-36d4-4bd0-9644-3bc62d87ae82"
       },
       {
        "value": "item3",
        "text": "Individuals with Substance Use Disorders ",
        "uuid": "0d658fe3-66bf-4703-a750-5f9d0e2e126d"
       },
       {
        "value": "item4",
        "text": "Pregnant and Postpartum Persons",
        "uuid": "7be6aef0-efb3-42d8-91c9-0fee2b79a6e6"
       },
       {
        "value": "item5",
        "text": "High-Risk Children Under 18 Years Old​",
        "uuid": "6934926d-1138-404f-90be-085f740ef8ea"
       },
       {
        "value": "item6",
        "text": "Individuals with an Intellectual or Developmental Disability",
        "uuid": "859213fd-7dc1-409d-a968-7018b50215cb"
       },
       {
        "value": "item7",
        "text": "Individuals with Serious Mental Illness",
        "uuid": "dd3a8833-4031-4fef-ae87-5846acf41363"
       },
       {
        "value": "item9",
        "text": "Individuals with a Chronic Condition Released from Incarceration in the Past 90 Days",
        "uuid": "4b36a759-7537-498b-abb0-c43ac9962b8a"
       },
       {
        "value": "item10",
        "text": "High-Risk Children Under 6 Years Old",
        "uuid": "028d8034-572b-4d59-96fe-b64cc256e758"
       },
       {
        "value": "item11",
        "text": "Youth with Juvenile Justice Involvement, in Foster Care Youth or Under Kinship Care​",
        "uuid": "69c5ba3b-2b66-4a04-bbc4-fa8b2e589e71"
       }
      ]
     },
     {
      "type": "html",
      "name": "qNeedSpecificQuestionsHeader",
      "visible": false,
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "uuid": "db3af5dc-f899-4a50-babf-757be492e2ce",
      "html": "<h2>Need-Specific Questions</h2>"
     },
     {
      "type": "html",
      "name": "qTransportationNeed1",
      "visible": false,
      "uuid": "736a11b8-4a3a-4b16-a0c6-1d27c2a889aa",
      "html": "<h3>Transportation</h3>"
     },
     {
      "type": "dropdown-multiselect",
      "name": "qTransportationNeed2",
      "visible": false,
      "title": "What type of transportation assistance does the client need?",
      "uuid": "0f31e1cc-b180-488f-8a09-dad37283e2d1",
      "choices": [
       {
        "value": "Item 1",
        "text": "551761000000000 - Transportation insecurity due to unaffordable transportation (finding)",
        "uuid": "9764e058-b1f1-4c88-af71-dc5a1c3059c1"
       },
       {
        "value": "Item 2",
        "text": "551741000124108 - Transportation insecurity due to no access to vehicle (finding)",
        "uuid": "1ce2defa-8145-4b4a-9f63-5f1cf58a4e87"
       },
       {
        "value": "Item 3",
        "text": "713458007- Lack of access to transportation (finding)",
        "uuid": "2cdc924e-7388-43ed-9c7f-a7fbb4a8d589"
       },
       {
        "value": "Item 4",
        "text": "551721000124101 - Transportation insecurity due to no driver's license (finding)",
        "uuid": "9a50b82c-2afe-49b6-8a0c-39afb60d609b"
       },
       {
        "value": "Item 5",
        "text": "551711000124109 - Transportation insecurity due to excessive travel time to destination (finding)",
        "uuid": "94ab3b59-2b7a-442b-aa18-cf44edffad8d"
       },
       {
        "value": "Item 6",
        "text": "160696009 - Transport distance too great (finding)",
        "uuid": "ca8c15e1-a396-4b01-9d9c-39827a6ffddd"
       },
       {
        "value": "Item 7",
        "text": "611151000124107 - Transportation insecurity due to route not serviced by public transportation (finding)",
        "uuid": "e6e3c313-80a5-4d4d-9887-5575b6e1b6b3"
       },
       {
        "value": "Item 8",
        "text": "551701000000000 - Transportation insecurity due to unsafe transportation environment (finding)",
        "uuid": "934d0232-586b-4282-b2a4-48b7fedc3c2d"
       },
       {
        "value": "Item 9",
        "text": "611161000124109 - Transportation insecurity limiting access to food (finding)",
        "uuid": "8fda024f-8f00-4cca-8d4f-11dfdea26f10"
       },
       {
        "value": "Item 10",
        "text": "551731000124103 - Inability to access healthcare due to transportation insecurity (finding)",
        "uuid": "9c66072d-7099-4455-8414-49a3ea3762cd"
       },
       {
        "value": "Item 11",
        "text": "551751000124105 - Inability to access community resources due to transportation insecurity (finding)",
        "uuid": "dc453219-0b95-4031-9331-35a7d2a31fef"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qTransportationNeed3",
      "visible": false,
      "title": "Is the client a transportation Program Recipient?",
      "uuid": "4ab576de-4930-4f9b-b468-cd85ce328a6e",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "38128ef2-42ab-4691-8e29-d8f9a6c8721d"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "4045f300-1a84-4a9c-bb0c-bd151177f2b0"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qTransportationNeed4",
      "visible": false,
      "title": "Does the client want to receive services for transportation needs?",
      "uuid": "869cdeb2-8743-4956-8edd-e4c8357bfb9d",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "ed686bbf-5821-4801-8658-dca057e1f158"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "a9de8c70-3e3b-4f30-9420-0f5d429eeb09"
       }
      ]
     },
     {
      "type": "html",
      "name": "qFoodNeed1",
      "visible": false,
      "uuid": "2423c888-41ae-4462-911f-5f2225d5f171",
      "html": "<h3>Food</h3>"
     },
     {
      "type": "text",
      "name": "qFoodNeed2",
      "visible": false,
      "title": "What type of food assistance does the client need?",
      "uuid": "1ee88044-9266-465a-9ac1-55285b0e2eb0"
     },
     {
      "type": "dropdown-multiselect",
      "name": "qFoodNeed3",
      "visible": false,
      "title": "Is the client enrolled in any of the following?",
      "description": "Select all that apply.",
      "uuid": "0d58fff6-7077-43d0-8a7e-41adf575194c",
      "choices": [
       {
        "value": "Item 1",
        "text": "SNAP",
        "uuid": "aca9e6a2-c13d-41e2-9a1d-adf4d69cfb74"
       },
       {
        "value": "Item 2",
        "text": "WIC",
        "uuid": "395b784f-4c71-40a5-94ce-1720ea25c50e"
       },
       {
        "value": "Item 3",
        "text": "Nutritional Education Program",
        "uuid": "244af9f1-bf0c-4938-bbb1-d794ce8c1e09"
       },
       {
        "value": "Item 4",
        "text": "Nutritional Services Program",
        "uuid": "ff450891-8b8c-4f15-9f4b-2b4444ea7ae0"
       },
       {
        "value": "Item 5",
        "text": "Food Service Program",
        "uuid": "4a58b8bf-4f97-412c-bb24-b5d716cde49b"
       }
      ]
     },
     {
      "type": "text",
      "name": "qFoodNeed4",
      "visible": false,
      "title": "How many people in the client’s household need food assistance?",
      "uuid": "5b6778a4-232b-41d4-abba-4ddfb92f7b92",
      "inputType": "number"
     },
     {
      "type": "dropdown",
      "name": "qFoodNeed5",
      "visible": false,
      "title": "Does the client have a valid state-issued id?",
      "uuid": "8b6fa04a-dc18-40a6-a19b-553a0148103f",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "080ccb74-c016-4af2-b6b2-5b280f3a3acc"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "5d6ba8cc-943d-4be6-a22e-d29007553901"
       }
      ]
     },
     {
      "type": "dropdown-multiselect",
      "name": "qFoodNeed6",
      "visible": false,
      "title": "On which days would the client like to have meals delivered?",
      "description": "Select all that apply.",
      "uuid": "b53565ea-cc65-4475-92be-65e30e0b5a7d",
      "choices": [
       {
        "value": "Item 1",
        "text": "Monday",
        "uuid": "a9441533-c3c6-4782-8f49-5de8a02e0f98"
       },
       {
        "value": "Item 2",
        "text": "Tuesday",
        "uuid": "a448bce1-a8cf-4a01-b288-7db49b2bc175"
       },
       {
        "value": "Item 3",
        "text": "Wednesday",
        "uuid": "9f8255dc-6c8d-46a7-9626-4fbfc49f3071"
       },
       {
        "value": "Item 4",
        "text": "Thursday",
        "uuid": "9540ca21-8126-4352-b5a6-e5b61044ce02"
       },
       {
        "value": "Item 5",
        "text": "Friday",
        "uuid": "30ebc2c8-b3fa-4b62-9431-0afb61bcd6c9"
       },
       {
        "value": "Item 6",
        "text": "Saturday",
        "uuid": "96bee632-55eb-493e-9485-fee70f424609"
       },
       {
        "value": "Item 7",
        "text": "Sunday",
        "uuid": "3c30ace2-7a05-4a40-93ec-f9b4a21dc506"
       }
      ]
     },
     {
      "type": "dropdown-multiselect",
      "name": "qFoodNeed7",
      "visible": false,
      "title": "Does the client have any of the following refrigeration needs?",
      "uuid": "af0fe730-921d-4990-8546-ab9810425be6",
      "choices": [
       {
        "value": "Item 1",
        "text": "Medication requiring refrigeration",
        "uuid": "5c51d592-3fff-489d-b3a1-54c52a3dadd4"
       },
       {
        "value": "Item 2",
        "text": "Breast milk requiring refrigeration",
        "uuid": "9b44f962-d194-45bd-bc3b-fc4d3d21f3da"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qFoodNeed8",
      "visible": false,
      "title": "Does the client have any dietary restrictions or food allergies?",
      "uuid": "f7112655-09b4-4caf-90d4-fe9e89f534ad",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "27fcb360-0ba7-43e7-a396-5fa05c0dd5d3"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "78c51204-d0d0-47e8-a8ba-3160d2d43ae2"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qFoodNeed9",
      "visible": false,
      "title": "Does the client want to receive services for Food needs?",
      "uuid": "40c59f88-4303-4c89-9e09-ae5a292391e1",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "158a1c80-2e8d-4235-bacf-c080fe3b0698"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "8e884194-5e9a-4e07-9349-cb11406039d5"
       }
      ]
     },
     {
      "type": "html",
      "name": "qHousingNeed1",
      "visible": false,
      "uuid": "58c4d12f-7acf-461f-95f5-b216514f4bce",
      "html": "<h3>Housing</h3>"
     },
     {
      "type": "dropdown-multiselect",
      "name": "qHousingNeed2",
      "visible": false,
      "title": "What type of housing assistance does the client need?",
      "description": "Select all that apply.",
      "uuid": "22b68b21-f1c7-4ea3-90ce-85efcc23b95f",
      "choices": [
       {
        "value": "Item 1",
        "text": "611551000124102​ - Homelessness, living in a​ shared accommodation​ (finding)",
        "uuid": "7a13fd70-d8de-4cf7-baa6-53875182683f"
       },
       {
        "value": "Item 2",
        "text": "611131000124100​ - Sheltered homelessness​ (finding)",
        "uuid": "d84009f0-d086-4634-942c-ff04a4861464"
       },
       {
        "value": "Item 3",
        "text": "611141000124105 - ​Unsheltered​ homelessness (finding)",
        "uuid": "4938bfc7-52fc-495e-9a98-f602b06f3735"
       },
       {
        "value": "Item 4",
        "text": "611181000124104​ - Unsheltered​ homelessness, sleeping in​ safe environment (finding)",
        "uuid": "f54efb4b-ff94-4cd8-bb2e-aa7d0ccf64e6"
       },
       {
        "value": "Item 5",
        "text": "1162585007 - Infestation of place of residence",
        "uuid": "1dd3f232-2247-4e02-b703-528d584c817e"
       },
       {
        "value": "Item 6",
        "text": "224255009 - Mold growth in home",
        "uuid": "87212ca1-557f-4852-be67-bcf7e58cb595"
       },
       {
        "value": "Item 7",
        "text": "1197631001 - Lead in residence",
        "uuid": "59b898b7-2006-4654-b72a-2da5701ebf7e"
       },
       {
        "value": "Item 8",
        "text": "105535008 - Lack of heat in house",
        "uuid": "6e49945b-a005-4c36-9e59-a9248a98d220"
       },
       {
        "value": "Item 9",
        "text": "1197634009 - Inadequate food preparation equipment in residence",
        "uuid": "ee4c798e-dc23-4534-b49b-813d115c92f9"
       },
       {
        "value": "Item 10",
        "text": "1197640002 - Inadequate smoke detection equipment in residence",
        "uuid": "7fb18a29-2c08-44db-98b7-17ca75d5a573"
       },
       {
        "value": "Item 11",
        "text": "105531004 - Housing unsatisfactory (finding)",
        "uuid": "5b3845cc-7072-4f54-97a8-37ede77394dc"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qHousingNeed3",
      "visible": false,
      "title": "Is the client’s household currently working with a housing provider (Housing Program Recipient)? ",
      "uuid": "02d9018c-2af0-4fbc-b80e-25bf07a539ae",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "fade7146-2cc7-4128-b616-5d410cd917e0"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "270c048d-4e0d-4565-96fa-a6c11fde85f6"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qHousingNeed4",
      "visible": false,
      "title": "Is the client on any housing waitlists (i.e. HUS Section 8, local housing authority, or other voucher programs)?",
      "uuid": "932c38be-d502-4dba-ac83-4457f88ffa19",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "f5dd0f44-43d9-45c9-864f-a4f184ec7fc5"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "cbd2bbd6-6e4c-4933-ac86-78f832dfee3c"
       }
      ]
     },
     {
      "type": "text",
      "name": "qHousingNeed5",
      "visible": false,
      "title": "How many people are in the client’s household?",
      "uuid": "c8515992-d6c1-46d4-b684-cc2c3c3eeae1",
      "inputType": "number"
     },
     {
      "type": "dropdown",
      "name": "qHousingNeed6",
      "visible": false,
      "title": "Does the client want to receive services for Housing needs?",
      "isRequired": true,
      "uuid": "f800497d-d9a9-4c33-82d5-8225241e50c1",
      "choices": [
       {
        "value": "item1",
        "text": "Yes",
        "uuid": "8ebd846c-1902-4cc7-ad18-20c2a3011280"
       },
       {
        "value": "item2",
        "text": "No",
        "uuid": "b1519ae4-7310-40e8-8624-12e935dd708d"
       }
      ]
     },
     {
      "type": "html",
      "name": "qUtilitiesNeed1",
      "visible": false,
      "uuid": "46fa27cd-3362-4b42-81a7-a740aab169f0",
      "html": "<h3>Utilities</h3>"
     },
     {
      "type": "dropdown-multiselect",
      "name": "qUtilitiesNeed2",
      "visible": false,
      "title": "What type of utilities assistance does the client need?",
      "description": "Select all that apply.",
      "uuid": "4c21a1e4-cc5c-4b8d-a07c-75fadbd4cd27",
      "choices": [
       {
        "value": "Item 1",
        "text": "105536009 - Living in housing without electricity",
        "uuid": "5b99d80f-8d66-46fd-bb17-31a23316675a"
       },
       {
        "value": "Item 2",
        "text": "5491000175103 - Lack of running water in house",
        "uuid": "9de63f06-759b-416f-b9eb-fd51dec0cd44"
       },
       {
        "value": "Item 3",
        "text": "671391000124106 - Unable to obtain electricity in residence due to limited financial resources",
        "uuid": "4cfc433e-753a-4c13-aa34-059311ececbd"
       },
       {
        "value": "Item 4",
        "text": "671371000124105 - Unable to heat residence due to limited financial resources",
        "uuid": "03843ff2-3286-4393-b93a-445660133f73"
       },
       {
        "value": "Item 5",
        "text": "671401000124108 - Unable to cool residence due to limited financial resources",
        "uuid": "d01fb1c2-fbdd-426b-a646-4ba6a6a6501e"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qUtilitiesNeed3",
      "visible": false,
      "title": "Does the client want to receive services for Utilities needs?",
      "uuid": "f541e8a1-5d53-4b2e-a7cc-9fe4f2fd405f",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "d1ae5caa-afe3-4f04-8f75-94ea03a367f9"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "f2a697cc-9965-45fb-8fd9-33a3905ac8f3"
       }
      ]
     },
     {
      "type": "html",
      "name": "qSupplementalQuestions1",
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "uuid": "76e73e5a-500d-4423-b609-6084a80d00af",
      "html": "<h2>Supplemental Questions</h2>"
     },
     {
      "type": "dropdown",
      "name": "qSupplementalQuestions2",
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "title": "Is the member enrolled in the TANF program?",
      "uuid": "3336940e-9d6b-4d35-bb33-5ca0497f49f5",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "2c76c178-ccf7-4a44-bcd5-9904def9d5a3"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "f91f0ed7-5753-4343-ac85-29c3a70cf4db"
       }
      ]
     },
     {
      "type": "dropdown-multiselect",
      "name": "qSupplementalQuestions3",
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "title": "Does the client have any of the following needs?",
      "description": "Select all that apply.",
      "uuid": "3c70f598-7aaa-4e56-9d81-af57f94468ff",
      "choices": [
       {
        "value": "Item 1",
        "text": "Activities of Daily Living (ADLs) & Instrumental Activities of Daily Living (IADLs) needs",
        "uuid": "2cfdebd7-b8a7-44b6-81e5-05131c4511ce"
       },
       {
        "value": "Item 2",
        "text": "Medical Respite",
        "uuid": "9accd094-d8ef-4e25-a96f-f5f912ac1927"
       },
       {
        "value": "Item 3",
        "text": "Childcare Assistance",
        "uuid": "f94785a0-bd04-4793-b13a-3f6a20b34408"
       },
       {
        "value": "Item 4",
        "text": "Other",
        "uuid": "71d943e7-ce71-4ea9-a135-36a5111943a3"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qSupplementalQuestions4",
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "title": "Does the client have serious difficulty walking or climbing stairs? (5 years old or older)",
      "enableIf": "{qIsEnhancedPopulation} = 'item1'",
      "isRequired": true,
      "uuid": "4f8ed83a-f0e0-4b68-b836-2295b8217daa",
      "choices": [
       {
        "value": "yes",
        "text": "Yes",
        "uuid": "ba004130-c053-4b28-9656-4aeff037c98f"
       },
       {
        "value": "no",
        "text": "No",
        "uuid": "e1273713-70af-4ab2-9d8f-fae428c15f3e"
       }
      ]
     },
     {
      "type": "dropdown",
      "name": "qSupplementalQuestions5",
      "visibleIf": "{qIsEnhancedPopulation} = 'yes'",
      "title": "Does the client have difficulty dressing or bathing? (5 years old or older)",
      "isRequired": true,
      "uuid": "83f3e321-7539-4c8c-83d5-91fa3e89b902",
      "choices": [
       {
        "value": "item1",
        "text": "Yes",
        "uuid": "f3c98037-ca05-40fc-bfa0-932224997619"
       },
       {
        "value": "item2",
        "text": "No",
        "uuid": "6c14e91c-3820-45e3-a341-33571480a566"
       }
      ]
     },
     {
      "type": "html",
      "name": "qNotEligible",
      "visible": false,
      "uuid": "b3780b6b-7d6d-44c7-a3b2-d2dc09e99c36",
      "html": "<div><div class=\"my-5 align-middle inline-block mr-4\"><svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M16 8C16 3.6129 12.3871 0 8 0C3.58065 0 0 3.6129 0 8C0 12.4194 3.58065 16 8 16C12.3871 16 16 12.4194 16 8ZM8 9.6129C8.80645 9.6129 9.48387 10.2903 9.48387 11.0968C9.48387 11.9355 8.80645 12.5806 8 12.5806C7.16129 12.5806 6.51613 11.9355 6.51613 11.0968C6.51613 10.2903 7.16129 9.6129 8 9.6129ZM6.58065 4.29032C6.54839 4.06452 6.74194 3.87097 6.96774 3.87097H9C9.22581 3.87097 9.41936 4.06452 9.3871 4.29032L9.16129 8.67742C9.12903 8.90323 8.96774 9.03226 8.77419 9.03226H7.19355C7 9.03226 6.83871 8.90323 6.80645 8.67742L6.58065 4.29032Z\" fill=\"#182A44\"></path></svg></div><div class=\"font-bold italic align-middle inline-block\"><p>The client is not eligible for enhanced services per NY1115 Waiver guidelines.</p><p>Submit the assessment and direct the client to existing programs in your network.</p></div></div>"
     }
    ]
   }
  ]
 }

/* eslint-disable */
export default {
    configuration: {
      ...configuration,
    }
  };



