import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, get, isEmpty, wget } from 'lodash';
import FacesheetInteractions from 'src/components/Facesheet/Overview/components/FacesheetInteractions';
import CaseSummary from 'src/components/Facesheet/Overview/components/CaseSummary';
import Relationships from './Relationships/Relationships';

const DISPLAY_NAME = 'facesheet-overview';
export function FacesheetOverview({
  contact,
  contactCases,
  employeeId,
  getClientRelationship,
}) {
  if (isEmpty(contact)) {
    return null;
  }

  return (
    <div className={DISPLAY_NAME}>
      <div className="row">
        <div className="col-md-4">
          {contact && (
            <Relationships
              person={contact}
              employeeId={employeeId}
              getClientRelationship={getClientRelationship}
            />
          )}
          <div className="mb-6">
            {!isEmpty(contactCases) && (
              <CaseSummary contactCases={contactCases} />
            )}
          </div>
        </div>
        <div className="col-md-8">
          <FacesheetInteractions
            contact={contact}
            contactCases={contactCases}
          />
        </div>
      </div>
    </div>
  );
}

FacesheetOverview.propTypes = {
  contact: PropTypes.object.isRequired,
  contactCases: PropTypes.array.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  employeeId: PropTypes.string,
  getClientRelationship: PropTypes.func.isRequired,
};

FacesheetOverview.defaultProps = {
  employeeId: '',
};

function mapStateToProps(state, ownProps) {
  const contactId = wget(ownProps, 'params.id', '');
  const contacts = wget(state, 'contacts.contacts', []);
  const contactCaseObj = get(state.serviceCase, contactId);
  const contactCases = get(contactCaseObj, 'data', []);
  const groupId = get(state, 'session.groupId', '');
  const employeeId = get(state, 'globalState.currentEmployee.id', '');

  return {
    contact: find(contacts, { id: contactId }),
    contactCases,
    groupId,
    employeeId,
  };
}

export default connect(mapStateToProps, null)(FacesheetOverview);
