import PropTypes from 'prop-types';
import moment from 'moment';

export const mergeAddresses = (
  formValues,
  clientsComparison,
) => {
  if (!formValues.addresses && !clientsComparison.addresses) {
    return undefined;
  }

  const selectedAddresses = formValues.addresses || [];
  const equalAddresses = clientsComparison.addresses[3];

  // If there is a primary address in the equal addresses, we keep it
  if (equalAddresses.some((address) => address.attributes.is_primary)) {
    return [
      ...selectedAddresses.map((address) => ({
        ...address,
        attributes: {
          ...address.attributes,
          is_primary: false,
        },
      })),
      ...equalAddresses,
    ];
  }

  // Get the most recent primary address
  const primaryAddresses = selectedAddresses.filter((address) => address.attributes.is_primary);

  const mostRecentPrimary = primaryAddresses.reduce(
    (latest, current) => (
      moment(current.attributes.created_at) > moment(latest.attributes.created_at) ? current : latest
    ),
    primaryAddresses[0],
  );

  // Set the most recent primary address as primary
  return [
    ...selectedAddresses.map((address) => ({
      ...address,
      attributes: {
        ...address.attributes,
        is_primary: mostRecentPrimary ? address.id === mostRecentPrimary.id : false,
      },
    })),
    ...equalAddresses,
  ];
};

mergeAddresses.propTypes = {
  formValues: PropTypes.object.isRequired,
  clientsComparison: PropTypes.object.isRequired,
};
