import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { useCurrentPayerId } from 'common/contexts/CurrentProviderContext';
import { useFind } from 'src/api/APIHooks';
import { Card } from 'src/common/Card';
import { Spinner } from 'src/common/spinners';
import { Drawer } from 'src/common/Drawer';
import {
  EnrollmentDrawerDetails,
  EnrollmentsTable,
  EnrollmentPagination,
} from '../components';
import getSort from '../utils/getSort';
import FilterSelectors from '../components/FilterSelectors';
import {
  useEnrollmentDrawer,
  useEnrollmentPagination,
  useEnrollmentFilters,
  useEnrollmentSorting,
} from '../hooks';
import NoEnrollmentsFoundCard from '../components/NoEnrollmentsMessage/NoEnrollmentsFoundCard';
import useWorkqueueRowSelector from '../../../common/utils/Workqueue/useWorkqueueRowSelector';
import useFeatureFlag from '../../../common/hooks/useFeatureFlag';
import SelectAllRowsButton from '../../../common/Workqueue/SelectAllRowsButton';

const getISOStringForNowMinusDays = (daysToSubtract = 0) => {
  const date = new Date();
  date.setMinutes(0, 0, 0);
  date.setDate(date.getDate() - daysToSubtract);
  return date.toISOString();
};

export const EnrollmentWorkqueue = ({
  enrollmentStatus,
  showEnrollmentAging,
  showSelectMultipleEnrollments,
}) => {
  const paginationProps = useEnrollmentPagination();
  const currentPayerId = useCurrentPayerId();
  const {
    filterValues,
    filterOptions,
    isFiltered,
    resetFilters,
    setRequestTypeFilter,
    setPlanFilter,
    setDaysSinceLastUpdatedFilter,
  } = useEnrollmentFilters();

  const pays6096UpdateEWQDesign = useFeatureFlag('pays-6096-update-ewq-design');

  const sortProps = useEnrollmentSorting({ resetPagination: paginationProps.resetPagination });
  const { isLoading, data } = useFind(
    'enrollment_requests',
    {
      enrollment_status: enrollmentStatus,
      'plan.payer': currentPayerId,
      request_type: filterValues.requestType,
      plan: filterValues.plan,
      updated_after: filterValues.daysSinceLastUpdated ?
        getISOStringForNowMinusDays(filterValues.daysSinceLastUpdated) :
        undefined,
    },
    {
      page: {
        number: paginationProps.pageNumber,
        size: paginationProps.pageSize,
      },
      queryConfig: {
        enabled: !!currentPayerId,
        placeholderData: undefined,
      },
      sort: getSort(sortProps),
    },
  );

  const enrollmentRequests = get(data, 'data.data', []);
  const totalItemCount = get(data, 'data.paging.total_count', 0);
  const totalPageCount = get(data, 'data.paging.total_pages', 0);

  const rowSelectorProps = useWorkqueueRowSelector({ pageRows: enrollmentRequests });

  const drawerProps = useEnrollmentDrawer({
    enrollmentRequests,
    pageNumber: paginationProps.pageNumber,
    pageSize: paginationProps.pageSize,
    setPageNumber: paginationProps.setPageNumber,
    totalPageCount,
  });

  const onUpdateFilter = () => {
    paginationProps.resetPagination();
    rowSelectorProps.deselectAll();
  };

  return (
    <div className="divide-y divide-solid divide-dark-border-blue">
      {
        pays6096UpdateEWQDesign ? (
          <div className="single-card-ewq-container px-3 py-3" data-testid="updated-enrollment-workqueue-container">
            <Card className="bg-white py-10 pt-30 overflow-x-scroll">
              <h1
                className="text-text-blue font-extrabold font-heavy-font text-2xl mb-6 px-8 pb-5"
              >
                Enrollment Requests
              </h1>
              <div className="px-8 flex flex-row">
                {
                  showSelectMultipleEnrollments && (
                    <SelectAllRowsButton
                      selectAllRows={rowSelectorProps.selectAllRows}
                      selectedRows={rowSelectorProps.selectedRows}
                      deselectAll={rowSelectorProps.deselectAll}
                    />
                  )
                }
                <FilterSelectors
                  filterValues={filterValues}
                  filterOptions={filterOptions}
                  resetFilters={resetFilters}
                  onUpdateFilter={onUpdateFilter}
                  setRequestTypeFilter={setRequestTypeFilter}
                  setPlanFilter={setPlanFilter}
                  setDaysSinceLastUpdatedFilter={setDaysSinceLastUpdatedFilter}
                />
              </div>
              <div className="px-0 py-4">
                {/* eslint-disable no-nested-ternary */}
                {isLoading ? (
                  <div role="alert"><Spinner /></div>
                ) : (
                  !isEmpty(enrollmentRequests) ? (
                    <>
                      <div className="py-5">
                        <EnrollmentsTable
                          enrollmentRequests={enrollmentRequests}
                          openDrawer={drawerProps.openDrawer}
                          showEnrollmentAging={showEnrollmentAging}
                          showSelectMultipleEnrollments={showSelectMultipleEnrollments}
                          toggleSelectedRow={rowSelectorProps.toggleSelectedRow}
                          toggleSelectAllRows={rowSelectorProps.toggleSelectAllRows}
                          selectedRows={rowSelectorProps.selectedRows}
                          {...sortProps}
                        />
                      </div>
                      <div className="px-6">
                        <EnrollmentPagination
                          {...paginationProps}
                          totalItemCount={totalItemCount}
                          totalPageCount={totalPageCount}
                        />
                      </div>
                    </>
                  ) : (
                    <NoEnrollmentsFoundCard
                      resetFilters={resetFilters}
                      isFiltered={isFiltered}
                      status={enrollmentStatus}
                    />
                  ))}
              </div>
              <Drawer open={drawerProps.open} setOpen={drawerProps.setOpen}>
                {isLoading || !drawerProps.clickedRequest ? (
                  <div className="pt-8" role="alert"><Spinner /></div>
                ) : (
                  <EnrollmentDrawerDetails
                    getNextRequest={drawerProps.getNextRequest}
                    getPrevRequest={drawerProps.getPrevRequest}
                    enrollmentRequest={drawerProps.clickedRequest}
                    setDrawerOpen={drawerProps.setOpen}
                  />
                )}
              </Drawer>
            </Card>
          </div>
        ) : (
          <>
            <div className="original-ewq-container p-6 pt-8 bg-white" data-testid="enrollment-workqueue-container">
              <h1 className="text-text-blue font-medium-font text-2xl">Enrollment Requests</h1>
            </div>
            <div className="bg-light-border-grey px-6 py-4">
              <FilterSelectors
                filterValues={filterValues}
                filterOptions={filterOptions}
                resetFilters={resetFilters}
                onUpdateFilter={() => paginationProps.resetPagination()}
                setRequestTypeFilter={setRequestTypeFilter}
                setPlanFilter={setPlanFilter}
                setDaysSinceLastUpdatedFilter={setDaysSinceLastUpdatedFilter}
              />
            </div>
            <div className="px-6 py-4">
              {/* eslint-disable no-nested-ternary */}
              {isLoading ? (
                <div role="alert"><Spinner /></div>
              ) : (
                !isEmpty(enrollmentRequests) ? (
                  <>
                    <Card className="mb-6">
                      <EnrollmentsTable
                        enrollmentRequests={enrollmentRequests}
                        openDrawer={drawerProps.openDrawer}
                        showEnrollmentAging={showEnrollmentAging}
                        {...sortProps}
                      />
                    </Card>
                    <EnrollmentPagination
                      {...paginationProps}
                      totalItemCount={totalItemCount}
                      totalPageCount={totalPageCount}
                    />
                  </>
              ) : (
                <NoEnrollmentsFoundCard resetFilters={resetFilters} isFiltered={isFiltered} status={enrollmentStatus} />
                )
              )}
              {/* eslint-enable no-nested-ternary */}
              <Drawer open={drawerProps.open} setOpen={drawerProps.setOpen}>
                {isLoading || !drawerProps.clickedRequest ? (
                  <div className="pt-8" role="alert"><Spinner /></div>
                ) : (
                  <EnrollmentDrawerDetails
                    getNextRequest={drawerProps.getNextRequest}
                    getPrevRequest={drawerProps.getPrevRequest}
                    enrollmentRequest={drawerProps.clickedRequest}
                    setDrawerOpen={drawerProps.setOpen}
                  />
                )}
              </Drawer>
            </div>
          </>
        )
      }
    </div>
  );
};

EnrollmentWorkqueue.propTypes = {
  enrollmentStatus: PropTypes.string.isRequired,
  showEnrollmentAging: PropTypes.bool,
  showSelectMultipleEnrollments: PropTypes.bool,
};

EnrollmentWorkqueue.defaultProps = {
  showEnrollmentAging: false,
  showSelectMultipleEnrollments: false,
};

export default EnrollmentWorkqueue;
