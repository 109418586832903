import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { get } from 'lodash';
import { DAYS_OF_THE_WEEK, getOverlaps, timeSlotsOptions } from '@unite-us/client-utils';
import { SelectField } from '@unite-us/ui';
import ButtonAddField from 'src/components/Organization/components/ButtonAddField';
import ButtonRemoveField from 'src/components/Organization/components/ButtonRemoveField';

const ContactHoursEdit = ({ fields, registerField }) => {
  const [isChecked, setIsChecked] = useState({});
  const [overlaps, setOverlaps] = useState({});

  const contactTimeslots = fields.contact_preferences.timeslots;

  const validateOverlaps = (lowercaseDay) => {
    const dayOverlaps = getOverlaps(contactTimeslots[lowercaseDay]);
    const newOverlaps = { ...overlaps };
    newOverlaps[lowercaseDay] = dayOverlaps;
    setOverlaps(newOverlaps);
  };

  const handleAddField = (lowercaseDay) => {
    if (contactTimeslots[lowercaseDay].length === 0) {
      contactTimeslots[lowercaseDay].addField({ start_time: '09:00', end_time: '17:00' });
    } else {
      contactTimeslots[lowercaseDay].addField({ start_time: null, end_time: null });
    }
  };

  const handleRemoveField = (lowercaseDay, index) => {
    contactTimeslots[lowercaseDay].removeField(index);

    if (index === 0 && contactTimeslots[lowercaseDay].length === 1) {
      setIsChecked({ ...isChecked, [lowercaseDay]: !isChecked[lowercaseDay] });
    }
    validateOverlaps(lowercaseDay);
  };

  const handleDayCheckbox = (lowercaseDay) => {
    contactTimeslots[lowercaseDay].forEach(() => {
      contactTimeslots[lowercaseDay].removeField(0);
    });
    if (!isChecked[lowercaseDay]) {
      handleAddField(lowercaseDay);
    }
    setIsChecked({ ...isChecked, [lowercaseDay]: !isChecked[lowercaseDay] });
  };

  const validateStartTime = (day_of_week, lowercaseDay, index) => {
    if (isChecked[lowercaseDay]) {
      if (!day_of_week.start_time.value) {
        return 'Required';
      }
    }
    if (overlaps[lowercaseDay]?.[index]) {
      return 'Times cannot overlap';
    }
    return undefined;
  };

  const validateEndTime = (day_of_week, lowercaseDay, index) => {
    if (isChecked[lowercaseDay]) {
      if (!day_of_week.end_time.value) {
        return 'Required';
      }
    }
    if (overlaps[lowercaseDay]?.[index]) {
      return ' '; // whitespace sets error style
    }
    return undefined;
  };

  const onChangeStartTime = (lowercaseDay, day_of_week) => {
    // If start_time values changes to a time later than end_time value, clear the end_time value.
    const startTimeValue = get(day_of_week, 'start_time.value');
    const endTimeValue = get(day_of_week, 'end_time.value');
    if (endTimeValue && endTimeValue <= startTimeValue) {
      day_of_week.end_time.onChange();
    }
    validateOverlaps(lowercaseDay);
  };

  const filterTime = (search, startTime) => (
    new Promise((resolve) => {
      const regex = new RegExp(`^${search}\\b`, 'i');
      const filteredOptions = startTime ?
        timeSlotsOptions(startTime).filter((time) => regex.test(time.label)) :
        timeSlotsOptions().filter((time) => regex.test(time.label));
      resolve({ options: filteredOptions });
    })
  );

  return (
    <div className="contact-hours-edit">
      {
        DAYS_OF_THE_WEEK.map((day) => {
          const lowercaseDay = day.toLowerCase();
          return (
            <div
              key={`contact-hours-edit-${lowercaseDay}`}
              className="grid grid-cols-5"
            >
              <div className="ui-checkbox-field pt-2 h-14">
                <input
                  id={`contact-hours-edit-checked-${lowercaseDay}`}
                  type="checkbox"
                  checked={isChecked[lowercaseDay]}
                  onChange={() => handleDayCheckbox(lowercaseDay)}
                />
                <label
                  htmlFor={`contact-hours-edit-checked-${lowercaseDay}`}
                  className="ui-form-field__label"
                >
                  {day}
                </label>
              </div>
              {contactTimeslots[day.toLowerCase()]?.map((day_of_week, index) => (
                <>
                  {index > 0 && <div className="w-32" />}
                  {isChecked[lowercaseDay] && (
                    <div className="flex gap-2 px-0 col-span-3 justify-start" data-testid="contact-hours-edit-row">
                      <SelectField
                        field={day_of_week.start_time}
                        className="w-32"
                        clearable={false}
                        hideLabel
                        id={`contact-hours-edit-start-${lowercaseDay}-${index}`}
                        label="Start Time"
                        onChange={() => onChangeStartTime(lowercaseDay, day_of_week)}
                        options={timeSlotsOptions()}
                        loadOptions={(search) => filterTime(search)}
                        placeholder="Start"
                        ref={registerField}
                        required
                        shouldSort={false}
                        validations={{ func: () => validateStartTime(day_of_week, lowercaseDay, index) }}
                      />
                      <div className="pt-2">to</div>
                      <SelectField
                        field={day_of_week.end_time}
                        className="w-32"
                        clearable={false}
                        hideLabel
                        id={`contact-hours-edit-end-${lowercaseDay}-${index}`}
                        label="End Time"
                        onChange={() => validateOverlaps(lowercaseDay)}
                        options={timeSlotsOptions(get(day_of_week, 'start_time.value'))}
                        loadOptions={(search) => filterTime(search, get(day_of_week, 'start_time.value'))}
                        placeholder="End"
                        ref={registerField}
                        required
                        shouldSort={false}
                        validations={{ func: () => validateEndTime(day_of_week, lowercaseDay, index) }}
                      />
                      <div className="pt-1">
                        <ButtonRemoveField
                          onClick={() => handleRemoveField(lowercaseDay, index)}
                          testId={`contact-hours-edit-remove-${lowercaseDay}-${index}`}
                        />
                      </div>
                    </div>
                  )}
                  {index > 0 && <div className="w-32" />}
                </>
              ))}

              {
                isChecked[lowercaseDay] ? (
                  <div className="pt-2 justify-self-end px-0 row-start-1 col-start-5">
                    <ButtonAddField
                      label="Add Timeslot"
                      onClick={() => handleAddField(lowercaseDay)}
                    />
                  </div>
                ) :
                  <div className="pt-2 font-regular-font">Do not contact</div>
              }
            </div>
          );
        })
      }
    </div>
  );
};

ContactHoursEdit.propTypes = {
  fields: PropTypes.object.isRequired,
  registerField: PropTypes.func.isRequired,
};

ContactHoursEdit.fields = [
  'contact_preferences.timeslots.monday[].start_time',
  'contact_preferences.timeslots.monday[].end_time',
  'contact_preferences.timeslots.tuesday[].start_time',
  'contact_preferences.timeslots.tuesday[].end_time',
  'contact_preferences.timeslots.wednesday[].start_time',
  'contact_preferences.timeslots.wednesday[].end_time',
  'contact_preferences.timeslots.thursday[].start_time',
  'contact_preferences.timeslots.thursday[].end_time',
  'contact_preferences.timeslots.friday[].start_time',
  'contact_preferences.timeslots.friday[].end_time',
  'contact_preferences.timeslots.saturday[].start_time',
  'contact_preferences.timeslots.saturday[].end_time',
  'contact_preferences.timeslots.sunday[].start_time',
  'contact_preferences.timeslots.sunday[].end_time',
];

export default ContactHoursEdit;
