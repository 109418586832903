import moment from 'moment';

const constructFeeScheduleProgramInitialFormValues = (feeScheduleProgram) => {
    const initialValues = feeScheduleProgram;
    delete initialValues.zcodes;
    delete initialValues.place_of_services;
    delete initialValues.procedure_codes;

    if (initialValues && initialValues.starts_at) {
      initialValues.starts_at = moment(initialValues.starts_at, 'YYYY/MM/DD').format('MM/DD/YYYY');
    }

    if (initialValues && initialValues.ends_at) {
      initialValues.ends_at = moment(initialValues.ends_at, 'YYYY/MM/DD').format('MM/DD/YYYY');
    }

    initialValues.billable = initialValues.billable ? initialValues.billable.toString() : 'false';

    initialValues.authorization_required = initialValues.authorization_required ?
      initialValues.authorization_required.toString() :
      'false';

    initialValues.auto_authorizes = initialValues.auto_authorizes ? initialValues.auto_authorizes.toString() : 'false';

    const can_go_above = initialValues.can_invoice_above_remaining_authorized_amount;
    initialValues.can_invoice_above_remaining_authorized_amount = can_go_above ? can_go_above.toString() : 'false';

    const forceZcodes = initialValues.force_zcode_associations;
    initialValues.force_zcode_associations = forceZcodes ? 'true' : 'false';

    const forceProcedureCodes = initialValues.force_procedure_code_associations;
    initialValues.force_procedure_code_associations = forceProcedureCodes ? 'true' : 'false';

    const forceModifiers = initialValues.force_procedure_code_modifier_associations;
    initialValues.force_procedure_code_modifier_associations = forceModifiers ? 'true' : 'false';

    return initialValues;
};

export default constructFeeScheduleProgramInitialFormValues;
