import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import useAvailableForms from 'src/components/FormSubmissions/useAvailableForms';
import FilterBar from 'src/components/Dashboard/components/FilterBar';
import DropdownMenu from 'src/common/Menu/DropdownMenu';
import { CORE_API } from 'src/config/env/env.config';
import { getContactFromState } from 'src/components/Contacts/utils';
// import useFormSubmissions from 'src/components/FormSubmissions/useFormSubmissions';
import { browserHistory } from 'src/common/utils/browserHistory';
import { Button, Icon } from '@unite-us/ui';
import { FacesheetScreenings as FacesheetAssessments } from '@unite-us/app-screenings';
import { getAuthToken } from 'src/api/config';
import * as apiHooks from 'src/api/APIHooks';
import _ from 'lodash';

export function FacesheetScreenings({
 contact, employee, provider, children,
}) {
  // const size = 50;
  // const context = contact.id;
  // const contextType = 'person';
  // const consolidate = true;
  // const [formFilter, setFormFilter] = useState('');
  // const [page, setPage] = useState(1);

  const handleViewFormSubmission = (formSubmission) => {
    browserHistory.push(`/screenings/v2/facesheet/${contact.id}/submission/${formSubmission.id}`);
  };

  const handleSelectNewForm = (formId) => {
    browserHistory.push(
      `/facesheet/${contact.id}/screenings/${formId}/submissions/new`,
    );
  };

  const filterBar = (filter, isFetching, paging, onNextClick, onPrevClick) => (
    <FilterBar
      filters={filter}
      onFiltersChange={(change, v) => {
        // Todo: Allow filtering by template_ids
        // if (change === 'screening_type') {
        //   setFormFilter(v.join(','));
        // }
        console.log(change, v);
      }}
      paging={paging}
      // onNextClick={() => setPage(page < paging.total_pages ? page + 1 : page)}
      // onPrevClick={() => setPage(page > 1 ? page - 1 : page)}
      onNextClick={onNextClick}
      onPrevClick={onPrevClick}
      isFetching={isFetching}
      topMargin={false}
    />
  );

  const addFormBtn = (formsForSubmissions) => (
    <DropdownMenu
      options={formsForSubmissions}
      onChange={(item) => handleSelectNewForm(item.value)}
      placeholder={(
        <Button
          id="new-screening-submission"
          label="Add New Screening"
          labelposition={'after'}
          iconLeft={<Icon icon="IconPlusCircle" className="fill-current" />}
          primary
        />
      )}
    />
  );

  if (children) {
    return children;
  }
const formFilter = '';

  return (
    <FacesheetAssessments
      hooks={{ apiHooks }}
      callbacks={{
        goBack: () => {
          browserHistory.goBack();
        },
        replace: (path) => {
          browserHistory.replace(path);
        },
        pushInHistory: (url) => {
          browserHistory.push(url);
        },
      }}
      // formSubmissions={formSubmissions}
      handleViewFormSubmission={handleViewFormSubmission}
      hasFilters={formFilter !== ''}
      contact={contact}
      filterBar={filterBar}
      dropdownMenu={addFormBtn}
      appSettings={{
        env: {
          getAuthToken,
          employee,
          employeeId: employee?.id,
          providerId: provider?.id,
          provider,
          coreUrl: CORE_API,
        },
      }}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const contactId = _.wget(ownProps, 'params.id', '');
  const contact = getContactFromState(state, contactId);
  const currentProvider = state.globalState.currentProvider.group;
  const currentEmployee = state.globalState.currentEmployee;
  return {
    contact,
    provider: currentProvider,
    employee: currentEmployee,
  };
}

FacesheetScreenings.propTypes = {
  contact: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  children: PropTypes.node,
};

FacesheetScreenings.defaultProps = {
  children: null,
};

export default connect(mapStateToProps)(FacesheetScreenings);
