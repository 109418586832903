import { find } from 'lodash';

function getConsentStatusDisplay(status, consentContactEnums) {
  const contactStatus = find(consentContactEnums, (contactEnum) => contactEnum.value === status);

  switch (status) {
    case 'unsent': {
      return contactStatus.display_name;
    }
    case 'pending': {
      return `${contactStatus.display_name} Consent`;
    }
    case 'accepted':
    case 'declined':
      return `Consent ${contactStatus.display_name}`;
    default: {
      return 'No Consent';
    }
  }
}

export default getConsentStatusDisplay;
