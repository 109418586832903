/* eslint-disable */
export const TEMPLATE_ID = "086f7447-3240-4695-aea8-0007d4330344";

export const payloadCreateScreen = {
    "template_id": TEMPLATE_ID,
    "status": "complete",
    "subject_id": "fa04ad88-3045-49b7-b631-d00be97c024f",
    "answers": [
      {
        "question_id": "c731300b-c677-48a1-9631-c98bddb82a8d",
        "option_id": "76822cca-4538-4cc0-854e-d71191f621ef"
      },
      {
        "question_id": "852ec7be-8f06-44af-88a3-dd836c48be6d",
        "option_id": "d4c053c8-02cf-4a9c-931c-e7973b4ac4d2"
      },
      {
        "question_id": "3cec81b9-d289-4936-b5c5-7a44e204cd03",
        "option_id": "11dd7088-340b-4475-b1dd-d9ccc80e7771"
      },
      {
        "question_id": "3b9d8bd2-270e-43b9-b57a-3eddde3a7e64",
        "option_id": "6b1a5e7a-0dc2-4499-806f-db6898ef129c"
      },
      {
        "question_id": "74d07c62-5c08-44be-b55c-113a9f7561e7",
        "option_id": "5f92dd7d-4e0f-4323-ab60-b3ab0578c178"
      },
      {
        "question_id": "9b93e117-ae6a-4bf1-aad1-d36664d9120e",
        "option_id": "20cadbf6-b772-4cd4-8d71-684975a2ca0e"
      },
      {
        "question_id": "d64e6005-8db4-4ed4-add8-d877bf69462a",
        "option_id": "b1e66190-b7d2-4416-9e84-833afa4fd39a"
      },
      {
        "question_id": "d79aa141-31c2-4054-8548-35a46b2bb6a2",
        "option_id": "37d47af7-213d-4944-9f13-f70fa9bd8815"
      },
      {
        "question_id": "6472b20b-f44c-46cb-bfa7-8712220987f8",
        "option_id": "f9538b69-2fd0-407d-b116-33b86218289f"
      }
    ]
  }
