import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  tracker,
} from '@unite-us/client-utils';
import { bindActionCreators } from 'redux';
import IndexDetailView from 'src/components/Dashboard/components/IndexDetailView';
import AllNetworkCasesIndex from 'src/components/Dashboard/components/Cases/AllNetworkCasesIndex';
import { fetchDashboardCases } from 'actions/Case/Contact/Group';
import _ from 'lodash';
import DetailView from 'src/components/Dashboard/components/DetailView';
import CaseDetailsView from 'src/components/Cases/components/Detail/CaseDetailsView';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import { isCaseManagementProvider } from 'common/utils/User/userGroup';
import { getStatusesOptions, getGroupUsersOptions } from 'src/components/Groups/Users/utils';
import { isCoordinationCenter } from 'common/utils/Group';
import {
  careCoordinatorFilterDefault,
  noneAssignedOption,
  networkGroupsOptions,
} from 'src/components/Dashboard/utils/filterDefaults';
import { getDataOfPage, isDataValid } from 'src/components/Dashboard/utils/dataHelpers';
import callOrLog from 'src/common/utils/callOrLog';
import { getCCUserNetwork } from 'src/components/User/utils';
import { searchNetworkGroups } from 'actions/Group/Network';
import { goToCase } from 'src/components/Cases/utils/routing';
import { careCoordinatorsFilter, serviceTypesFilter } from 'src/components/Dashboard/utils/filter';
import { DASHBOARD_VIEWS, DASHBOARD_EVENTS } from 'common/utils/EventTracker/utils/eventConstants';
import { isSuperNetwork } from 'src/components/Network/utils';
import subNetworksFilter from 'src/components/Dashboard/utils/subNetworksFilter';
import {
  useV4toGetOrganizations,
  hideMoreFiltersOption,
  crtb1290SortReferralsByOldest,
} from 'src/common/utils/FeatureFlags/flags';
import FILTER_CONSTANTS from 'src/components/Dashboard/utils/filter/constants';
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';

export class AllNetworkCases extends PureComponent {
  constructor(props) {
    super(props);
    this.fetch = this.fetch.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.fetchAllNetworkGroups = this.fetchAllNetworkGroups.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.shouldFetch = this.shouldFetch.bind(this);
    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.state = {
      activeUsersInGroup: [],
      page: 1,
      sidx: 'updated_at',
      sord: 'desc',
      filters: {
        service_types: _.get(props, 'filters.service_types', []),
        programs: _.get(props, 'filters.programs', []),
        care_coordinator_users: _.get(
          props,
          'filters.care_coordinator_users',
          careCoordinatorFilterDefault(props.userId, props.isCaseManager && props.isCC),
        ),
        resolved: _.get(props, 'filters.resolved', 'all'),
        network_inclusion_scope: _.get(props, 'filters.network_inclusion_scope', 'all'),
        provider_scope: _.get(props, 'filters.provider_scope', 'all'),
        groups: _.get(props, 'filters.groups', []),
        networks: _.get(props, 'filters.networks', []),
      },
      open: false,
    };
  }

  componentDidMount() {
    this.fetch();
    this.props.fetchProvidersUserCore({
      providers: this.props.groupId,
    })
      .then((response) => {
        if (response) {
          const { employees: activeUsersInGroup } = response;
          this.setState({ activeUsersInGroup });
        }
      });

    const { useV4toSearchNetworkGroups } = this.props;
    if (useV4toSearchNetworkGroups && this.props.networkId) {
      this.fetchAllNetworkGroups();
    }

    if (!useV4toSearchNetworkGroups && this.props.networkId && _.isEmpty(this.props.networkGroups)) {
      this.fetchAllNetworkGroups();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.get(this.props, 'allCases.currentPage', 1) !== _.get(nextProps, 'allCases.currentPage', 1)) {
      this.setState({ page: nextProps.allCases.currentPage });
    }

    if (!this.props.refetch && nextProps.refetch) {
      this.fetch();
    }
  }

  onClose() {
    this.setState({ open: false });
  }

  onToggle() {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  onCellClick(row) {
    const pagedData = getDataOfPage(this.props.allCases, this.state.page, this.props.sortAscending);
    const serviceCase = pagedData.data[row];

    callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.allNetworkCasesRow, {
      view: DASHBOARD_VIEWS.allNetworkCases,
    }, { serviceCase }));

    goToCase({ ...serviceCase, status: 'all' });
  }

  onFiltersChange(key, activeFilters, callbacks) {
    let newFilters = activeFilters;
    if (_.isEqual(key, 'care_coordinator_users')) {
      newFilters = careCoordinatorsFilter(activeFilters, callbacks);
    }
    this.setState({
      page: 1,
      filters: { ...this.state.filters, [key]: newFilters },
    }, () => {
      callOrLog(() => {
        const filters = tracker.buildDashboardFilterPayload(this.state, this.props);
        this.context.eventTracker(DASHBOARD_EVENTS.allNetworkCasesFilter, filters);
      });
      this.fetch();
    });
  }

  onNextClick() {
    const page = this.state.page + 1;

    this.shouldFetch(page);
  }

  onPrevClick() {
    const page = this.state.page - 1;

    this.shouldFetch(page);
  }

  fetchAllNetworkGroups(page = 1, search = undefined) {
    const { useV4toSearchNetworkGroups } = this.props;
    return this.props.searchNetworkGroups(
      this.props.networkId,
      {},
      { page, search },
      useV4toSearchNetworkGroups,
    ).then((response) => {
      if (useV4toSearchNetworkGroups) {
        const dropdownResponse = response.map((provider) => ({
          label: provider.name,
          value: provider.id,
          initial: false,
        }));
        return dropdownResponse;
      }
      if (!useV4toSearchNetworkGroups) {
        const nextPage = _.get(response, 'data.paging.next_page');
        if (nextPage) {
          this.fetchAllNetworkGroups(nextPage);
        }
      }
      return true;
    });
  }

  shouldFetch(page) {
    if (isDataValid(this.props.allCases, page)) {
      this.setState({ page });
    } else {
      this.fetch(page);
    }
  }

  fetch(page = 1) {
    const formattedFilters = _.reduce(
      this.state.filters,
      (acc, f, key) => {
        if (f === 'all') {
          return acc;
        }
        return _.merge(acc, { [key]: f });
      },
      {},
    );
    if (!this.props.isCC && _.isEmpty(formattedFilters.primary_worker_users)) {
      formattedFilters.primary_worker_users = ['all'];
    }
    if (this.props.isCC) {
      formattedFilters.networks = [this.state.filters.networks];
    }

    this.props.fetchDashboardCases(
      this.props.groupId,
      {
        target: 'allNetworkCases',
        sidx: this.state.sidx,
        sord: this.state.sord,
        filters: { ...formattedFilters, networks: [this.props.networkId] },
        page,
      },
    );
  }

  fetchGroupsUsers(search = '') {
    const { groupId, isCC } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: {
        text: search,
        licensed: false,
      },
    })
      .then((response) => {
        if (response) {
          const { employees: activeUsersInGroup } = response;
          if (search === '' && isCC) {
            return _.concat(
              [noneAssignedOption(this.state)],
              getGroupUsersOptions(activeUsersInGroup, this.state),
            );
          }

          return getGroupUsersOptions(activeUsersInGroup, this.state);
        }

        return [];
      });
  }

  render() {
    const {
      allCases,
      currentGroup,
      groupId,
      isCaseMgmtProvider,
      isCC,
      isFetching,
      networkGroups,
      params,
      serviceTypes,
      showMoreFilters,
      labels,
    } = this.props;
    const { activeUsersInGroup, open } = this.state;
    let filters = [
      serviceTypesFilter(serviceTypes, this.state.filters.service_types),
    ];
    const { useV4toSearchNetworkGroups } = this.props;
    filters = _.compact(_.concat(filters, [
      {
        key: 'care_coordinator_users',
        name: labels.CareCoordinator,
        pluralName: `${labels.CareCoordinator}s`,
        options: _.concat(
          [noneAssignedOption(this.state)],
          getGroupUsersOptions(activeUsersInGroup, this.state),
        ),
      },
      {
        key: 'groups',
        name: 'Organization',
        pluralName: 'Organizations',
        hideSelectAll: true,
        isSingleOptionSelect: true,
        options: networkGroupsOptions(networkGroups, this.state.filters.groups),
        asyncSearch:
          useV4toSearchNetworkGroups ?
            (search) => this.fetchAllNetworkGroups(undefined, search) : null,
        searchPlaceholder: useV4toSearchNetworkGroups ?
          FILTER_CONSTANTS.SEARCH_PLACEHOLDER_ASYNC : FILTER_CONSTANTS.SEARCH_PLACEHOLDER,
        secondary: !showMoreFilters,
      },
      {
        key: 'resolved',
        name: 'Resolution',
        single: true,
        options: [
          { label: 'Resolution (All)', value: 'all' },
          { label: 'Resolved', value: 'true' },
          { label: 'Unresolved', value: 'false' },
        ],
        value: this.state.filters.resolved,
      },
      {
        key: 'status_filter',
        name: 'Status',
        pluralName: 'Statuses',
        options: getStatusesOptions(this.state),
      },
      subNetworksFilter(currentGroup, this.state.filters.networks, true),
    ]));

    filters = _.compact(filters);

    const innerContentView = (
      <CaseDetailsView
        subjectType="case"
        params={params}
        type="allCases"
        showContactColumn
      />
    );

    const allCasesDetailView = (
      <DetailView
        innerContentView={innerContentView}
      />
    );
    const pagedData = getDataOfPage(allCases, this.state.page, this.props.sortAscending);

    return (
      <IndexDetailView
        params={this.props.params}
        DetailView={allCasesDetailView}
        IndexView={AllNetworkCasesIndex}
        data={pagedData.data}
        paging={pagedData.paging}
        isFetching={isFetching}
        onNextClick={this.onNextClick}
        onPrevClick={this.onPrevClick}
        onCellClick={this.onCellClick}
        initialSort={{ [this.state.sidx]: this.state.sord }}
        isCC={isCC}
        isSuperNetwork={isSuperNetwork(currentGroup.networks, groupId)}
        onFiltersChange={this.onFiltersChange}
        filters={_.sortBy(filters, 'name')}
        isCaseMgmtProvider={isCaseMgmtProvider}
        fetchGroupsUsers={this.fetchGroupsUsers}
        onClose={this.onClose}
        onToggle={this.onToggle}
        open={open}
        showMoreFilters={showMoreFilters}
      />
    );
  }
}

AllNetworkCases.propTypes = {
  allCases: PropTypes.shape({
    data: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
  }).isRequired,
  currentGroup: PropTypes.object.isRequired,
  fetchDashboardCases: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isCaseManager: PropTypes.bool.isRequired,
  isCaseMgmtProvider: PropTypes.bool,
  isCC: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  networkGroups: PropTypes.object.isRequired,
  networkId: PropTypes.string,
  params: PropTypes.object.isRequired,
  refetch: PropTypes.bool.isRequired,
  searchNetworkGroups: PropTypes.func.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  useV4toSearchNetworkGroups: PropTypes.func.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  showMoreFilters: PropTypes.bool,
  labels: PropTypes.object,
  sortAscending: PropTypes.bool,
};

AllNetworkCases.defaultProps = {
  labels: defaultLabels,
  sortAscending: false,
};

function mapStateToProps(state) {
  const user = _.wget(state, 'user', {});
  const groupId = _.get(state, 'session.groupId');
  const userGroups = _.get(state, 'groups.data', []);
  const network = getCCUserNetwork(user, groupId);
  const currentGroup = _.find(userGroups, { id: groupId }) || {};

  return {
    allCases: _.get(state, 'dashboard.allNetworkCases', {}),
    currentGroup,
    filters: _.get(state, 'dashboard.allNetworkCases.filters', {}),
    groupId,
    isCaseMgmtProvider: isCaseManagementProvider(state),
    // CORE-WORK - Need to resolve user.groups migration work
    isCC: isCoordinationCenter(_.get(currentGroup, 'networks', []), groupId),
    isFetching: _.get(state, 'dashboard.allCases.isFetching', false),
    networkGroups: _.get(state, 'networks.groups', {}),
    networkId: _.get(network, 'id', null),
    refetch: _.get(state, 'dashboard.refetch'),
    serviceTypes: _.wget(state, 'serviceTypes.allNetworksAndPrograms', []),
    token: _.get(state, 'session.token', ''),
    user,
    userId: _.wget(user, 'id', ''),
    useV4toSearchNetworkGroups: useV4toGetOrganizations(state),
    showMoreFilters: hideMoreFiltersOption(state),
    labels: labelCustomization(state),
    sortAscending: crtb1290SortReferralsByOldest(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      fetchDashboardCases,
      searchNetworkGroups,
      fetchProvidersUserCore,
    }, dispatch),
    dispatch,
  };
}

AllNetworkCases.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllNetworkCases);
