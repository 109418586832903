// DEPRECATED COMPONENT, DO NOT USE. USE APP-COMPONENTS AllOrOne INSTEAD.
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @visibleName AllOrOne DEPRECATED
*/
var AllOrOne = function AllOrOne(props) {
  if (props.showAll) {
    return React.Children.toArray(props.children);
  }
  var renderedChildren = React.Children.toArray(props.children);
  return renderedChildren.length > 0 ? renderedChildren[0] : null;
};
AllOrOne.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  showAll: PropTypes.bool
};
AllOrOne.defaultProps = {
  showAll: false
};
export default AllOrOne;