function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { compact, map, range } from 'lodash';
import moment from 'moment';
import { HOUR_23_59, MIDNIGHT, MINUTES_IN_A_DAY, TIME_FORMAT_DISPLAY, TIME_FORMAT_SERVER } from "./constants";

/**
 * @param {number} time integer number that represents a time of day in minutes. Equals to: (hour * 60) + minutes.
 * @return {string} time input in "h:mm A" format
 */
var formatTimeOfDay = function formatTimeOfDay() {
  var time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var hour = Math.floor(time / 60);
  var minute = time - hour * 60;
  return moment({
    hour: hour,
    minute: minute
  }).format(TIME_FORMAT_SERVER);
};

/**
 * Get time of day options in steps of `increment` minutes, optionally excluding midnight
 */
var timeOfDayOptions = function timeOfDayOptions() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    before = _ref.before,
    _ref$increment = _ref.increment,
    increment = _ref$increment === void 0 ? 15 : _ref$increment,
    _ref$midnightEndsDay = _ref.midnightEndsDay,
    midnightEndsDay = _ref$midnightEndsDay === void 0 ? false : _ref$midnightEndsDay;
  var dayIncrements = Math.floor(MINUTES_IN_A_DAY / increment);
  return compact([!midnightEndsDay && {
    value: '0',
    label: formatTimeOfDay(0),
    disabled: before > 0
  }].concat(_toConsumableArray(map(range(dayIncrements), function (t) {
    if (t === 0 || t === MIDNIGHT) {
      return null;
    }
    var time = t * increment;
    return {
      label: formatTimeOfDay(time),
      value: time.toString(),
      disabled: before ? time <= before : false
    };
  })), [midnightEndsDay && {
    value: HOUR_23_59.toString(),
    label: formatTimeOfDay(0),
    disabled: false
  }]));
};

/**
 * Get time of day options enabled or disabled based on `before`:
 * - options afer `before` will be enabled
 * - options before or equal to `before` will be disabled
 */
var timeSlotsOptions = function timeSlotsOptions(before) {
  return timeOfDayOptions().map(function (_ref2) {
    var label = _ref2.label;
    return {
      value: moment(label, TIME_FORMAT_SERVER).format(TIME_FORMAT_DISPLAY),
      label: label,
      disabled: moment(label, TIME_FORMAT_SERVER).format(TIME_FORMAT_DISPLAY) <= before
    };
  });
};
export default timeSlotsOptions;