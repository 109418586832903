import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';

// Component Imports
import { clearDraftState, withAppCreateReferralContext } from '@unite-us/app-create-referral';
import featureFlag from 'common/utils/FeatureFlag/FeatureFlag';
import { Tabs, Tab, Icon, CheckBoxField, Popover } from '@unite-us/ui';
import { Button } from '@unite-us/app-components';
import ClientSummary from 'src/components/ClientSummary';
import { browserHistory } from 'common/utils/browserHistory';
import callOrLog from 'common/utils/callOrLog';
import { REFERRAL, FACESHEET } from 'common/utils/EventTracker/utils/eventConstants';
import { Serializer } from '@unite-us/client-utils';
import { REFERRALS_CREATE_REFERRALS, RESOURCE_LISTS } from 'common/utils/FeatureFlag/utils/constants';
import { scrn7ScreeningsCompletion } from 'common/utils/FeatureFlags/flags';
import { isEmpty } from 'lodash';
import './FacesheetSubHeader.scss';

const { Rollbar } = window;

function urlMappings(contactId) {
  const baseUrl = `/facesheet/${contactId}`;

  return {
    overview: baseUrl,
    profile: `${baseUrl}/profile`,
    cases: `${baseUrl}/cases`,
    eligibility: `${baseUrl}/eligibility`,
    forms: `${baseUrl}/forms`,
    screenings: `${baseUrl}/screenings`,
    uploads: `${baseUrl}/uploads`,
    referrals: `${baseUrl}/referrals`,
    resource: `${baseUrl}/resource-lists`,
  };
}

export class FacesheetSubHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.activeTab,
      popoverOpen: false,
    };

    this.onReferClicked = this.onReferClicked.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.activeTab,
    });
  }

  handleTabChange(value) {
    const { contact } = this.props;
    const faceSheetConstant = FACESHEET[value];

    browserHistory.push(urlMappings(this.props.contactId)[value]);

    if (faceSheetConstant) {
      const eventPayload = Serializer.build({ contact });
      callOrLog(() => this.context.eventTracker(faceSheetConstant, { contact: eventPayload }));
    } else if (Rollbar) {
      Rollbar.warning(`Untracked Facesheet Value, ${value}`);
    }
  }

  onReferClicked() {
    const { dispatchCreateReferral, contact } = this.props;

    clearDraftState(dispatchCreateReferral);

    callOrLog(() => this.context.eventTracker(
      REFERRAL.onReferButtonClicked,
      null,
      { contact },
    ));

    this.props.onButtonClick();
  }

  render() {
    const {
      contact,
      styles,
      flagEnabled,
      enums,
      isMilitaryFocused,
      isFetching,
      clientRelationship,
      handleOnCheck,
      isSupersetEnabled,
      hasClientLink,
      showDuplicatesLink,
      scrn7ScreeningsCompletionEnabled,
      resourceListDetailEnhancements,
      hbh1025ShowEligibilityAssessmentTab,
    } = this.props;

    return (
      <div className="facesheet-sub-header" id="subheader">
        <div className="row facesheet-sub-header__title-row">
          <div className="col-sm-12 col-md-7">
            <div className="facesheet-sub-header__title-summary">
              {!isEmpty(contact) && (
                <ClientSummary
                  contact={contact}
                  enums={enums}
                  isMilitaryFocused={isMilitaryFocused}
                  isFetching={isFetching}
                  isSupersetEnabled={isSupersetEnabled}
                  hasClientLink={hasClientLink}
                />
              )}
            </div>
          </div>
          {this.props.flagEnabled(REFERRALS_CREATE_REFERRALS) &&
            !isEmpty(contact) &&
            !isEmpty(clientRelationship) && (
              <div className="flex items-center col-sm-12 col-md-5 sm:justify-between md:justify-end">
                {!resourceListDetailEnhancements && (
                  <>
                    <div className="flex mt-2 sm:pl-1">
                      <CheckBoxField
                        id="client-requires-action__checkbox"
                        className="client-requires-action__checkbox"
                        label="Client requires action"
                        checked={clientRelationship.flagged}
                        onChange={handleOnCheck}
                        showError={false}
                        showHint={false}
                      />
                    </div>
                    <div className="ml-3 facesheet-sub-header__refer-button-container">
                      <Button
                        id="subheader-refer-btn"
                        label={`Refer ${contact.first_name}`}
                        labelPosition={'before'}
                        labelStyle={styles.referButton.label}
                        iconRight={
                          <Icon icon="IconMailForward" className="fill-current" />
                        }
                        onClick={this.onReferClicked}
                        primary
                      />
                    </div>
                  </>
                )}
                {resourceListDetailEnhancements && (
                  <div className="ml-3 facesheet-sub-header__refer-button-container">
                    <Button
                      id="subheader-refer-btn"
                      label={`Find Resources For ${contact.first_name}`}
                      labelPosition={'before'}
                      labelStyle={styles.referButton.label}
                      iconLeft={
                        <Icon icon="IconSearch" className="fill-current" />
                      }
                      onClick={this.onReferClicked}
                      onMouseEnter={() => this.setState({ popoverOpen: true })}
                      onMouseLeave={() => this.setState({ popoverOpen: false })}
                      primary
                    />
                    <Popover
                      placement="bottom"
                      isOpen={this.state.popoverOpen}
                      target="subheader-refer-btn"
                      toggle={() => { }}
                      showArrow={false}
                      body={(
                        <div>
                          Find resources to share with your client or<br />
                          create referrals that will be sent to<br />
                          organizations through the Unite Us platform.
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
            )}
        </div>
        <div className="flex justify-between">
          <Tabs
            id="facesheet-tabs"
            className="facesheet-tabs"
            onChange={this.handleTabChange}
            value={this.state.value}
          >
            <Tab
              id="facesheet-overview-tab"
              label="Overview"
              value="overview"
            />
            <Tab id="facesheet-profile-tab" label="Profile" value="profile" />
            <Tab id="facesheet-cases-tab" label="Cases" value="cases" />
            {scrn7ScreeningsCompletionEnabled && (
              <Tab
                id="facesheet-screenings-tab"
                label="Screenings"
                value="screenings"
              />
            )}
            {hbh1025ShowEligibilityAssessmentTab ? (
              <Tab
                id="facesheet-eligibility-assessments-tab"
                label="Eligibility Assessment"
                value="eligibility"
              />
            ) : null}
            {isEmpty(clientRelationship) ? null : (
              <Tab id="facesheet-forms-tab" label="Forms" value="forms" />
            )}
            <Tab id="facesheet-uploads-tab" label="Uploads" value="uploads" />
            {flagEnabled('referrals_view_referral_details') ? (
              <Tab
                id="facesheet-referrals-tab"
                label="Referrals"
                value="referrals"
              />
            ) : null}
            {flagEnabled(RESOURCE_LISTS) ? (
              <Tab
                id="facesheet-resource-lists-tab"
                label="Resources"
                value="resource"
              />
            ) : null}
          </Tabs>
          <div className="flex items-center">
            {showDuplicatesLink && (
              <Link
                className="font-bold text-action-blue"
                to={{
                  pathname: `/people/duplicates/${this.props.contactId}`,
                  query: {
                    back: urlMappings(this.props.contactId)[this.state.value],
                  },
                }}
              >
                Merge
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

FacesheetSubHeader.propTypes = {
  activeTab: PropTypes.string.isRequired,
  clientRelationship: PropTypes.object,
  contact: PropTypes.shape({
    first_name: PropTypes.string,
    full_name: PropTypes.string,
    empi_id: PropTypes.string,
  }).isRequired,
  contactId: PropTypes.string.isRequired,
  dispatchCreateReferral: PropTypes.func,
  enums: PropTypes.object.isRequired,
  flagEnabled: PropTypes.func.isRequired,
  handleOnCheck: PropTypes.func.isRequired,
  hasClientLink: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isMilitaryFocused: PropTypes.bool.isRequired,
  isSupersetEnabled: PropTypes.bool,
  resourceListDetailEnhancements: PropTypes.bool,
  onButtonClick: PropTypes.func.isRequired,
  styles: PropTypes.object,
  showDuplicatesLink: PropTypes.bool.isRequired,
  scrn7ScreeningsCompletionEnabled: PropTypes.bool,
  hbh1025ShowEligibilityAssessmentTab: PropTypes.bool.isRequired,
};

FacesheetSubHeader.defaultProps = {
  isSupersetEnabled: false,
  styles: {
    navHeaders: {
      fontSize: '16px',
    },
    referButton: {
      label: {
        color: 'white',
        fontSize: '12px',
      },
      icon: {
        paddingRight: '10px',
        height: '18px',
        width: '18px',
        fill: 'white',
      },
    },
  },
  clientRelationship: {},
  scrn7ScreeningsCompletionEnabled: false,
  dispatchCreateReferral: () => { },
  resourceListDetailEnhancements: false,
};

FacesheetSubHeader.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  scrn7ScreeningsCompletionEnabled: scrn7ScreeningsCompletion(state),
});

export default connect(mapStateToProps, {})(withAppCreateReferralContext(featureFlag(FacesheetSubHeader)));
