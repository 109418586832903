import React from 'react';
import PropTypes from 'prop-types';

const Nicknames = ({ nicknames }) => (
  <div className="flex items-center">
    <div className="flex flex-col">
      <span className="break-all max-w-xs">
        {nicknames.join(', ')}
      </span>
    </div>
  </div>
);

Nicknames.propTypes = {
  nicknames: PropTypes.array.isRequired,
};

export default Nicknames;
