import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { get } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import { connect } from 'react-redux';
import Stepper from 'common/Stepper/Stepper';
import EligibilityAssessment from 'src/pages/facesheet/_id/eligibility/EligibilityAssessment';
import EligibilityConsent from 'src/pages/facesheet/_id/eligibility/EligibilityConsent';

const NewAssessment = (props) => {
  const { contactId } = props;
  const [step, setStep] = useState(0);
  const steps = [
    {
      title: 'Consent',
      content: null,
      onStepClick: () => setStep(0),
    },
    {
      title: 'Eligibility Assessment',
      content: null,
      stepDisabled: (currentStep) => currentStep < 1,
      onStepClick: () => setStep(1),
    },
  ];
  let stepperRef = useRef();

  const answerConsent = (acceptedConsent) => {
    if (acceptedConsent) {
      stepperRef.setCurrentStep(1);
      return setStep(1);
    }
    return browserHistory.push('/dashboard/clients/all');
  };

  return (
    <div>
      <div className="mb-8">
        <Stepper
          ref={(el) => { stepperRef = el; }}
          steps={steps}
          initialStep={step}
          stepStyle={{ flex: '0 1 auto' }}
          stepperStyle={{ backgroundColor: '#ecf5fd' }}
        />
      </div>
      { step === 0 ? <EligibilityConsent answerConsent={answerConsent} /> : null }
      { step === 1 ? <EligibilityAssessment contactId={contactId} /> : null }
    </div>
  );
};

NewAssessment.propTypes = {
  // contact: PropTypes.object.isRequired,
  // provider: PropTypes.object.isRequired,
  // children: PropTypes.node,
  contactId: PropTypes.string.isRequired,
};

NewAssessment.defaultProps = {
  // children: null,
};

const mapStateToProps = (state, ownProps) => ({
    contactId: get(ownProps, 'params.id'),
});

export default connect(mapStateToProps)(NewAssessment);
