/* eslint-disable */
import ReactDOM from "react-dom";

export const FoodSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5714 0.857143V9.42857C11.5714 9.97321 11.4129 10.4688 11.096 10.9152C10.779 11.3616 10.3661 11.6741 9.85714 11.8527V22.2857C9.85714 22.75 9.6875 23.1518 9.34822 23.4911C9.00893 23.8304 8.60714 24 8.14286 24H6.42857C5.96429 24 5.5625 23.8304 5.22321 23.4911C4.88393 23.1518 4.71429 22.75 4.71429 22.2857V11.8527C4.20536 11.6741 3.79241 11.3616 3.47545 10.9152C3.15848 10.4688 3 9.97321 3 9.42857V0.857143C3 0.625 3.08482 0.424107 3.25446 0.254464C3.42411 0.0848214 3.625 0 3.85714 0C4.08929 0 4.29018 0.0848214 4.45982 0.254464C4.62946 0.424107 4.71429 0.625 4.71429 0.857143V6.42857C4.71429 6.66071 4.79911 6.86161 4.96875 7.03125C5.13839 7.20089 5.33929 7.28571 5.57143 7.28571C5.80357 7.28571 6.00446 7.20089 6.17411 7.03125C6.34375 6.86161 6.42857 6.66071 6.42857 6.42857V0.857143C6.42857 0.625 6.51339 0.424107 6.68304 0.254464C6.85268 0.0848214 7.05357 0 7.28571 0C7.51786 0 7.71875 0.0848214 7.88839 0.254464C8.05804 0.424107 8.14286 0.625 8.14286 0.857143V6.42857C8.14286 6.66071 8.22768 6.86161 8.39732 7.03125C8.56697 7.20089 8.76786 7.28571 9 7.28571C9.23214 7.28571 9.43304 7.20089 9.60268 7.03125C9.77232 6.86161 9.85714 6.66071 9.85714 6.42857V0.857143C9.85714 0.625 9.94196 0.424107 10.1116 0.254464C10.2813 0.0848214 10.4821 0 10.7143 0C10.9464 0 11.1473 0.0848214 11.317 0.254464C11.4866 0.424107 11.5714 0.625 11.5714 0.857143ZM21.8571 0.857143V22.2857C21.8571 22.75 21.6875 23.1518 21.3482 23.4911C21.0089 23.8304 20.6071 24 20.1429 24H18.4286C17.9643 24 17.5625 23.8304 17.2232 23.4911C16.8839 23.1518 16.7143 22.75 16.7143 22.2857V15.4286H13.7143C13.5982 15.4286 13.4978 15.3862 13.4129 15.3013C13.3281 15.2165 13.2857 15.1161 13.2857 15V4.28571C13.2857 3.10714 13.7054 2.09821 14.5446 1.25893C15.3839 0.419643 16.3929 0 17.5714 0H21C21.2321 0 21.433 0.0848214 21.6027 0.254464C21.7723 0.424107 21.8571 0.625 21.8571 0.857143Z" fill="#2C405A"/>
  </svg>
);

export const HousingSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.5745 13.001V20.1464C20.5745 20.4044 20.4802 20.6277 20.2917 20.8163C20.1031 21.0048 19.8798 21.0991 19.6218 21.0991H13.9054V15.3828H10.0946V21.0991H4.37822C4.12019 21.0991 3.8969 21.0048 3.70834 20.8163C3.51978 20.6277 3.4255 20.4044 3.4255 20.1464V13.001C3.4255 12.991 3.42798 12.9762 3.43294 12.9563C3.43791 12.9365 3.44039 12.9216 3.44039 12.9116L12 5.85555L20.5596 12.9116C20.5695 12.9315 20.5745 12.9613 20.5745 13.001ZM23.8941 11.9738L22.9712 13.0754C22.8918 13.1647 22.7876 13.2193 22.6586 13.2391H22.6139C22.4849 13.2391 22.3807 13.2044 22.3013 13.1349L12 4.54555L1.69869 13.1349C1.5796 13.2143 1.46051 13.2491 1.34142 13.2391C1.21241 13.2193 1.1082 13.1647 1.02881 13.0754L0.105858 11.9738C0.0264645 11.8746 -0.00827016 11.758 0.00165403 11.624C0.0115782 11.49 0.0661613 11.3833 0.165403 11.3039L10.8686 2.38704C11.1862 2.12901 11.5633 2 12 2C12.4367 2 12.8138 2.12901 13.1314 2.38704L16.7636 5.42385V2.52102C16.7636 2.38208 16.8083 2.26795 16.8976 2.17864C16.9869 2.08932 17.101 2.04466 17.24 2.04466H20.0981C20.2371 2.04466 20.3512 2.08932 20.4405 2.17864C20.5298 2.26795 20.5745 2.38208 20.5745 2.52102V8.59462L23.8346 11.3039C23.9338 11.3833 23.9884 11.49 23.9983 11.624C24.0083 11.758 23.9735 11.8746 23.8941 11.9738Z" fill="#2C405A"/>
  </svg>
);

export const NEEDS_ICONS = {
  'food': FoodSVG,
  'housing': HousingSVG
};

const identifiedNeeds = [
  'Food',
  'Housing',
];

export const OTHER_NEEDS = [
  'Transportation',
];

export const NEEDS_KEYS = {
  'Transportation': {
    headerName: 'transportation_need_header',
    needName: 'transportation_need',
  },
};

export const NotEligibleHtml = `
<div>
  <button style="background-color: rgba(68, 103, 171, var(--bg-opacity)); color: white; border-radius: 2px; padding-left: 5px; padding-right: 5px; padding-top: 2px; padding-bottom: 2px; font-weight: bold; font-size: 20px;" className="font-bold bg-white text-lg" onClick=\"console.log('hey there')\">You are not eligible. Back!</button>
</div>
`;

export const getNeedsHtml = (needs = ['Food']) => {
  let html = `
    <div class="border-solid border border-dark-pale-blue bg-lighter-grey px-6 py-6">
      <p class="text-lg font-bold mb-5">
        Last Screening identified these Health Related Social Needs:
      </p>
      ${needs.map((need) => (
        `
          <div class="mr-3 cursor-pointer bg-white hover:bg-light-fill-blue border border-solid border-dark-pale-blue py-4 px-5 w-64 rounded inline-block rounded-2xl">
            <span class="inline-block align-middle">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5745 13.001V20.1464C20.5745 20.4044 20.4802 20.6277 20.2917 20.8163C20.1031 21.0048 19.8798 21.0991 19.6218 21.0991H13.9054V15.3828H10.0946V21.0991H4.37822C4.12019 21.0991 3.8969 21.0048 3.70834 20.8163C3.51978 20.6277 3.4255 20.4044 3.4255 20.1464V13.001C3.4255 12.991 3.42798 12.9762 3.43294 12.9563C3.43791 12.9365 3.44039 12.9216 3.44039 12.9116L12 5.85555L20.5596 12.9116C20.5695 12.9315 20.5745 12.9613 20.5745 13.001ZM23.8941 11.9738L22.9712 13.0754C22.8918 13.1647 22.7876 13.2193 22.6586 13.2391H22.6139C22.4849 13.2391 22.3807 13.2044 22.3013 13.1349L12 4.54555L1.69869 13.1349C1.5796 13.2143 1.46051 13.2491 1.34142 13.2391C1.21241 13.2193 1.1082 13.1647 1.02881 13.0754L0.105858 11.9738C0.0264645 11.8746 -0.00827016 11.758 0.00165403 11.624C0.0115782 11.49 0.0661613 11.3833 0.165403 11.3039L10.8686 2.38704C11.1862 2.12901 11.5633 2 12 2C12.4367 2 12.8138 2.12901 13.1314 2.38704L16.7636 5.42385V2.52102C16.7636 2.38208 16.8083 2.26795 16.8976 2.17864C16.9869 2.08932 17.101 2.04466 17.24 2.04466H20.0981C20.2371 2.04466 20.3512 2.08932 20.4405 2.17864C20.5298 2.26795 20.5745 2.38208 20.5745 2.52102V8.59462L23.8346 11.3039C23.9338 11.3833 23.9884 11.49 23.9983 11.624C24.0083 11.758 23.9735 11.8746 23.8941 11.9738Z" fill="#2C405A"/>
              </svg>
            </span>
            <span class="ml-4 text-lg font-bold text-center inline-block align-middle">
              ${ need }
            </span>
          </div>
        `
      ))}
    </div>
  `;
  return html;
};

export const convertToHtml = (El) => {
  const tempDiv = document.createElement("div");
  ReactDOM.render(<El />, tempDiv);
  const htmlString = tempDiv.innerHTML;
  ReactDOM.unmountComponentAtNode(tempDiv);
  tempDiv.remove();
  return htmlString;
};

export const addIdentifiedNeedsAtIndex = (surveyRef, index) => {
  const survey = surveyRef.current;
  const newQuestion = survey.pages[0].addNewQuestion("html", 'html_info');
  newQuestion.html = getNeedsHtml(['Food', 'Housing']);
  const pageElements = survey.pages[0].elements;
  pageElements.splice(index, 0, newQuestion);
};

export const getIdentifiedNeedsHTML = (needs) => {
  if (!needs || !needs.length) {
    return `
      <div class="border-solid border border-dark-pale-blue bg-lighter-grey px-6 py-6">
        <p class="text-lg font-bold">
          No needs identified from the selected screening.
        </p>
      </div>
    `;
  }
  let html = `
    <div class="border-solid border border-dark-pale-blue bg-lighter-grey px-6 py-6">
      <p class="text-lg font-bold mb-5">
        Last Screening identified these Health Related Social Needs:
      </p>
  `;

  if (needs.includes('food')) {
    html += `
      <div class="mr-3 cursor-pointer bg-white hover:bg-light-fill-blue border border-solid border-dark-pale-blue py-4 px-5 w-64 rounded inline-block rounded-2xl">
        <span class="inline-block align-middle">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5714 0.857143V9.42857C11.5714 9.97321 11.4129 10.4688 11.096 10.9152C10.779 11.3616 10.3661 11.6741 9.85714 11.8527V22.2857C9.85714 22.75 9.6875 23.1518 9.34822 23.4911C9.00893 23.8304 8.60714 24 8.14286 24H6.42857C5.96429 24 5.5625 23.8304 5.22321 23.4911C4.88393 23.1518 4.71429 22.75 4.71429 22.2857V11.8527C4.20536 11.6741 3.79241 11.3616 3.47545 10.9152C3.15848 10.4688 3 9.97321 3 9.42857V0.857143C3 0.625 3.08482 0.424107 3.25446 0.254464C3.42411 0.0848214 3.625 0 3.85714 0C4.08929 0 4.29018 0.0848214 4.45982 0.254464C4.62946 0.424107 4.71429 0.625 4.71429 0.857143V6.42857C4.71429 6.66071 4.79911 6.86161 4.96875 7.03125C5.13839 7.20089 5.33929 7.28571 5.57143 7.28571C5.80357 7.28571 6.00446 7.20089 6.17411 7.03125C6.34375 6.86161 6.42857 6.66071 6.42857 6.42857V0.857143C6.42857 0.625 6.51339 0.424107 6.68304 0.254464C6.85268 0.0848214 7.05357 0 7.28571 0C7.51786 0 7.71875 0.0848214 7.88839 0.254464C8.05804 0.424107 8.14286 0.625 8.14286 0.857143V6.42857C8.14286 6.66071 8.22768 6.86161 8.39732 7.03125C8.56697 7.20089 8.76786 7.28571 9 7.28571C9.23214 7.28571 9.43304 7.20089 9.60268 7.03125C9.77232 6.86161 9.85714 6.66071 9.85714 6.42857V0.857143C9.85714 0.625 9.94196 0.424107 10.1116 0.254464C10.2813 0.0848214 10.4821 0 10.7143 0C10.9464 0 11.1473 0.0848214 11.317 0.254464C11.4866 0.424107 11.5714 0.625 11.5714 0.857143ZM21.8571 0.857143V22.2857C21.8571 22.75 21.6875 23.1518 21.3482 23.4911C21.0089 23.8304 20.6071 24 20.1429 24H18.4286C17.9643 24 17.5625 23.8304 17.2232 23.4911C16.8839 23.1518 16.7143 22.75 16.7143 22.2857V15.4286H13.7143C13.5982 15.4286 13.4978 15.3862 13.4129 15.3013C13.3281 15.2165 13.2857 15.1161 13.2857 15V4.28571C13.2857 3.10714 13.7054 2.09821 14.5446 1.25893C15.3839 0.419643 16.3929 0 17.5714 0H21C21.2321 0 21.433 0.0848214 21.6027 0.254464C21.7723 0.424107 21.8571 0.625 21.8571 0.857143Z" fill="#2C405A"/>
          </svg>
        </span>
        <span class="ml-4 text-lg font-bold text-center inline-block align-middle">
          Food
        </span>
      </div>
    `;
  }

  if (needs.includes('housing')) {
    html += `
      <div class="mr-3 cursor-pointer bg-white hover:bg-light-fill-blue border border-solid border-dark-pale-blue py-4 px-5 w-64 rounded inline-block rounded-2xl">
        <span class="inline-block align-middle">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5745 13.001V20.1464C20.5745 20.4044 20.4802 20.6277 20.2917 20.8163C20.1031 21.0048 19.8798 21.0991 19.6218 21.0991H13.9054V15.3828H10.0946V21.0991H4.37822C4.12019 21.0991 3.8969 21.0048 3.70834 20.8163C3.51978 20.6277 3.4255 20.4044 3.4255 20.1464V13.001C3.4255 12.991 3.42798 12.9762 3.43294 12.9563C3.43791 12.9365 3.44039 12.9216 3.44039 12.9116L12 5.85555L20.5596 12.9116C20.5695 12.9315 20.5745 12.9613 20.5745 13.001ZM23.8941 11.9738L22.9712 13.0754C22.8918 13.1647 22.7876 13.2193 22.6586 13.2391H22.6139C22.4849 13.2391 22.3807 13.2044 22.3013 13.1349L12 4.54555L1.69869 13.1349C1.5796 13.2143 1.46051 13.2491 1.34142 13.2391C1.21241 13.2193 1.1082 13.1647 1.02881 13.0754L0.105858 11.9738C0.0264645 11.8746 -0.00827016 11.758 0.00165403 11.624C0.0115782 11.49 0.0661613 11.3833 0.165403 11.3039L10.8686 2.38704C11.1862 2.12901 11.5633 2 12 2C12.4367 2 12.8138 2.12901 13.1314 2.38704L16.7636 5.42385V2.52102C16.7636 2.38208 16.8083 2.26795 16.8976 2.17864C16.9869 2.08932 17.101 2.04466 17.24 2.04466H20.0981C20.2371 2.04466 20.3512 2.08932 20.4405 2.17864C20.5298 2.26795 20.5745 2.38208 20.5745 2.52102V8.59462L23.8346 11.3039C23.9338 11.3833 23.9884 11.49 23.9983 11.624C24.0083 11.758 23.9735 11.8746 23.8941 11.9738Z" fill="#2C405A"/>
          </svg>
        </span>
        <span class="ml-4 text-lg font-bold text-center inline-block align-middle">
          Housing
        </span>
      </div>
    `;
  }

  if (needs.includes('interpersonal_safety')) {
    html += `
      <div class="mr-3 cursor-pointer bg-white hover:bg-light-fill-blue border border-solid border-dark-pale-blue py-4 px-5 w-64 rounded inline-block rounded-2xl">
        <span class="inline-block align-middle">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5745 13.001V20.1464C20.5745 20.4044 20.4802 20.6277 20.2917 20.8163C20.1031 21.0048 19.8798 21.0991 19.6218 21.0991H13.9054V15.3828H10.0946V21.0991H4.37822C4.12019 21.0991 3.8969 21.0048 3.70834 20.8163C3.51978 20.6277 3.4255 20.4044 3.4255 20.1464V13.001C3.4255 12.991 3.42798 12.9762 3.43294 12.9563C3.43791 12.9365 3.44039 12.9216 3.44039 12.9116L12 5.85555L20.5596 12.9116C20.5695 12.9315 20.5745 12.9613 20.5745 13.001ZM23.8941 11.9738L22.9712 13.0754C22.8918 13.1647 22.7876 13.2193 22.6586 13.2391H22.6139C22.4849 13.2391 22.3807 13.2044 22.3013 13.1349L12 4.54555L1.69869 13.1349C1.5796 13.2143 1.46051 13.2491 1.34142 13.2391C1.21241 13.2193 1.1082 13.1647 1.02881 13.0754L0.105858 11.9738C0.0264645 11.8746 -0.00827016 11.758 0.00165403 11.624C0.0115782 11.49 0.0661613 11.3833 0.165403 11.3039L10.8686 2.38704C11.1862 2.12901 11.5633 2 12 2C12.4367 2 12.8138 2.12901 13.1314 2.38704L16.7636 5.42385V2.52102C16.7636 2.38208 16.8083 2.26795 16.8976 2.17864C16.9869 2.08932 17.101 2.04466 17.24 2.04466H20.0981C20.2371 2.04466 20.3512 2.08932 20.4405 2.17864C20.5298 2.26795 20.5745 2.38208 20.5745 2.52102V8.59462L23.8346 11.3039C23.9338 11.3833 23.9884 11.49 23.9983 11.624C24.0083 11.758 23.9735 11.8746 23.8941 11.9738Z" fill="#2C405A"/>
          </svg>
        </span>
        <span class="ml-4 text-lg font-bold text-center inline-block align-middle">
          Interpersonal Safety
        </span>
      </div>
    `;
  }

  if (needs.includes('utilities')) {
    html += `
      <div class="mr-3 cursor-pointer bg-white hover:bg-light-fill-blue border border-solid border-dark-pale-blue py-4 px-5 w-64 rounded inline-block rounded-2xl">
        <span class="inline-block align-middle">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5745 13.001V20.1464C20.5745 20.4044 20.4802 20.6277 20.2917 20.8163C20.1031 21.0048 19.8798 21.0991 19.6218 21.0991H13.9054V15.3828H10.0946V21.0991H4.37822C4.12019 21.0991 3.8969 21.0048 3.70834 20.8163C3.51978 20.6277 3.4255 20.4044 3.4255 20.1464V13.001C3.4255 12.991 3.42798 12.9762 3.43294 12.9563C3.43791 12.9365 3.44039 12.9216 3.44039 12.9116L12 5.85555L20.5596 12.9116C20.5695 12.9315 20.5745 12.9613 20.5745 13.001ZM23.8941 11.9738L22.9712 13.0754C22.8918 13.1647 22.7876 13.2193 22.6586 13.2391H22.6139C22.4849 13.2391 22.3807 13.2044 22.3013 13.1349L12 4.54555L1.69869 13.1349C1.5796 13.2143 1.46051 13.2491 1.34142 13.2391C1.21241 13.2193 1.1082 13.1647 1.02881 13.0754L0.105858 11.9738C0.0264645 11.8746 -0.00827016 11.758 0.00165403 11.624C0.0115782 11.49 0.0661613 11.3833 0.165403 11.3039L10.8686 2.38704C11.1862 2.12901 11.5633 2 12 2C12.4367 2 12.8138 2.12901 13.1314 2.38704L16.7636 5.42385V2.52102C16.7636 2.38208 16.8083 2.26795 16.8976 2.17864C16.9869 2.08932 17.101 2.04466 17.24 2.04466H20.0981C20.2371 2.04466 20.3512 2.08932 20.4405 2.17864C20.5298 2.26795 20.5745 2.38208 20.5745 2.52102V8.59462L23.8346 11.3039C23.9338 11.3833 23.9884 11.49 23.9983 11.624C24.0083 11.758 23.9735 11.8746 23.8941 11.9738Z" fill="#2C405A"/>
          </svg>
        </span>
        <span class="ml-4 text-lg font-bold text-center inline-block align-middle">
          Utilities
        </span>
      </div>
    `;
  }

  html += `</div>`;
  return html;
};
