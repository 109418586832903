import Notifier from 'common/helpers/Notifier';
import _ from 'lodash';
import { CLOSE_SERVICE_CASE } from 'actions';
import { coreApi } from 'src/api/config';
import moment from 'moment';
import createNote from './createNote';

export default function closeServiceCase(groupId, contactId, caseObj, closing) {
  function canSentClosedDate(addOpenCloseDateOffPlatformCase, isOON, isOpenedDatePresent) {
    if (isOON) {
       return addOpenCloseDateOffPlatformCase;
    }
      return isOpenedDatePresent;
  }

  return async (dispatch) => {
    try {
      await dispatch(createNote({
        interaction: {
          note: closing.note,
          occurred_on: closing.exited_at,
          category: 'general',
        },
        subjectType: 'case',
        subject: caseObj.id,
        opts: { sendSuccessNotification: false },
        context: 'closed',
      }));

      const {
        addOpenCloseDateOffPlatformCase,
        outcome_id,
        exited_at,
        opened_date,
      } = closing;
      const isOON = _.get(caseObj, 'state') === 'off_platform';
      const isOpenedDatePresent = caseObj.opened_date;
      const sendClosedDate = canSentClosedDate(addOpenCloseDateOffPlatformCase, isOON, isOpenedDatePresent);
      const openedDate = opened_date ? moment.unix(opened_date).utc().format('YYYY-MM-DD') : false;

      const updateData = {
        outcome: outcome_id,
        ...(sendClosedDate && {
          closed_date: moment.unix(exited_at).utc().format('YYYY-MM-DD'),
        }),
        ...(openedDate && isOON && sendClosedDate && {
          opened_date: openedDate,
        }),
      };
      const caseResponse = await coreApi.updateRecord('case', caseObj.id, updateData);

      dispatch({
        type: CLOSE_SERVICE_CASE,
        payload: caseResponse,
        contactId,
      });
      Notifier.dispatch(caseResponse.status, 'Case Successfully closed');

      return caseResponse;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}
