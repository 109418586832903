import React from 'react';
import { ColumnHeader } from 'src/common/tables/V2';
import { Checkbox } from 'src/common/TailwindComponents';
import PropTypes from 'prop-types';

const SelectAllCheckboxColumnHeader = ({
 isChecked, onCheckColumn, ariaLabel,
}) => (
  <ColumnHeader className="w-6">
    <Checkbox
      ariaLabel={ariaLabel}
      checked={isChecked}
      id="workqueue-table-header-row-checkbox"
      onChange={onCheckColumn}
    />
  </ColumnHeader>
);

SelectAllCheckboxColumnHeader.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onCheckColumn: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
};

export default SelectAllCheckboxColumnHeader;
