import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Address = ({ address }) => {
  const addressType = _.get(address, 'address_type', '');
  const addressTypeDisplay = _.startCase(addressType);

  const addressLine1 = _.get(address, 'line_1', '');
  const addressLine2 = _.get(address, 'line_2', '');
  const addressDisplayLine1 = _.compact([addressLine1, addressLine2]).join(', ');

  const addressCity = _.get(address, 'city', '');
  const addressState = _.get(address, 'state', '');
  const addressCityState = _.compact([addressCity, addressState]).join(', ');
  const addressPostalCode = _.get(address, 'postal_code', '');
  const addressIsPrimary = address.is_primary ? '(Primary)' : '';
  const addressDisplayLine2 = _.compact([addressCityState, addressPostalCode, addressIsPrimary]).join(' ');

  return (
    <div className="flex items-center">
      <div className="flex flex-col space-y-1">
        <span className="italic">{addressTypeDisplay}</span>
        <span>{addressDisplayLine1}</span>
        <span>{addressDisplayLine2}</span>
      </div>
    </div>
  );
};

Address.propTypes = {
  address: PropTypes.shape({
    address_type: PropTypes.string.isRequired,
    line_1: PropTypes.string,
    line_2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postal_code: PropTypes.string,
    is_primary: PropTypes.bool,
  }).isRequired,
};

export default Address;
