/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { get } from 'lodash';
import { payloadCreateScreen } from 'src/pages/facesheet/_id/eligibility/createScreen';
import SurveyJSFormRenderer from '@unite-us/surveyjs/dist/components/Renderer/SurveyJSFormRenderer';
import buildResponses from '@unite-us/surveyjs/dist/components/Renderer/buildResponses';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import { screeningsApiClient } from 'src/api/config';
import { getIdentifiedNeedsHTML } from './utils';
import './EligibilityAssessment.scss';
import formJSON from './formJSON';

const NotEligible = () => (
  <div>
    <div className="my-5 align-middle inline-block mr-4">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 8C16 3.6129 12.3871 0 8 0C3.58065 0 0 3.6129 0 8C0 12.4194 3.58065 16 8 16C12.3871 16 16 12.4194 16 8ZM8 9.6129C8.80645 9.6129 9.48387 10.2903 9.48387 11.0968C9.48387 11.9355 8.80645 12.5806 8 12.5806C7.16129 12.5806 6.51613 11.9355 6.51613 11.0968C6.51613 10.2903 7.16129 9.6129 8 9.6129ZM6.58065 4.29032C6.54839 4.06452 6.74194 3.87097 6.96774 3.87097H9C9.22581 3.87097 9.41936 4.06452 9.3871 4.29032L9.16129 8.67742C9.12903 8.90323 8.96774 9.03226 8.77419 9.03226H7.19355C7 9.03226 6.83871 8.90323 6.80645 8.67742L6.58065 4.29032Z" fill="#182A44"/>
      </svg>
    </div>
    <div className="font-bold italic align-middle inline-block">
      <p>The client is not eligible for enhanced services per NY1115 Waiver guidelines.</p>
      <p>Submit the assessment and direct the client to existing programs in your network.</p>
    </div>
  </div>
);

const EL_ASSESSMENT_SCREENINGS_2_TEMPLATE_ID = '086f7447-3240-4695-aea8-0007d4330344';

export function EligibilityAssessment(props) {
  const { contactId } = props;
  const [json, setJson] = useState(null);
  const [showNotEligible,] = useState(false);
  const [screenings, setScreenings] = useState([]);

  const divRef = useRef(null); // Create a reference for a DOM element

  const surveyRef = useRef();
  const handleSetSurvey = (survey) => {
    surveyRef.current = survey;
  };

  // Draft
  const createAssessment = async () => {
    payloadCreateScreen.subject_id = contactId;
    const response = await screeningsApiClient.post('/screenings', payloadCreateScreen);
    console.log('response', response);
  };

  const fetchMyScreenings = async () => {
    const queryParams = {
      subject_id: contactId,
      type: 'screening',
    };
    const response = await screeningsApiClient.get('/screenings', { params: queryParams });
    const screens = get(response, 'data.screens', []);
    setScreenings(screens);
    return screens;
  }

  const fetchTemplate = async () => {
    const response = await screeningsApiClient.get(`/templates/${EL_ASSESSMENT_SCREENINGS_2_TEMPLATE_ID}`);
    const questions = response.data.template.questions;

    const screenings = await fetchMyScreenings();
    const elements = formJSON.configuration.pages[0].elements;
    formJSON.configuration.pages[0].elements.forEach((element) => {
      if (!element.title) { return; }

      if (element.name === 'qSelectScreening') {
        element.choices = screenings.map((screen) => ({
          value: screen.id,
          text: screen.name || screen.id, // to be replaced with screen.name
        }))
      }

      let elementRegex = new RegExp(element.title, 'i');
      let question = questions.find((q) => elementRegex.test(q.primaryText.toLowerCase()));

      // Exceptions
      if (!question) {
        if (element.title === 'Is the client enrolled in Medicaid?') {
          question = questions.find((q) => q.id === 'c731300b-c677-48a1-9631-c98bddb82a8d');
        }

        if (element.title === "Are there any needs not identified in the screenings that you'd like to add?") {
          question = questions.find((q) => q.id === 'ba992a45-8dc6-4687-bfea-d1bf33dc6cd3');
        }

        if (element.title === 'Are you receiving any services for the unmet needs?') {
          question = questions.find((q) => q.id === '3b9d8bd2-270e-43b9-b57a-3eddde3a7e64');
        }

        // "How many people in the client's household need food assistance?"
        // if (element.uuid === "5b6778a4-232b-41d4-abba-4ddfb92f7b92") {
        //   question = questions.find((q) => q.id === '5f0465eb-b9f4-4852-b9c3-467bc977d0b2');
        // }

        // "Is the client’s household currently working with a housing provider (Housing Program Recipient)? "
        if (element.uuid === "02d9018c-2af0-4fbc-b80e-25bf07a539ae") {
          question = questions.find((q) => q.id === '8d34acaa-9d06-48ea-91f1-f7806428ec0b');
        }

        // title: "How many people are in the client’s household?"
        if (element.uuid === "c8515992-d6c1-46d4-b684-cc2c3c3eeae1") {
          // primaryText: "How many people are in the client's household?"
          question = questions.find((q) => q.id === '5f0465eb-b9f4-4852-b9c3-467bc977d0b2');
        }

        // title: "Does the client have serious difficulty walking or climbing stairs? (5 years old or older)"
        if (element.uuid === "4f8ed83a-f0e0-4b68-b836-2295b8217daa") {
          // primaryText: "Does the client have serious difficulty walking or climbing stairs? (5 years old or older)"
          question = questions.find((q) => q.id === '4971b19a-7ae3-41f7-aa7b-f2784832557d');
        }

        // title: "Does the client have difficulty dressing or bathing? (5 years old or older)"
        if (element.uuid === "4f8ed83a-f0e0-4b68-b836-2295b8217daa") {
          // primaryText: "Does the client have difficulty dressing or bathing? (5 years old or older)"
          question = questions.find((q) => q.id === 'a3ba2d3e-4592-427b-aa88-8eaf006b647b');
        }

        // title: "How many people in the client’s household need food assistance?"
        if (element.uuid === "5b6778a4-232b-41d4-abba-4ddfb92f7b92") {
          // primaryText: "How many people in the client's household need food assistance?"
          question = questions.find((q) => q.id === '5f0465eb-b9f4-4852-b9c3-467bc977d0b2');
        }

        // title: "Does the client have difficulty dressing or bathing? (5 years old or older)"
        if (element.uuid === "83f3e321-7539-4c8c-83d5-91fa3e89b902") {
          // primaryText: "Does the client have difficulty dressing or bathing? (5 years old or older)"
          question = questions.find((q) => q.id === 'a3ba2d3e-4592-427b-aa88-8eaf006b647');
        }
      }


      // Set custom object in each surveyjs element/question
      if (question) {
        element.custom = {
          questionId: question.id
        };
        if (element.choices) {
          element.choices.forEach((c) => {
            c.custom = {
              ...c.custom,
              questionId: question.id
            };
          })
        }
        
      } else {
        console.log('NO MATCH', { element, question });
      }
    });
    setJson(formJSON);
  };

  const fetchAllTemplates = async () => {
    const response = await screeningsApiClient.get('/screenings/templates');
  };

  const getNeedQuestions = () => {
    const survey = surveyRef.current.pages[0];

    const transportationQuestions = [];
    for (let i = 0; i < 4; i++) {
      transportationQuestions.push(survey.getQuestionByName(`qTransportationNeed${i + 1}`));
    }

    const foodQuestions = [];
    for (let i = 0; i < 9; i++) {
      foodQuestions.push(survey.getQuestionByName(`qFoodNeed${i + 1}`));
    }

    const housingQuestions = [];
    for (let i = 0; i < 6; i++) {
      housingQuestions.push(survey.getQuestionByName(`qHousingNeed${i + 1}`));
    }

    const utilitiesQuestions = [];
    for (let i = 0; i < 3; i++) {
      utilitiesQuestions.push(survey.getQuestionByName(`qUtilitiesNeed${i + 1}`));
    }

    const supplementalQuestions = [];
    for (let i = 0; i < 5; i++) {
      supplementalQuestions.push(survey.getQuestionByName(`qSupplementalQuestions${i + 1}`));
    }

    return {
      transportationQuestions,
      foodQuestions,
      housingQuestions,
      supplementalQuestions,
      utilitiesQuestions,
      all: [
        ...transportationQuestions,
        ...foodQuestions,
        ...housingQuestions,
        ...utilitiesQuestions,
        ...supplementalQuestions,
      ]
    }
  };

  const resetAfterScreeningSelections = () => {
    const survey = surveyRef.current;
    const surveyPage = surveyRef.current.pages[0];

    // reset other needs question
    survey.setValue('qOtherNeeds', []);
    const otherNeedsElement = surveyPage.getQuestionByName('qOtherNeeds');
    otherNeedsElement.choices = [];

    // clear previous selections
    survey.setValue('qReceiveHelp', null);
    survey.setValue('qReceivingServices', []);
    survey.setValue('qIsEnhancedPopulation', null);
    survey.setValue('qSelectEnhanced', []);

    const { all: allNeedQuestions } = getNeedQuestions();
    for (let i = 0; i < allNeedQuestions.length; i++) {
      allNeedQuestions[i].value = null;
    }
  };

  const renderIdentifiedNeeds = (needs) => {
    const ALL_NEEDS_AVAILABLE = [
      'transportation',
      'food',
      'housing',
      // 'interpersonal_safety',
      'utilities'
    ];
    const surveyPage = surveyRef.current.pages[0];
    const question = surveyPage.getQuestionByName('qIdentifiedNeedsHtml');
    const htmlElement = document.getElementById(question.id);

    if (htmlElement) {
      htmlElement.innerHTML = getIdentifiedNeedsHTML(needs);
    }

    
    const otherNeeds = ALL_NEEDS_AVAILABLE.filter((need) => !needs.includes(need));
    const otherNeedsElement = surveyPage.getQuestionByName('qOtherNeeds');
    otherNeedsElement.choices = otherNeeds.map((need) => {
      let text = '';
      if (need === 'transportation') { text = 'Transportation'; }
      if (need === 'food') { text = 'Food'; }
      if (need === 'housing') { text = 'Housing'; }
      // if (need === 'interpersonal_safety') { text = 'Interpersonal Safety'; }
      if (need === 'utilities') { text = 'Utilities'; }
      return {
        value: `${need}_id`,
        text,
      }
    });
  };

  const hideIdentifiedNeeds = () => {
    const surveyPage = surveyRef.current.pages[0];
    const question = surveyPage.getQuestionByName('qIdentifiedNeedsHtml');
    const htmlElement = document.getElementById(question.id);
    if (htmlElement) {
      htmlElement.innerHTML = '';
    }
  };

  // const resetOtherNeeds

  const selectScreening = async (screeningId) => {
    // reset other needs selected
    


    hideIdentifiedNeeds();
    const screening = await screeningsApiClient.get(`/screenings/${screeningId}`);
    resetAfterScreeningSelections();
    const identifiedNeeds = Object.keys(screening.data.identifiedNeeds);
    renderIdentifiedNeeds(identifiedNeeds || []);
  }

  const determineVisibility = (need) => {
    const survey = surveyRef.current.pages[0];

    const qIdentifiedNeedsHtml = document.getElementById(survey.getQuestionByName("qIdentifiedNeedsHtml").id);
    const identifiedNeedsHtml = qIdentifiedNeedsHtml ? qIdentifiedNeedsHtml.innerHTML : '';

    const htmlContainsHousing = identifiedNeedsHtml.indexOf("Housing") !== -1;
    const htmlContainsTransportation = identifiedNeedsHtml.indexOf("Transportation") !== -1;
    const htmlContainsFood = identifiedNeedsHtml.indexOf("Food") !== -1;
    const htmlContainsUtilities = identifiedNeedsHtml.indexOf("Utilities") !== -1;
    const htmlContainsInterpersonalSafety = identifiedNeedsHtml.indexOf("Interpersonal Safety") !== -1;

    const qOtherNeeds = survey.getQuestionByName("qOtherNeeds");
    const otherNeedsContainsHousing = qOtherNeeds && qOtherNeeds.value.includes("housing_id");
    const otherNeedsContainsTransportation = qOtherNeeds && qOtherNeeds.value.includes("transportation_id");
    const otherNeedsContainsFood = qOtherNeeds && qOtherNeeds.value.includes("food_id");
    const otherNeedsContainsUtilities = qOtherNeeds && qOtherNeeds.value.includes("utilities_id");
    const otherNeedsContainsInterpersonalSafety = qOtherNeeds && qOtherNeeds.value.includes("interpersonal_safety_id");

    const hasHousingNeed = htmlContainsHousing || otherNeedsContainsHousing;
    const hasTransportationNeed = htmlContainsTransportation || otherNeedsContainsTransportation;
    const hasFoodNeed = htmlContainsFood || otherNeedsContainsFood;
    const hasUtilitiesNeed = htmlContainsUtilities || otherNeedsContainsUtilities;
    const hasInterpersonalSafetyNeed = htmlContainsInterpersonalSafety || otherNeedsContainsInterpersonalSafety;

    const hasNoNeeds = !hasHousingNeed && !hasTransportationNeed && !hasFoodNeed && !hasUtilitiesNeed && !hasInterpersonalSafetyNeed;
    const hasNeeds = !hasNoNeeds;

    const qIsEnhancedPopulation = survey.getQuestionByName('qIsEnhancedPopulation');
    const qNotEligible = survey.getQuestionByName('qNotEligible');
    const isEnhancedPopulation = qIsEnhancedPopulation.value === 'yes';

    const qIsNeedSpecificQuestionsHeader = survey.getQuestionByName('qNeedSpecificQuestionsHeader');
    const qIsEnrolledInMedicaid = survey.getQuestionByName('qIsEnrolled');
    const isEnrolledInMedicaid = qIsEnrolledInMedicaid.value === '76822cca-4538-4cc0-854e-d71191f621ef';

    const qSelectScreening = survey.getQuestionByName('qSelectScreening');
    const isEnrolledSelectedChoice = qIsEnrolledInMedicaid.choices.find(choice => choice.value === qIsEnrolledInMedicaid.value);
    if (isEnrolledSelectedChoice.text === 'NO') {
      hideIdentifiedNeeds();
      qSelectScreening.value = null;
    }

    const qReceiveHelp = survey.getQuestionByName('qReceiveHelp');
    const clientWantsHelpForNeeds = qReceiveHelp.value === 'yes';
    const clientDoesNotWantHelp = qReceiveHelp.value === 'no';

    const {
      transportationQuestions,
      foodQuestions,
      housingQuestions,
      utilitiesQuestions,
    } = getNeedQuestions();

    qNotEligible.visible = Boolean(!isEnrolledInMedicaid || clientDoesNotWantHelp || hasNoNeeds || qIsEnhancedPopulation.value === 'no');

    qIsNeedSpecificQuestionsHeader.visible = Boolean(hasNeeds && isEnhancedPopulation);

    transportationQuestions.forEach((question) => {
      question.visible = Boolean(hasTransportationNeed && isEnhancedPopulation);
    });


    foodQuestions.forEach((question) => {
      question.visible = Boolean(hasFoodNeed && isEnhancedPopulation);
    });

    housingQuestions.forEach((question) => {
      question.visible = Boolean(hasHousingNeed && isEnhancedPopulation);
    });

    utilitiesQuestions.forEach((question) => {
      question.visible = Boolean(hasUtilitiesNeed && isEnhancedPopulation);
    });


  };

  const onValueChanged = (sender, options) => {
    if (options.name === 'qSelectScreening') {
      const screeningId = options.value;
      if (screeningId) {
        selectScreening(screeningId);
      }
    }

    determineVisibility();
  }

  const submit = async () => {
    const survey = surveyRef.current;
    console.log('Before buildResponses()', buildResponses)
    const responses = buildResponses(surveyRef.current);
    console.log('responses are', responses);
  };

  useEffect(() => {
    // In progress: HBH-1025
    // addIdentifiedNeedsAtIndex(surveyRef.current, 3);
    fetchTemplate();
  }, []);


  if (!json) { return <p>waiting..</p>}

  return (
    <div className="eligibility-assessment">
      <BaseCard className="bg-white">
        <div
          className='border border-solid border-medium-border-blue border-l-0 border-r-0 border-t-0'
        >
          <div className="px-10 py-8">
            <h2 className="text-2xl font-bold mb-2">Eligibility Assessment</h2>
            <p className="text-md">This form is to determine eligibility for HRSN services, if the client is not eligible, you can proceed to send referrals for regular client services.</p>
          </div>
        </div>

        <div className="px-6">
          <BaseCardBody>
            <SurveyJSFormRenderer
              formData={json}
              formSubmission={null}
              editMode={true}
              handleSetSurveyModel={handleSetSurvey}
              onValueChanged={onValueChanged}
            />

            {
              showNotEligible ? (
                <NotEligible />
              ) : null
            }

            <div className="flex justify-between items-center mb-6 mt-3">
              <button className="h-12 mr-4 px-16 py-3 border border-solid border-light-border-blue rounded text-action-darker-blue">
                <span className="inline-block align-middle">Cancel</span>
              </button>

              <div className="flex space-x-4">
                <button className="h-12 px-16 py-3 border border-solid border-medium-border-blue rounded text-action-darker-blue">
                  <span className="inline-block align-middle mr-2">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.9643 3.5L13 0.535714C12.7143 0.25 12.1786 0 11.7857 0H2.21429C1.25 0 0.5 0.785714 0.5 1.71429V14.2857C0.5 15.25 1.25 16 2.21429 16H14.7857C15.7143 16 16.5 15.25 16.5 14.2857V4.71429C16.5 4.32143 16.25 3.78571 15.9643 3.5ZM8.5 13.7143C7.21429 13.7143 6.21429 12.7143 6.21429 11.4286C6.21429 10.1786 7.21429 9.14286 8.5 9.14286C9.75 9.14286 10.7857 10.1786 10.7857 11.4286C10.7857 12.7143 9.75 13.7143 8.5 13.7143ZM11.9286 2.85714V6.42857C11.9286 6.67857 11.7143 6.85714 11.5 6.85714H3.21429C2.96429 6.85714 2.78571 6.67857 2.78571 6.42857V2.71429C2.78571 2.5 2.96429 2.28571 3.21429 2.28571H11.3571C11.4643 2.28571 11.5714 2.35714 11.6786 2.42857L11.7857 2.53571C11.8571 2.60714 11.9286 2.75 11.9286 2.85714Z" fill="#4467AB"/>
                    </svg>
                  </span>
                  <span className="inline-block align-middle">Save Draft</span>
                </button>
                
                <button onClick={submit} className="h-12 px-16 py-3 bg-action-blue rounded text-white">
                  <span className="inline-block align-middle mr-2">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.651414 9.06769C0.379074 9.06769 0.15625 9.29051 0.15625 9.56285V10.5532C0.15625 10.8255 0.379074 11.0483 0.651414 11.0483H4.11757V9.06769H0.651414ZM15.7849 3.40424L12.7551 0.371359C12.6158 0.232094 12.427 0.154724 12.229 0.154724H12.0402V4.11604H16.0015V3.92726C16.0015 3.73229 15.9241 3.54351 15.7849 3.40424ZM11.0499 4.36362V0.154724H4.86031C4.44871 0.154724 4.11757 0.485865 4.11757 0.897471V9.06769H8.07888V7.04989C8.07888 6.60734 8.61428 6.38761 8.92685 6.70018L11.8855 9.68664C12.0897 9.89399 12.0897 10.2251 11.8855 10.4294L8.92375 13.4128C8.61118 13.7253 8.07579 13.5056 8.07579 13.063V11.0483H4.11757V15.2572C4.11757 15.6688 4.44871 16 4.86031 16H15.2588C15.6704 16 16.0015 15.6688 16.0015 15.2572V5.10637H11.7926C11.3841 5.10637 11.0499 4.77213 11.0499 4.36362Z" fill="white"/>
                    </svg>
                  </span>
                  <span className="inline-block align-middle text-white">Submit Assessment</span>
                </button>
              </div>

            </div>
          </BaseCardBody>
        </div>
      </BaseCard>
      <div ref={divRef} style={{ display: "none" }}></div>

      <div className="mt-64">
        <button onClick={() => {
          const survey = surveyRef.current;
          const newQuestion = survey.pages[0].addNewQuestion("text", "favorite_color");
          newQuestion.title = "What is your favorite color?";
          newQuestion.isRequired = true;
          newQuestion.uuid = 'mihai_123';
          newQuestion.setPropertyValue("custom", {
            hello: 'mihai_234',
          });
        }}>trying..</button>
        <button className="block" onClick={fetchTemplate}>fetch template 2</button>
        <button className="block" onClick={fetchAllTemplates}>fetch all templates</button>
        <button className="block" onClick={createAssessment}>create assessment</button>
      </div>
    </div>
  );
}

EligibilityAssessment.propTypes = {
  contact: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  children: PropTypes.node,
};

EligibilityAssessment.defaultProps = {
  children: null,
};

export default EligibilityAssessment;
