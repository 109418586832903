export const caseDetailFields = [
  'case_type',
  'network',
  'program_entry',
  'program',
  'service_case.attachableDocuments[].attached',
  'service_case.attachableDocuments[].document',
  'service_case.description',
  'service_case.documents',
  'service_case.oonCase.selected[].group',
  'service_case.oonCase.custom[].group',
  'service_case.oonGroups[].group',
  'service_case.primary_worker_id',
  'service_case.service_type',
  'service_case.uploadedDocuments',
  'authorizationRequest.amount_requested',
  'authorizationRequest.service_start',
  'authorizationRequest.service_end',
];

export const caseCloseFields = [
  'exited_at',
  'note',
  'outcome_id',
  'resolved',
  'opened_date',
];
