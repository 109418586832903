export default function formatDemographicInfo(input) {
  const ignoreWords = ['or', 'and'];

  let result = input.replace(/-/g, ' ');

  // Capitalize the first letter of each word except for the ignored words
  result = result.replace(/\b\w+/g, (word) => (ignoreWords.includes(word) ?
    word :
    word.charAt(0).toUpperCase() + word.slice(1)));

  return result;
}
