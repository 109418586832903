import { useState } from 'react';
import { intersection, uniq } from 'lodash';

const useWorkqueueRowSelector = ({ pageRows }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const selectedRowIds = selectedRows.map((row) => row.id);

  const deselectAll = () => setSelectedRows([]);
  const selectRow = (row) => {
    setSelectedRows(uniq([...selectedRows, row]));
  };

  const deselectRows = (rows) => {
    const rowsToRemoveIds = rows.map((r) => r.id);
    setSelectedRows(selectedRows.filter((row) => !rowsToRemoveIds.includes(row.id)));
  };

  const selectAllRows = () => {
    if (selectedRows.length) setSelectedRows(uniq([...selectedRows, ...pageRows]));
    else setSelectedRows([...pageRows]);
  };

  const toggleSelectedRow = (r) => {
    if (selectedRowIds.includes(r.id)) {
      deselectRows([r]);
    } else {
      selectRow(r);
    }
  };

  const toggleSelectAllRows = () => {
    const allRowsOnCurrentPageSelected =
      intersection(selectedRowIds, pageRows.map((row) => row.id)).length === pageRows.length;

    if (allRowsOnCurrentPageSelected) {
      deselectRows(pageRows);
    } else {
      selectAllRows();
    }
  };

  return {
    deselectAll,
    deselectRows,
    selectRow,
    selectedRows,
    selectAllRows,
    toggleSelectAllRows,
    toggleSelectedRow,
  };
};

export default useWorkqueueRowSelector;
