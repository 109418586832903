import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const getFullName = (
  title = '',
  first_name = '',
  middle_name = '',
  last_name = '',
  suffix = '',
) => _.compact([title, first_name, middle_name, last_name, suffix]).join(' ');

const Name = ({
  title,
  first_name,
  middle_name,
  last_name,
  suffix,
}) => (
  <div className="flex items-center">
    <div className="flex flex-col">
      <span className="break-all max-w-xs">
        {getFullName(title, first_name, middle_name, last_name, suffix)}
      </span>
    </div>
  </div>
);

Name.defaultProps = {
  title: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  suffix: '',
};

Name.propTypes = {
  title: PropTypes.string,
  first_name: PropTypes.string,
  middle_name: PropTypes.string,
  last_name: PropTypes.string,
  suffix: PropTypes.string,
};

export default Name;
