import React from 'react';
import PropTypes from 'prop-types';
import { get, partition, sumBy } from 'lodash';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import { Card, CardHeader } from 'common/Card';
import formatShortDate from 'common/utils/Dates/formatShortDate';
import Icon from '@unite-us/ui/dist/Display/Icon';
import ToolTip from 'common/ToolTip';
import { LineItem } from 'common/display/LineItem';
import AuthorizationSpendExpandable from './AuthorizationSpendExpandable';

const VerticalDivider = () => <div className="border-l border-dark-fill-blue border-solid" />;

const filterByState = (items) => {
  const approvedItems = items.filter((item) => item.state === 'approved');
  const requestedItems = items.filter((item) => item.state === 'requested');
  return { approvedItems, requestedItems };
};

const ClientServiceUtilizationCard = ({
  feeScheduleProgram,
  serviceAuthorization,
  authorizedSpends,
  totalRequestedSpend,
  capInfo,
  amountAvailable,
}) => {
  const hasCapGroup = !!get(feeScheduleProgram, 'service_authorization_cap_group.id', null);

  // Card subtitle:
  const isSingleDateRequested = serviceAuthorization.requested_starts_at === serviceAuthorization.requested_ends_at;
  const dateRequestedStartsAt = formatShortDate(serviceAuthorization.requested_starts_at);
  const dateRequestedEndsAt = formatShortDate(serviceAuthorization.requested_ends_at);
  const formattedRequestDates = isSingleDateRequested ?
    dateRequestedEndsAt : `${dateRequestedStartsAt} - ${dateRequestedEndsAt}`;
  const subtitle = (
    <div data-testid="date-subtitle">
      The following requests have service delivery dates that overlap with&nbsp;
      {capInfo.hasCap ?
        <span>this cap period: <b>{capInfo.formattedCapPeriod}</b></span> :
        <span>the dates of the current request: <b>{formattedRequestDates}</b></span>}
    </div>
  );

  const isCostBased = feeScheduleProgram && fspUtils.isCostPaymentType(feeScheduleProgram.payment_type);
  const { approvedItems, requestedItems } = filterByState(authorizedSpends);
  const [approvedSameProgramItems, approvedOtherProgramItems] = partition(approvedItems, (item) => (
    item.fee_schedule_program.id === feeScheduleProgram.id
  ));
  const sameProgramSpend = sumBy(approvedSameProgramItems, (item) => item.spend);
  const otherProgramSpend = sumBy(approvedOtherProgramItems, (item) => item.spend);

  return (
    <Card className="bg-white">
      <CardHeader
        className="pt-2 pl-2 space-y-2"
        title="Client Service Utilization"
        subtitle={subtitle}
      />
      <div className="flex flex-col py-4">
        <div className="flex px-6 space-x-12">
          <div className="w-1/2 space-y-4">
            <div className="m-0 grid grid-cols-2 gap-x-8 gap-y-2">
              {capInfo.hasCap && (
                <LineItem
                  colon={false}
                  field={(
                    <div className="flex">
                      Available Amount:
                      <ToolTip
                        data-testid="amount-available-tooltip"
                        tooltipElementAs="span"
                        enableHover
                        buttonComponentContent={() => (
                          <Icon
                            icon="IconInfoCircle"
                            className="fill-current text-text-blue"
                            ariaLabel="Previous Authorization Requests info"
                          />
                        )}
                        buttonClassName="px-2 focus:outline-none"
                        panelComponentContent={() => (
                          'The available amount is the cap minus any accepted authorization requests.'
                        )}
                        panelClassName="bg-white border border-gray-400 border-solid rounded
                        shadow-md text-dark-grey leading-4
                          w-80 px-5 py-3"
                        placement="bottom-start"
                      />
                    </div>
                  )}
                  dataTestElement="amount-available-line-element"
                >
                  {amountAvailable}
                </LineItem>
              )}
              <AuthorizationSpendExpandable
                title="Accepted Requests"
                items={approvedSameProgramItems}
                totalSpend={sameProgramSpend}
                unit={feeScheduleProgram?.unit}
                isAuthorized
                isCostBased={isCostBased}
                currentAuthorizationId={serviceAuthorization.id}
              />
              {hasCapGroup && (
                <AuthorizationSpendExpandable
                  title="Referrals for Related Programs"
                  items={approvedOtherProgramItems}
                  totalSpend={otherProgramSpend}
                  unit={feeScheduleProgram?.unit}
                  isAuthorized
                  isCostBased={isCostBased}
                  currentAuthorizationId={serviceAuthorization.id}
                />
              )}
              {capInfo.hasCap && (
                <LineItem field="Cap" dataTestElement="cap-line-element">
                  {capInfo.formattedCap}
                </LineItem>
              )}
            </div>
          </div>
          <VerticalDivider />
          <div className="w-1/2 space-y-4">
            <div className="m-0 grid grid-cols-2 gap-x-8 gap-y-2">
              { capInfo.hasCap && (
                <LineItem field="Cap Period" dataTestElement="cap-period-line-element">
                  {capInfo.formattedCapPeriod}
                </LineItem>
              )}
              <AuthorizationSpendExpandable
                title="Open Requests"
                items={requestedItems}
                totalSpend={totalRequestedSpend}
                unit={feeScheduleProgram?.unit}
                isCostBased={isCostBased}
                currentAuthorizationId={serviceAuthorization.id}
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

ClientServiceUtilizationCard.propTypes = {
  feeScheduleProgram: PropTypes.object.isRequired,
  serviceAuthorization: PropTypes.object.isRequired,
  authorizedSpends: PropTypes.array.isRequired,
  totalRequestedSpend: PropTypes.number.isRequired,
  amountAvailable: PropTypes.node.isRequired,
  capInfo: PropTypes.shape({
    hasCap: PropTypes.bool.isRequired,
    formattedCap: PropTypes.node,
    formattedCapPeriod: PropTypes.string,
  }),
};

ClientServiceUtilizationCard.defaultProps = {
  capInfo: {
    hasCap: false,
  },
};

export default ClientServiceUtilizationCard;
