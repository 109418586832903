import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import featureFlag from 'src/common/utils/FeatureFlag/FeatureFlag';
import { getContactFromState } from 'src/components/Contacts/utils';
import _, { isEmpty, get } from 'lodash';
import FilterBar from 'src/components/Dashboard/components/FilterBar';
import DropdownMenu from 'src/common/Menu/DropdownMenu';
import { selectContact } from 'actions/Contact';
import {
  useInvalidateQueries,
} from 'src/api/APIHooks';
import {
  Table,
  TableHeader,
  TableBody,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  BaseCard,
  BaseCardBody,
  Button,
  Icon,
} from '@unite-us/ui';
import useFormSubmissions from 'src/components/FormSubmissions/useFormSubmissions';
import useAvailableForms from 'src/components/FormSubmissions/useAvailableForms';
import { browserHistory } from 'src/common/utils/browserHistory';
import moment from 'moment';
import './FacesheetAssessments.scss';

const CREATE_FORM_USAGE_TYPE_OPTIONS = 'general,profile,screening';
const FORM_USAGE_TYPE_OPTIONS = [
  {
    label: 'General',
    value: 'general',
    initial: false,
  },
  {
    label: 'Screening',
    value: 'screening',
    initial: false,
  },
  {
    label: 'Profile',
    value: 'profile',
    initial: false,
  },
];

const defaultFormTypeFilter = 'screening,general,profile';
// Form submissions on facesheet forms tab should not include any other form types
const getFormTypeFilter = (list) => {
  if (!list.length) {
    return defaultFormTypeFilter;
  }
  return list.join(',');
};
export function FacesheetAssessments({
  contact,
  provider,
  children,
}) {
  const size = 50;
  const context = contact.id;
  const contextType = 'person';
  // The consolidate parameter determines whether to consolidate the form submissions
  // based on specific criteria to return form submissions based on usage type:
  // For screenings: apply specific logic to filter and retrieve form submissions.
  // For general and profile cases: use the standard logic to retrieve form submissions.
  const consolidate = true;
  const [page, setPage] = useState(1);
  const [formTypeFilter, setFormTypeFilter] = useState(defaultFormTypeFilter);
  const [formFilter, setFormFilter] = useState('');
  const { formSubmissions, paging, isFetching } = useFormSubmissions({
    context,
    contextType,
    size,
    page,
    providerId: provider.id,
    formTypeFilter,
    formFilter,
    consolidate,
  });

  const { forms } = useAvailableForms({
    providerId: provider.id,
    usageTypes: CREATE_FORM_USAGE_TYPE_OPTIONS,
    relevantServices: null,
  });

  const invalidateQueries = useInvalidateQueries();
  useEffect(() => {
    invalidateQueries('form');
  }, []);
  const formsForSubmissions = forms.map((f) => ({ label: f.name, value: f.id }));
  const filters = [{
    key: 'form_types',
    name: 'All form Types',
    pluralName: 'All form types',
    options: FORM_USAGE_TYPE_OPTIONS,
  }, {
    key: 'forms',
    name: 'Forms',
    pluralName: 'Form types',
    options: formsForSubmissions,
  }];

  const hasFilters = false;

  const handleSelectForm = (formId) => {
    browserHistory.push(`/facesheet/${contact.id}/forms/${formId}/submissions/new`);
  };

  const handleViewFormSubmission = (formSubmission) => {
    selectContact(contact.id);
    browserHistory.push(
      get(formSubmission, 'usage_types', []).includes('Screening') ?
        `/screenings/all/${formSubmission.id}` :
        `/facesheet/${contact.id}/forms/${formSubmission.form.id}/submissions/${formSubmission.id}`,
    );
  };

  if (children) {
    return children;
  }

  return (
    <div className="form-submissions">
      <div className="row place-content-between space-between mb-5">
        <h2 className="col-3 mr-5 my-2">Form Submissions</h2>
        <div className="col-6 my-2">
          <FilterBar
            filters={filters}
            onFiltersChange={(change, v) => {
              if (change === 'form_types') {
                setFormTypeFilter(getFormTypeFilter(v));
              }
              if (change === 'forms') {
                setFormFilter(v.join(','));
              }
            }}
            paging={paging}
            onNextClick={() => setPage(page < paging.total_pages ? (page + 1) : page)}
            onPrevClick={() => setPage(page > 1 ? (page - 1) : page)}
            isFetching={isFetching}
            topMargin={false}
          />
        </div>
        <div className="mx-1 my-2">
          <DropdownMenu
            options={formsForSubmissions}
            onChange={(item) => handleSelectForm(item.value)}
            placeholder={(
              <Button
                id="new-form-submission"
                label="Add New Form Submission"
                labelPosition={'after'}
                iconLeft={<Icon icon="IconPlusCircle" className="fill-current" />}
                primary
              />
            )}
          />
        </div>
      </div>
      <div>
        <BaseCard>
          <BaseCardBody>
            <Table
              noBorder
            >
              <TableHeader className="form-submissions-header">
                <TableRow>
                  <TableHeaderColumn>
                    Form
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Submitter
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Organization
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Submission Date
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Form Type
                  </TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {formSubmissions.map((formSubmission) => (
                  <TableRow
                    key={`table - row - ${formSubmission.id} `}
                    hoverable={false}
                    clickable
                    onCellClick={() => handleViewFormSubmission(formSubmission)}
                  >
                    <TableRowColumn>
                      {get(formSubmission, 'form.name')}
                    </TableRowColumn>
                    <TableRowColumn>
                      {get(formSubmission, 'submitter.full_name')}
                    </TableRowColumn>
                    <TableRowColumn>
                      {formSubmission.provider.name}
                    </TableRowColumn>
                    <TableRowColumn>
                      {moment(get(formSubmission, 'created_at')).format('MMMM DD, YYYY')}
                    </TableRowColumn>
                    <TableRowColumn>
                      {get(formSubmission, 'usage_types').join(', ')}
                    </TableRowColumn>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {isFetching && (
              <div className="flex justify-center p-6">
                <p>
                  Loading...
                </p>
              </div>
            )}
            {!isFetching && isEmpty(formSubmissions) && (
              <div className="flex justify-center p-6">
                <p>
                  {!hasFilters && `There are no form submissions for ${contact.full_name}`}
                  {hasFilters && (
                    'No form submissions match your search criteria. Please try adjusting the filters and search again.'
                  )}
                </p>
              </div>
            )}
          </BaseCardBody>
        </BaseCard>
      </div>
    </div>
  );
}

FacesheetAssessments.propTypes = {
  contact: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  children: PropTypes.node,
};

FacesheetAssessments.defaultProps = {
  children: null,
};

function mapStateToProps(state, ownProps) {
  const contactId = _.wget(ownProps, 'params.id', '');
  const contact = getContactFromState(state, contactId);
  const currentProvider = state.globalState.currentProvider.group;

  return {
    contact,
    provider: currentProvider,
  };
}

export default connect(mapStateToProps)(featureFlag(FacesheetAssessments));
