import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BaseCard, BaseCardBody, Icon } from '@unite-us/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { theme } from 'src/../tailwind.config';
import {
  getProgramAttributesFullNames,
  getStateEnumFullName,
  mapGeography,
} from 'src/components/Group/Programs/utils';
import { getLanguageFullNames } from 'src/components/Groups/GroupDetails/utils';
import DEFAULT_ENUMS from 'src/reducers/constants/defaultEnums';
import { serviceAreaSupportForOrgs } from 'src/common/utils/FeatureFlags/flags';
import { usePrograms, useUniteUsServices } from '../../api/hooks/v1';
import { getProgramServices } from '../../services/apiDataTransform';
import { ProgramDetail } from './ProgramDetail';

const {
  base: { languagesEnum },
  groups: { programs: programsEnum },
  geography: { us_states: usStatesEnum },
} = DEFAULT_ENUMS;

const Programs = ({
  providerId,
  providerLocations,
  providerState,
  readOnly,
  serviceAreaSupportForOrgs: serviceAreaSupportForOrgsFlag,
}) => {
  const { data: programData } = usePrograms(providerId, { excludeDefault: true });
  const { data: services } = useUniteUsServices();
  const [programs, setPrograms] = useState([]);
  const active_programs = programs.filter((program) => program?.is_active);

  useEffect(() => {
    setTimeout(() => {
      if (programData) {
        setPrograms([...programData]);
      }
    });
  }, [programData]);

  const refreshPrograms = ((programId) => {
    const filteredPrograms = programs.filter((item) => item.id !== programId);
    setPrograms(filteredPrograms);
  });

  return (
    <div data-test-element="org_programs_section" className="pt-8 pb-2">
      <div className="flex flex-row mb-4">
        <h2 className="flex-1 text-text-blue">Programs</h2>
        <div className="flex-0 ml-auto">
          {!readOnly && (
            <>

              <Icon className="-mt-1 pr-1" icon="V2Add" color={theme.extend.colors['action-blue']} />
              <Link
                data-test-element="add_program_link"
                className="ml-0.5 mt-0.5"
                to={'/organization/settings/programs/new'}
              >
                Add Program
              </Link>
            </>
          )}
        </div>
      </div>
      {(programs?.length > 0 && services?.length > 0) &&
        active_programs.map((apiProgram) => {
          const program = {
            ...apiProgram,
            accessibility_options:
              getProgramAttributesFullNames(apiProgram?.accessibility_options, programsEnum, 'accessibility_options'),
            cities: mapGeography(apiProgram?.cities),
            counties: mapGeography(apiProgram?.counties),
            delivery_options:
              getProgramAttributesFullNames(apiProgram?.delivery_options, programsEnum, 'delivery_options'),
            languages: getLanguageFullNames(apiProgram?.languages, languagesEnum),
            locations: providerLocations?.filter(
              (providerLocation) => providerLocation?.programs?.find((p) => p?.id === apiProgram?.id),
            ),
            payment_options:
              getProgramAttributesFullNames(apiProgram?.payment_options, programsEnum, 'payment_options'),
            population_restrictions:
              getProgramAttributesFullNames(apiProgram?.population_restrictions, programsEnum, 'population'),
            population_specializations:
              getProgramAttributesFullNames(apiProgram?.population_specializations, programsEnum, 'population'),
            service_types: getProgramServices({
              allServices: services,
              programServiceIds: apiProgram?.services,
            }),
            states: getStateEnumFullName(apiProgram?.states, usStatesEnum),
            transportation_options:
              getProgramAttributesFullNames(apiProgram?.transportation_options, programsEnum, 'transportation_options'),
          };
          return (
            <ProgramDetail
              key={apiProgram.id}
              program={program}
              isDraft={providerState === 'draft'}
              readOnly={readOnly}
              serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
              onRefreshPrograms={refreshPrograms}
            />
          );
        })}
      {(programs?.length === 0 || active_programs.length === 0) &&
        (
          <BaseCard>
            <BaseCardBody>
              <div className="p-10 text-center">
                No programs added. Programs are the different resources your organization offers to clients.
              </div>
            </BaseCardBody>
          </BaseCard>
        )}
    </div>
  );
};

Programs.propTypes = {
  providerId: PropTypes.string.isRequired,
  providerLocations: PropTypes.array,
  readOnly: PropTypes.bool,
  serviceAreaSupportForOrgs: PropTypes.bool,
  providerState: PropTypes.string.isRequired,
};

Programs.defaultProps = {
  providerLocations: [],
  readOnly: false,
  serviceAreaSupportForOrgs: false,
};

const mapStateToProps = (state) => (
  {
    serviceAreaSupportForOrgs: serviceAreaSupportForOrgs(state),
  }
);

export default connect(mapStateToProps)(Programs);
