import React, { useCallback, useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { TrackerContext } from '@unite-us/client-utils';
import { INVOICE } from 'common/utils/EventTracker/utils/eventConstants';
import { SelectField } from 'src/components/Backoffice/form/SelectField';
import { useFind } from 'src/api/APIHooks';
import { TextField, Button, Dialog } from '@unite-us/ui';
import { theme } from 'src/../tailwind.config';
import callOrLog from 'src/common/utils/callOrLog';
import NoteDisclaimer from 'common/Disclaimer/NoteDisclaimer';
import { Spinner } from 'common/spinners';
import { useInvoiceDisputeWorkflow } from 'src/common/utils/FeatureFlags/flags';
import { useDisputeByNetworkLead } from '../hooks/useUpdateInvoice';

const InvoiceDisputeForm = ({
  close,
  invoice,
  networkId,
  setRef,
  showInvoiceDisputeWorkflow,
  onSuccess,
}) => {
  const [values, setValues] = useState({
    invoice_dispute_reason: '',
    dispute_reason_note: '',
  });
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const disputeByNetworkLead = useDisputeByNetworkLead(invoice.id, values, showInvoiceDisputeWorkflow);

  const { isLoading, data } = useFind(
    'invoice_dispute_reasons',
    { fee_schedule: invoice.fee_schedule_id },
    { queryConfig: { placeholderData: undefined } },
  );
  const invoice_dispute_reasons = get(data, 'data.data', []);

  const disputeReasons = invoice_dispute_reasons.map((reason) => ({
    label: reason.display_name,
    value: reason.id,
  }));

  const handleDisputeReason = useCallback(
    (event) => {
      setValues({ ...values, invoice_dispute_reason: event.value });
      setValid(true);
      setSubmitted(false);
    },
    [values, setValues, setValid, setSubmitted],
  );

  const handleDisputeNote = useCallback(
    (event) => {
      setValues({ ...values, dispute_reason_note: event.target.value });
      setSubmitted(false);
    },
    [values, setValues, setSubmitted],
  );

  const cancelDispute = useCallback(() => {
    setValues({
      invoice_dispute_reason: '',
      dispute_reason_note: '',
    });
    setValid(false);
    setSubmitted(false);
    close();
  }, [setValues, setValid, setSubmitted, close]);

  const eventTracker = useContext(TrackerContext);
  const submitDispute = async () => {
    setSubmitted(true);
    if (valid) {
      await disputeByNetworkLead();
      onSuccess();
      callOrLog(() => eventTracker(INVOICE.nlClickedDispute, { current_network: networkId }));
    }
    close();
  };

  if (isLoading) return <Spinner />;
  return (
    <Dialog
      ref={setRef}
      modal
      title={`Dispute Invoice #${invoice.short_id}`}
      titleStyles={{ color: theme.extend.colors['text-blue'] }}
      id="dispute-invoice-modal"
      onRequestClose={cancelDispute}
    >
      <div className="flex flex-col text-left">
        <div className="text-sm pb-4 space-y-6">
          <p>
            Disputes can only be raised within{' '}
            <span className="font-extrabold">30 days </span>
            of an invoice being paid or rejected.
          </p>
          <p>
            By disputing an invoice, you&apos;re signaling there&apos;s an issue with
            {/* eslint-disable-next-line max-len */}
            this invoice. The invoice will remain &quot;In Dispute&quot; until a resolution is reached with the Health Plan.
          </p>
        </div>
        <SelectField
          className="md:col-span-8 xl:col-span-4 px-0"
          value={values.invoice_dispute_reason}
          label="REASON FOR DISPUTE"
          onChange={handleDisputeReason}
          options={disputeReasons}
          placeholder="Choose Code"
          required
        />
        {submitted && !valid && (
          <span id="dispute-reason-error" className="text-red mt-2">
            * Dispute reason code is required.
          </span>
        )}
        <label
          className="ui-form-field__label text-text-blue font-medium-font text-13px pt-4 font-extrabold"
          htmlFor="dispute_note"
        >
          NOTE
        </label>
        <TextField
          id="dispute_note"
          afterLabelContent={<NoteDisclaimer />}
          placeholder="Additional details..."
          value={values.dispute_reason_note}
          onChange={handleDisputeNote}
        />
      </div>
      <div className="flex justify-end space-x-4">
        <Button className="capitalize" onClick={cancelDispute} label="cancel" />
        <Button
          className="text-white capitalize"
          onClick={submitDispute}
          label="Dispute Invoice"
          primary
        />
      </div>
    </Dialog>
  );
};

InvoiceDisputeForm.propTypes = {
  close: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  networkId: PropTypes.string.isRequired,
  setRef: PropTypes.object.isRequired,
  showInvoiceDisputeWorkflow: PropTypes.bool,
  onSuccess: PropTypes.func,
};

InvoiceDisputeForm.defaultProps = {
  showInvoiceDisputeWorkflow: false,
  onSuccess: () => {},
};

InvoiceDisputeForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const networkId = state.networks.networkId;
  return {
    networkId,
    showInvoiceDisputeWorkflow: useInvoiceDisputeWorkflow(state),
  };
}

export { InvoiceDisputeForm };
export default connect(mapStateToProps)(InvoiceDisputeForm);
