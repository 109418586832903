// Library Imports
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Divider,
} from '@unite-us/ui';
import { InfoPanel } from '@unite-us/client-utils';
import { PHI_INFO_MESSAGE, CFR_PART_2_CASE_MESSAGE } from 'common/messageConstants';

// Util Imports
import { hasPaymentsUserAccess, crtb824AddOpenCloseDateOffPlatformCase } from 'common/utils/FeatureFlags/flags';
import { editable } from 'common/utils/Permission/Permission';
import { dividerStatusStyles } from 'common/display/SingleItemDetails/utils/common';
import caseStatus from 'src/components/Cases/utils/caseStatus';
import generateDetailLabelData from 'common/display/SingleItemDetails/utils/CaseLabel';

// Component Imports
import DocumentUploader from 'common/form/DocumentUploader';
import DetailSummary from 'common/display/SingleItemDetails/dashboard/DetailSummary';
import CaseDetailSummaryWrapper from 'src/components/Cases/components/Detail/CaseDetailSummaryWrapper';
import DetailLabelWrapper from 'common/display/SingleItemDetails/DetailLabelWrapper';
import DetailStatusText from 'common/display/SingleItemDetails/DetailStatusText';
import DetailOutcomeWrapper from 'common/display/SingleItemDetails/DetailOutcomeWrapper';
import DetailActionWrapper from 'common/display/SingleItemDetails/DetailActionWrapper';
import ReferralServiceAuthorizationInformation from 'components/Referrals/ReferralDetail/components/ReferralServiceAuthorizationInformation';
import { referralStatuses } from 'src/components/ReferralHistory/utils/referralStatusConstants';
import ServiceCaseActions from './ServiceCaseActions';
import {
  INTERNAL_CASES_STATUSES,
} from '../../constants';

import './ServiceCaseOptions.scss';

function ServiceCaseOptions(props) {
  const {
    currentProviderId,
    groupId,
    groupIsSensitive,
    isCoordinationGroup,
    serviceCase = {},
    type,
    usePaymentsUserRole,
    addOpenCloseDateOffPlatformCase,
    serviceAuthorization,
    isServiceAuthorizationLoaded,
  } = props;

  const contact = serviceCase.person;
  const description = (serviceCase && serviceCase.description) || '';
  const status = caseStatus(serviceCase);
  const isInternalCase = serviceCase && isEmpty(serviceCase.referrals) &&
    INTERNAL_CASES_STATUSES.includes(serviceCase.state);
  const isAuthRequestedOrDenied = serviceAuthorization && ['requested', 'denied'].includes(serviceAuthorization.state);

  return (
    <BaseCard className="service-case-options detail-info">
      <BaseCardHeader noBorder title={`${contact.full_name}'s Case`}>
        { status && referralStatuses[status] && referralStatuses[status]({ showText: false }) }
        <div className="detail-info__status-text">
          <DetailStatusText enumsKey="service_cases" detailObj={serviceCase} />
        </div>
      </BaseCardHeader>
      <Divider style={dividerStatusStyles(status)} />
      <BaseCardBody withPadding>
        <DetailOutcomeWrapper status={status} item={serviceCase} />
        <DetailLabelWrapper
          data={generateDetailLabelData(
            serviceCase,
            groupId,
            contact,
            { usePaymentsUserRole, currentProviderId, addOpenCloseDateOffPlatformCase },
          )}
        />
        <Divider className="mt-4" />
        <CaseDetailSummaryWrapper
          contact={contact}
          serviceCase={serviceCase}
        >
          <DetailSummary
            editable={editable(serviceCase, type, currentProviderId)}
            description={description}
            item={serviceCase}
            descriptionTitle="Case Description"
            descriptionSubtitle={groupIsSensitive ? (
              <InfoPanel
                className="detail-summary__info-panel mb-3"
                message={`Reminder: ${CFR_PART_2_CASE_MESSAGE}`}
              />
            ) : undefined}
            descriptionEditSubtitle={(
              <InfoPanel
                className="service-case-options__info-panel mb-3"
                message={groupIsSensitive ?
                  [PHI_INFO_MESSAGE, CFR_PART_2_CASE_MESSAGE].join(' ') :
                  PHI_INFO_MESSAGE}
              />
            )}
          />
        </CaseDetailSummaryWrapper>
        {isServiceAuthorizationLoaded && (
          <>
            <Divider className="detail-info__horizontal-divider mb-4 mt-4" />
            <ReferralServiceAuthorizationInformation
              serviceAuthorization={serviceAuthorization}
              showRequestedValues={isInternalCase && isAuthRequestedOrDenied}
            />
          </>
        )}
        <Divider className="detail-info__horizontal-divider mb-4 mt-4" />
        <div className="row">
          <div className="col-md-6">
            <h5 className="detail-info__icon-label">Documents</h5>
            <DocumentUploader
              attachedDocuments={serviceCase.documents}
              contactId={contact.id}
              dropzoneName="case-detail-view"
              editable={editable(serviceCase, type, currentProviderId)}
              onAttachDocuments={props.onAttachDocuments}
              onRemoveDocument={props.onRemoveDocument}
              onRenameDocument={props.onRenameDocument}
            />
          </div>
        </div>
        {
          editable(serviceCase, type, currentProviderId, isCoordinationGroup) && (
            <div className="action-section">
              <Divider className="detail-info__horizontal-divider mb-4 mt-2" />
              <DetailActionWrapper
                buttonComp={(
                  <ServiceCaseActions
                    contact={contact}
                    currentProviderId={currentProviderId}
                    isCoordinationGroup={isCoordinationGroup}
                    serviceAuthorization={serviceAuthorization}
                    serviceCase={serviceCase}
                  />
                )}
              />
            </div>
          )
        }
      </BaseCardBody>
    </BaseCard>
  );
}

ServiceCaseOptions.propTypes = {
  currentProviderId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  groupIsSensitive: PropTypes.bool,
  isCoordinationGroup: PropTypes.bool.isRequired,
  isServiceAuthorizationLoaded: PropTypes.bool.isRequired,
  onAttachDocuments: PropTypes.func.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
  onRenameDocument: PropTypes.func.isRequired,
  serviceCase: PropTypes.shape({
    person: PropTypes.shape({
      id: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  serviceAuthorization: PropTypes.object.isRequired,
  type: PropTypes.string,
  usePaymentsUserRole: PropTypes.bool.isRequired,
  addOpenCloseDateOffPlatformCase: PropTypes.bool.isRequired,
};

ServiceCaseOptions.defaultProps = {
  groupIsSensitive: false,
  type: '',
};

ServiceCaseOptions.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    usePaymentsUserRole: hasPaymentsUserAccess(state),
    addOpenCloseDateOffPlatformCase: crtb824AddOpenCloseDateOffPlatformCase(state),
  };
}

export default connect(mapStateToProps)(ServiceCaseOptions);
