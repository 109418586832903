import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { SEARCH_CLIENTS } from '@unite-us/app-search';
import { init, useAppCreateReferralContext } from '@unite-us/app-create-referral';
import { TrackerContext } from '@unite-us/client-utils';
import { ComponentLibraryLoader } from '@unite-us/app-components';
import { getAuthToken } from 'src/api/config';
import { SEARCH_API, CORE_API, GOOGLE_MAPS_API_KEY, SHARES_URL } from 'src/config/env/env.config';
import { get, find } from 'lodash';
import Notifier from 'common/helpers/Notifier';
import { updateGlobalState } from 'actions/Global/globalActions';
import {
  uup459SupersetPhase2 as uup459SupersetPhase2Selector,
  hint716SearchNetworkHubSupportPremiumSelector,
  hint921ManualSendback as hint921ManualSendbackSelector,
  hint1066PaymentsLiteModal as hint1066PaymentsLiteModalSelector,
  hint1199IsAppSharesWithSearchApi as hint1199IsAppSharesWithSearchApiSelector,
  cerb1367ResourceListDetailEnhancements as cerb1367ResourceListDetailEnhancementsSelector,
} from 'common/utils/FeatureFlags/flags';
import { browserHistory } from '../../common/utils/browserHistory';

const getBasename = ({ pathname, route, basename = '' }) => {
  const path = pathname.split(route).at(0);
  return `${basename}${path}${route}`;
};

const AppRL = ComponentLibraryLoader({
  loadFn: () => import('@unite-us/app-resource-list'),
  component: 'AppResourceList',
  loadingApp: 'app-client',
  source: 'app-resource-list',
  datadogRum,
});

function ResourceLists({
  location,
  params,
  employeeId,
  providerId,
  uup459SupersetPhase2,
  hint921ManualSendback,
  hint1066PaymentsLiteModal,
  hint716SearchNetworkHubSupportPremium,
  hint1199IsAppSharesWithSearchApi,
  cerb1367ResourceListDetailEnhancements,
  firstName,
  initialAddresses,
}) {
  const trackEvent = useContext(TrackerContext);

  const basename = getBasename({
    basename: location.basename,
    pathname: location.pathname,
    route: 'resource-lists',
  });

  const { dispatch } = useAppCreateReferralContext();

  const routeReferralFromResourceLists = (id, personId, programs) => {
    dispatch(
      init({
        selectedPrograms: programs,
        person: personId,
        resourceListId: id,
      }),
    );

    browserHistory.push({
      pathname: '/referrals/2/create/builder',
    });
  };

  const routeFindMoreResourceLists = (id, personId) => {
    browserHistory.push(`/referrals/create/add-resources?resource_list=${id}&person=${personId}`);
  };

  const routeFindMore = (personId) => {
    browserHistory.push(`/referrals/create/add-resources?person=${personId}`);
  };

  return (
    <AppRL
      source="app-client"
      basename={basename}
      appState={{
        personId: params.id,
        providerId,
        employeeId,
        firstName,
        // TODO: just pass the flag and check these inside
        editResourceLists: uup459SupersetPhase2,
        addMoreResourceLists: uup459SupersetPhase2,
        createReferralResourceLists: uup459SupersetPhase2,
        hint716SearchNetworkHubSupportPremium,
        hint921ManualSendback,
        hint1066PaymentsLiteModal,
        hint1199IsAppSharesWithSearchApi,
        initialAddresses,
      }}
      callbacks={{
        trackEvent,
        notify: {
          error: (message) => Notifier.dispatch('error', message),
          success: (message) => Notifier.dispatch('success', message),
          warn: (message) => Notifier.dispatch('warning', message),
        },
        routeReferralFromResourceLists,
        routeFindMoreResourceLists,
        routeFindMore,
      }}
      appSettings={{
        client: SEARCH_CLIENTS.APP_CLIENT,
        env: {
          getAuthToken,
          employeeId,
          providerId,
          SHARES_URL,
          googleApiKey: GOOGLE_MAPS_API_KEY,
        },
        endpoints: {
          search: {
            url: SEARCH_API,
          },
          core: {
            url: CORE_API,
          },
        },
        flags: { cerb1367ResourceListDetailEnhancements },
        roles: [],
      }}
    />
  );
}

// Should match shape of address validator in Core
const addressShape = {
  address_type: PropTypes.string.isRequired,
  line_1: PropTypes.string,
  line_2: PropTypes.string,
  city: PropTypes.string.isRequired,
  county: PropTypes.string,
  state: PropTypes.string.isRequired,
  postal_code: PropTypes.string,
  country: PropTypes.string.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  is_primary: PropTypes.bool,
};

ResourceLists.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  employeeId: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  uup459SupersetPhase2: PropTypes.bool.isRequired,
  hint716SearchNetworkHubSupportPremium: PropTypes.bool.isRequired,
  hint921ManualSendback: PropTypes.bool.isRequired,
  hint1066PaymentsLiteModal: PropTypes.bool.isRequired,
  hint1199IsAppSharesWithSearchApi: PropTypes.bool.isRequired,
  cerb1367ResourceListDetailEnhancements: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  initialAddresses: PropTypes.shape({
    CLIENT: PropTypes.arrayOf(PropTypes.shape(addressShape)),
    USER: PropTypes.arrayOf(PropTypes.shape(addressShape)),
    GROUP: PropTypes.arrayOf(PropTypes.shape(addressShape)),
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    currentEmployee: {
      addresses: userAddresses,
      provider: { addresses: groupAddresses, id: providerId },
    },
  } = state.globalState;

  const contactId = get(ownProps, 'params.id');
  const contact = find(state.contacts.contacts, { id: contactId });
  return {
    employeeId: state.globalState.currentEmployee.id,
    uup459SupersetPhase2: uup459SupersetPhase2Selector(state),
    hint716SearchNetworkHubSupportPremium: hint716SearchNetworkHubSupportPremiumSelector(state),
    hint921ManualSendback: hint921ManualSendbackSelector(state),
    hint1066PaymentsLiteModal: hint1066PaymentsLiteModalSelector(state),
    hint1199IsAppSharesWithSearchApi: hint1199IsAppSharesWithSearchApiSelector(state),
    cerb1367ResourceListDetailEnhancements: cerb1367ResourceListDetailEnhancementsSelector(state),
    firstName: contact?.first_name,
    providerId,
    initialAddresses: {
      client: contact?.addresses,
      user: userAddresses,
      ours: groupAddresses,
    },
  };
}

export default connect(
  mapStateToProps,
  {
    updateGlobalState,
  },
  null,
  { forwardRef: true },
)(ResourceLists);
