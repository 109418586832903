export const GOOGLE_MAPS_API_KEY = 'AIzaSyAl5_cHrQgCPbnJOPR82PY2gXf5H6eOlhQ';
export const LAUNCH_DARKLY_KEY = '5d2784762448350761247f70';
export const INTEL_URL = 'https://bi.uniteusdev.com';
export const INTERCOM_APP_ID = 'iclfbr6a';
export const IP_INFO_KEY = '83a51b5f488d26';
export const PAYMENTS_URL = 'https://smso.uniteusdev.com';
export const ROUTE_BASEPATH = '/';
export const SHARES_URL = 'https://groups.uniteusdev.com';
export const TABLEAU_BASE_URL = 'https://tableau.uniteusdev.com';
export const TABLEAU_DASHBOARD_URL = 'ExternalDashboardLandingPagewithButtons/InsightsCenter?';
export const UNITEUS_GEOGRAPHY_URL = 'https://geo.uniteusdev.com/v1';
export const UNITEUS_SUPPORT_URL = 'https://support.uniteus.io/';
export const WS_URL = 'wss://ws.uniteusdev.com/ws';

export const AUTH_URL = 'https://app.auth.uniteusdev.com';
export const CLIENT_ID = '132r2298fh';

export const CORE_BASE_URL = 'https://core.uniteusdev.com';
export const CORE_LEGACY_API_URL = `${CORE_BASE_URL}/legacy`;
export const CORE_API = `${CORE_BASE_URL}/v1`;
export const SEARCH_API = 'https://search-ext-staging.uniteusdev.com/v1';

export const TRACKING_URL = 'https://track.uniteusdev.com';
export const CONSENT_APP_URL = 'https://consent.uniteusdev.com';
export const CONSENT_DOWNLOAD_URL = 'https://s3.amazonaws.com/documents.uniteusdev.com/Unite-Us-Consent.pdf';

export const MESSAGING_SERVICE_URL = 'https://message-service-ext-staging.uniteusdev.com';

export const DATADOG_APPLICATION_ID = 'e2f235ed-bb0b-4509-8850-aafc397cbdcd';
export const DATADOG_CLIENT_TOKEN = 'pubfb64e5a6699cbdc58f897cd12211110b';
export const DATADOG_ENVIRONMENT = 'staging';
export const DATADOG_PROXY_URL = 'https://datadog.uniteusdev.com';
export const DATADOG_LOGS_ENABLED = true;

export const PRD_API_URL = 'https://prd-api.uniteusdev.com';
export const PRD_FE_DOMAIN = 'resources.uniteusdev.com';

export const SCREENING_BASE_URL = 'https://screenings-ingestion.uniteusdev.com';
