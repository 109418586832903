import React from 'react';
import PropTypes from 'prop-types';
import { BaseCard, BaseCardBody, BaseCardHeader } from '@unite-us/ui';
import { get } from 'lodash';
import parse from 'html-react-parser';
import { useFindServices } from 'src/components/Backoffice/api/hooks/v1/serviceHooks';
import './FeeScheduleProgramDetail.scss';

const FeeScheduleProgramDetail = (props) => {
  const capInformation = get(props.feeScheduleProgram, 'cap_information', null);
  const billable = get(props.feeScheduleProgram, 'billable', null);
  const authorization_required = get(props.feeScheduleProgram, 'authorization_required', null);
  const auto_authorizes = get(props.feeScheduleProgram, 'auto_authorizes', null);
  const can_invoice_above_remaining_authorized_amount =
   get(props.feeScheduleProgram, 'can_invoice_above_remaining_authorized_amount', null);
  const fspServices = get(props.feeScheduleProgram, 'services', []);
  const serviceIds = fspServices.map((n) => n.id).toString();
  const pageSize = 50;
  const { data: { data: { data: services } } } = useFindServices(
    serviceIds,
    {
      page: {
        size: pageSize,
        number: 1,
      },
    },
  );
  const childrenServices = services.filter((s) => ('parent' in s));
  const service_names = childrenServices.map((service) => service.name).join(', ');
  return (
    <div className="fee-schedule-program-base-card">
      <BaseCard className="fee-schedule-program-details p-8" noBorder>
        <BaseCardHeader title="Program Details" noBorder className="px-4 font-bold" />
        <BaseCardBody className="px-8 bg-white">
          {
            props.feeScheduleProgram &&
            (
            <div className="grid grid-cols-6">
              <div className="col-start-1 pl-0 pb-2 font-bold">
                External Code:
              </div>
              <div className="capitalize" data-testid="fs-prog-external-code">
                { props.feeScheduleProgram.external_code }
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">
                Service Type:
              </div>
              <div className="capitalize col-span-5 px-0" data-testid="fs-prog-service-type">
                {
                  props.feeScheduleProgram.services && props.feeScheduleProgram.services.length > 0 ?
                  service_names :
                  '-'
                }
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">
                Unit Type:
              </div>
              <div className="capitalize" data-testid="fs-prog-unit-type">
                { props.feeScheduleProgram.unit }
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">
                Unit Rate (cents):
              </div>
              <div className="capitalize" data-testid="fs-prog-unit-rate">
                { props.feeScheduleProgram.unit_rate }
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">
                Payment Type:
              </div>
              <div className="capitalize" data-testid="fs-prog-payment-type">
                { props.feeScheduleProgram.payment_type }
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">
                Cap Information:
              </div>
              <div className="capitalize" data-testid="fs-prog-cap-information">
                { capInformation ? parse(capInformation) : '' }
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">
                Billable:
              </div>
              <div data-testid="fs-prog-billable-requirement">
                { billable ? 'Billable' : 'Not Billable' }
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">
                Authorization:
              </div>
              <div data-testid="fs-prog-auth-requirement">
                { authorization_required ? 'Required' : 'Not Required' }
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">
                Auto-Approved Authorization:
              </div>
              <div data-testid="fs-prog-auto-approved-auth-requirement">
                { auto_authorizes ? 'Yes' : 'No' }
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">
                Invoicing over authorized amount:
              </div>
              <div data-testid="fs-prog-invoice-above-auth-requirement">
                { can_invoice_above_remaining_authorized_amount ? 'Yes' : 'No' }
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">
                Force Zcode Associations:
              </div>
              <div data-testid="fs-prog-force-zcodes">
                { props.feeScheduleProgram.force_zcode_associations ? 'Yes' : 'No' }
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">
                Force Procedure Code Associations:
              </div>
              <div data-testid="fs-prog-force-procedure-codes">
                { props.feeScheduleProgram.force_procedure_code_associations ? 'Yes' : 'No' }
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">
                Force Procedure Code Modifier Associations:
              </div>
              <div data-testid="fs-prog-force-modifiers">
                { props.feeScheduleProgram.force_procedure_code_modifier_associations ? 'Yes' : 'No' }
              </div>
            </div>
            )
          }
        </BaseCardBody>
      </BaseCard>
    </div>
  );
};

FeeScheduleProgramDetail.propTypes = {
  feeScheduleProgram: PropTypes.object.isRequired,
};

export default FeeScheduleProgramDetail;
