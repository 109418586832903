import React from 'react';
import PropTypes from 'prop-types';
import {
  BaseCard,
} from '@unite-us/ui';

const BUTTON_STYLE_A = 'h-12 px-16 py-3 border border-solid border-medium-border-blue rounded text-action-darker-blue';

const EligibilityConsent = ({ answerConsent }) => (
  <div className="form-submissions">
    <BaseCard className="bg-white">
      <div
        className="border border-solid border-medium-border-blue border-l-0 border-r-0 border-t-0"
      >
        <div className="pl-4 py-6">
          <h2 className="text-xl font-bold">Agreement to Eligibility Assessment</h2>
        </div>
      </div>

      <div className="bg-light-fill-grey px-6 pt-4 pl-4">
        <p className="text-md">
          {
            'I would like to see, based on your medical history including information Medicaid ' +
            'collects and from Social Services organizations, if you qualify for any services ' +
            'that could be helpful and which would be free to you. Is this okay with you? ' +
            'Do you have any questions?'
          }
        </p>

        <div className="mt-24 pb-6">
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => answerConsent(false)}
              className={BUTTON_STYLE_A}
            >
              <span className="inline-block align-middle">Client Declines</span>
            </button>

            <button
              type="button"
              onClick={() => answerConsent(true)}
              className="h-12 px-16 py-3 bg-action-blue rounded text-white"
            >
              <span className="inline-block align-middle text-white">Client Accepts</span>
            </button>
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
);

EligibilityConsent.propTypes = {
  answerConsent: PropTypes.func.isRequired,
};

export default EligibilityConsent;
