/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get, upperFirst } from 'lodash';
import { connect } from 'react-redux';
import { isEmpty, wget } from 'lodash';
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableBody,
  BaseCard,
  BaseCardBody,
  TableRowColumn
} from '@unite-us/ui';
import { Badge } from '@unite-us/app-components';
import { browserHistory } from 'common/utils/browserHistory';
import { screeningsApiClient, coreApi } from 'src/api/config';
import { getContactFromState } from 'src/components/Contacts/utils';
import AddCirclePlusButton from 'common/display/Profile/components/AddCirclePlusButton';

export function EligibilityAssessments({
  contact,
  // provider,
  children,
}) {
  const [assessments, setAssessments] = useState([]);
  const isFetching = false;
  const hasFilters = false;

  useEffect(() => {
    if (!assessments.length) {
      fetchAssessments();
    }
  }, []);

  const StatusBadge = ({ status }) => {
    const commonClasses = "leading-4 w-20"
    if (status === 'Complete') {
      // tw color 'dark-green'
      return <Badge text={status} className={commonClasses} color="#078B00" />;
    }
    if (status === 'Draft') {
      // tw color 'canary-yellow'
      return <Badge text={status} className={commonClasses} color="#F4D316" />;
    }
    if (status === 'Declined') {
      // tw color 'red'
      return <Badge text={status} className={commonClasses} color="#D0021B" />;
    }

    return <Badge text={status} className={commonClasses} color="#F4D316" />;
  };

  const fetchAssessments = async () => {
    try {
      const queryParams = {
        // limit: 50,
        subject_id: contact.id,
        type: 'screening'
      };
      // subject_id=0942e6f2-e9e2-4acf-a91a-e5552a2bdb4f&offset=0
      const response = await screeningsApiClient.get('/screenings', { params: queryParams });
      const screens = get(response, 'data.screens', []);
      const subjects = screens.map((s) => s.subject.id);
      const organizations = screens.map((s) => s.organizationId);
      const [peopleResponse, providersResponse] = await Promise.all([
        coreApi.query('person', { ids: subjects }),
        coreApi.query('provider', { ids: organizations }),
      ]);

      screens.forEach((screen) => {
        const person = peopleResponse.data.data.find((p) => p.id === screen.subject.id);
        const provider = providersResponse.data.data.find((p) => p.id === screen.organizationId);
        screen.person = person;
        screen.provider = provider;
      });

      setAssessments(screens);
    } catch (error) {
      console.log('EligibilityAssessments.fetchAssessments', error);
    }
  };

  if (children) {
    return children;
  }

  return (
    <div className="eligibility-assessments">
      <div className="mb-5 flex justify-end" aria-hidden="true">
        <h2 className="w-9/12 mb-4 my-2">Eligibility Assessment</h2>
        <div className="w-3/12 flex justify-end">
          <AddCirclePlusButton
            id="add-new-assessment"
            labelText="Add new assessment"
            addHandler={() => browserHistory.push(`/facesheet/${contact.id}/eligibility/new`)}
          />
        </div>
      </div>

      <div>
        <BaseCard>
          <BaseCardBody>
            <Table
              noBorder
            >
              <TableHeader className="form-submissions-header">
                <TableRow>
                  <TableHeaderColumn>
                    Form
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Submitter
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Status
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Organization
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Last Updated
                  </TableHeaderColumn>
                </TableRow>
              </TableHeader>
              {
                assessments && assessments.length ? (
                  <TableBody>
                    {
                      assessments.map((assessment) => {
                        const lastUpdated = assessment.updatedAt ? moment(assessment.updatedAt).format('LL') : '';
                        return (
                          <TableRow>
                            <TableRowColumn>
                              Eligibility Assessment
                            </TableRowColumn>
                            <TableRowColumn>
                              { get(assessment, 'person.full_name', '') }
                            </TableRowColumn>
                            <TableRowColumn>
                              <StatusBadge status={ upperFirst(get(assessment, 'status', '-')) } />
                            </TableRowColumn>
                            <TableRowColumn>
                              { get(assessment, 'provider.name', '') }
                            </TableRowColumn>
                            <TableRowColumn>
                              { lastUpdated }
                            </TableRowColumn>
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                ) : null
              }
              
            </Table>
            {isFetching && (
              <div className="flex justify-center p-6">
                <p>
                  Loading...
                </p>
              </div>
            )}
            {!isFetching && isEmpty(assessments) && (
              <div className="flex justify-center p-6">
                <p>
                  {!hasFilters && `There are no eligibility assessments for ${contact.full_name}`}
                  {hasFilters && (
                    'No eligibility assessments match your search criteria. Please try adjusting the filters and search again.'
                  )}
                </p>
              </div>
            )}
          </BaseCardBody>
        </BaseCard>
      </div>
    </div>
  );
};

EligibilityAssessments.propTypes = {
  contact: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  children: PropTypes.node,
};

EligibilityAssessments.defaultProps = {
  children: null,
};

function mapStateToProps(state, ownProps) {
  const contactId = wget(ownProps, 'params.id', '');
  const contact = getContactFromState(state, contactId);
  const currentProvider = state.globalState.currentProvider.group;

  return {
    contact,
    provider: currentProvider,
  };
}

export default connect(mapStateToProps)(EligibilityAssessments);
