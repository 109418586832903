import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  tracker,
} from '@unite-us/client-utils';
import IndexDetailView from 'src/components/Dashboard/components/IndexDetailView';
import OpenCasesIndex from 'src/components/Dashboard/components/Cases/OpenCasesIndex';
import { fetchDashboardCases } from 'actions/Case/Contact/Group';
import { fetchGroupsPrograms } from 'actions/Program/Group';
import _ from 'lodash';
import DetailView from 'src/components/Dashboard/components/DetailView';
import CaseDetailsView from 'src/components/Cases/components/Detail/CaseDetailsView';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import { isCaseManagementProvider } from 'common/utils/User/userGroup';
import { isCoordinationCenter } from 'common/utils/Group';
import { hasFeatureRole } from 'common/utils/Roles/hasFeatureRole';
import {
  careCoordinatorFilterDefault,
  noneAssignedOption,
} from 'src/components/Dashboard/utils/filterDefaults';
import { getDataOfPage, isDataValid } from 'src/components/Dashboard/utils/dataHelpers';
import callOrLog from 'src/common/utils/callOrLog';
import { getCCUserNetwork } from 'src/components/User/utils';
import { searchNetworkGroups } from 'actions/Group/Network';
import { goToCase } from 'src/components/Cases/utils/routing';
import { careCoordinatorsFilter, serviceTypesFilter } from 'src/components/Dashboard/utils/filter';
import { DASHBOARD_VIEWS, DASHBOARD_EVENTS } from 'common/utils/EventTracker/utils/eventConstants';
import { isSuperNetwork } from 'src/components/Network/utils';
import {
  useV4toGetOrganizations,
  pays5604AuthorizationForInternalCases,
  crtb1290SortReferralsByOldest,
} from 'src/common/utils/FeatureFlags/flags';
import {
  getAuthorizationStatusesOptions,
  getGroupUsersOptions,
  getPrimaryWorkersOptions,
} from 'src/components/Groups/Users/utils';
import { getProgramsOptions } from 'src/components/Groups/Programs/utils';
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';

export class CasesOpenCases extends Component {
  constructor(props) {
    super(props);
    this.fetch = this.fetch.bind(this);
    this.fetchAllNetworkGroups = this.fetchAllNetworkGroups.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.shouldFetch = this.shouldFetch.bind(this);
    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.fetchPrimaryWorkers = this.fetchPrimaryWorkers.bind(this);
    this.state = {
      activePrograms: [],
      activeUsersInGroup: [],
      primaryWorkers: [],
      page: 1,
      sidx: 'updated_at',
      sord: 'desc',
      filters: {
        authorization_statuses: _.get(props, 'filters.authorization_status_filter', []),
        service_types: _.get(props, 'filters.service_types', []),
        care_coordinator_users: _.get(
          props,
          'filters.care_coordinator_users',
          careCoordinatorFilterDefault(props.userId, props.isCaseManager && props.isCC),
        ),
        programs: _.get(props, 'filters.programs_filter', []),
        primary_worker_users: _.get(
          props,
          'filters.primary_worker_users',
          [props.currentEmployee.id],
        ),
      },
    };
  }

  componentDidMount() {
    this.fetch();
    this.props.fetchProvidersUserCore({
      providers: this.props.groupId,
    })
      .then((response) => {
        if (response) {
          const { employees: activeUsersInGroup } = response;
          this.setState({ activeUsersInGroup, primaryWorkers: response.employees });
        }
      });

    const { useV4toSearchNetworkGroups } = this.props;
    if (useV4toSearchNetworkGroups && this.props.networkId) {
      this.fetchAllNetworkGroups();
    }

    if (!useV4toSearchNetworkGroups && this.props.networkId && _.isEmpty(this.props.networkGroups)) {
      this.fetchAllNetworkGroups();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.get(this.props, 'openCases.currentPage', 1) !== _.get(nextProps, 'openCases.currentPage', 1)) {
      this.setState({ page: nextProps.openCases.currentPage });
    }

    if (!this.props.refetch && nextProps.refetch) {
      this.fetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetch();
    }
  }

  onCellClick(row) {
    const pagedData = getDataOfPage(this.props.openCases, this.state.page, this.props.sortAscending);
    const openCase = pagedData.data[row];

    callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.openCasesRow, {
      view: DASHBOARD_VIEWS.openCases,
    }, { serviceCase: openCase }));

    goToCase(openCase);
  }

  onFiltersChange(key, activeFilters, callbacks) {
    let newFilters = activeFilters;
    if (_.isEqual(key, 'care_coordinator_users')) {
      newFilters = careCoordinatorsFilter(activeFilters, callbacks);
    }
    this.setState({
      page: 1,
      filters: _.assign({}, this.state.filters, { [key]: newFilters }),
    }, () => {
      callOrLog(() => {
        const filters = tracker.buildDashboardFilterPayload(this.state, this.props);
        this.context.eventTracker(DASHBOARD_EVENTS.openCasesFilter, filters);
      });
      this.fetch();
    });
  }

  onNextClick() {
    const page = this.state.page + 1;

    this.shouldFetch(page);
  }

  onPrevClick() {
    const page = this.state.page - 1;

    this.shouldFetch(page);
  }

  fetchAllNetworkGroups(page = 1, search = undefined) {
    const { useV4toSearchNetworkGroups } = this.props;
    return this.props.searchNetworkGroups(
      this.props.networkId,
      {},
      { page, search },
      useV4toSearchNetworkGroups,
    ).then((response) => {
      if (useV4toSearchNetworkGroups) {
        const dropdownResponse = response.map((provider) => ({
          label: provider.name,
          value: provider.id,
          initial: false,
        }));
        return dropdownResponse;
      }
      if (!useV4toSearchNetworkGroups) {
        const nextPage = _.get(response, 'data.paging.next_page');
        if (nextPage) {
          this.fetchAllNetworkGroups(nextPage);
        }
      }
      return true;
    });
  }

  shouldFetch(page) {
    if (isDataValid(this.props.openCases, page)) {
      this.setState({ page });
    } else {
      this.fetch(page);
    }
  }

  fetch(page = 1) {
    const formattedFilters = _.reduce(
      this.state.filters,
      (acc, f, key) => {
        if (f === 'all') {
          return acc;
        }
        return _.merge(acc, { [key]: f });
      },
      {},
    );
    formattedFilters.primary_worker_users = _.uniq(
      _.map(formattedFilters.primary_worker_users, (f) => f.split('-primary_worker')[0]),
    );
    this.props.fetchDashboardCases(
      this.props.groupId,
      {
        target: 'openCases',
        sidx: this.state.sidx,
        sord: this.state.sord,
        filters: formattedFilters,
        page,
      },
      this.props.pays5604AuthorizationForInternalCases,
    );
    this.props.fetchGroupsPrograms(this.props.groupId, { active: true })
      .then((res) => { this.setState({ activePrograms: res.data.data }); });
  }

  fetchGroupsUsers(search = '') {
    const { groupId, isCC } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: {
        licensed: false,
        text: search,
      },
    })
      .then((response) => {
        if (response) {
          const { employees: activeUsersInGroup } = response;
          if (search === '' && isCC) {
            return _.concat(
              [noneAssignedOption(this.state)],
              getGroupUsersOptions(activeUsersInGroup, this.state),
            );
          }

          return getGroupUsersOptions(activeUsersInGroup, this.state);
        }

        return [];
      });
  }

  fetchPrimaryWorkers(search = '') {
    const { groupId, currentEmployee } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: {
        licensed: false,
        text: search,
      },
    })
      .then((response) => {
        if (response) {
          const { employees: primaryWorkers } = response;

          return getPrimaryWorkersOptions(
            primaryWorkers,
            this.state,
            currentEmployee,
          );
        }

        return [];
      });
  }

  render() {
    const {
      currentEmployee,
      currentGroup,
      groupId,
      isCaseMgmtProvider,
      isCC,
      isFetching,
      location,
      openCases,
      params,
      serviceTypes,
      labels,
    } = this.props;
    const { activePrograms, activeUsersInGroup, primaryWorkers } = this.state;

    const hasPaymentsRole = hasFeatureRole({
      employeeRoles: currentEmployee?.roles,
      targetRoleKey: 'payments',
    });

    let filters = [
      serviceTypesFilter(serviceTypes, this.state.filters.service_types),
    ];

    filters = _.concat(filters, [
      {
        key: 'primary_worker_users',
        name: 'Primary Worker',
        pluralName: 'Primary Workers',
        options: getPrimaryWorkersOptions(
          primaryWorkers,
          this.state,
          currentEmployee,
        ),
        asyncSearch: this.fetchPrimaryWorkers,
      },
      {
        key: 'care_coordinator_users',
        name: labels.CareCoordinator,
        pluralName: `${labels.CareCoordinator}s`,
        options: _.concat(
          [noneAssignedOption(this.state.filters.care_coordinator_users)],
          getGroupUsersOptions(activeUsersInGroup, this.state),
        ),
      },
      {
        key: 'programs_filter',
        name: 'Program',
        pluralName: 'Programs',
        options: getProgramsOptions(activePrograms, this.state),
      },
      this.props.pays5604AuthorizationForInternalCases && hasPaymentsRole &&
        {
          key: 'authorization_status_filter',
          name: 'Authorization Status',
          pluralName: 'Authorization Statuses',
          options: getAuthorizationStatusesOptions(this.state),
        },
    ]);

    filters = _.compact(filters);

    const innerContentView = (
      <CaseDetailsView
        subjectType="case"
        params={params}
        history={location.state}
        type="openCases"
        showContactColumn
      />
    );

    const openCasesDetailView = (
      <DetailView
        innerContentView={innerContentView}
      />
    );
    const pagedData = getDataOfPage(openCases, this.state.page, this.props.sortAscending);

    return (
      <IndexDetailView
        params={this.props.params}
        DetailView={openCasesDetailView}
        IndexView={OpenCasesIndex}
        data={pagedData.data}
        paging={pagedData.paging}
        isFetching={isFetching}
        onNextClick={this.onNextClick}
        onPrevClick={this.onPrevClick}
        onCellClick={this.onCellClick}
        initialSort={{ [this.state.sidx]: this.state.sord }}
        isCC={isCC}
        isSuperNetwork={isSuperNetwork(currentGroup.networks, groupId)}
        onFiltersChange={this.onFiltersChange}
        filters={_.sortBy(filters, 'name')}
        isCaseMgmtProvider={isCaseMgmtProvider}
        fetchGroupsUsers={this.fetchGroupsUsers}
      />
    );
  }
}

CasesOpenCases.defaultProps = {
  location: { state: {} },
  networkId: undefined,
  labels: defaultLabels,
  sortAscending: false,
};

CasesOpenCases.propTypes = {
  currentGroup: PropTypes.object.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  fetchDashboardCases: PropTypes.func.isRequired,
  fetchGroupsPrograms: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isCaseManager: PropTypes.bool.isRequired,
  isCaseMgmtProvider: PropTypes.bool.isRequired,
  isCC: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  location: PropTypes.object,
  networkGroups: PropTypes.object.isRequired,
  networkId: PropTypes.string,
  openCases: PropTypes.shape({
    data: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
  }).isRequired,
  params: PropTypes.object.isRequired,
  pays5604AuthorizationForInternalCases: PropTypes.bool.isRequired,
  refetch: PropTypes.bool.isRequired,
  searchNetworkGroups: PropTypes.func.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  useV4toSearchNetworkGroups: PropTypes.func.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  labels: PropTypes.object,
  sortAscending: PropTypes.bool,
};

function mapStateToProps(state) {
  const user = _.wget(state, 'user', {});
  const groupId = _.get(state, 'session.groupId');
  const userGroups = _.get(state, 'groups.data');
  const network = getCCUserNetwork(user, groupId);
  const { currentEmployee } = state.globalState;
  const currentGroup = _.find(userGroups, { id: groupId }) || {};

  return {
    currentGroup,
    currentEmployee,
    filters: _.get(state, 'dashboard.openCases.filters', {}),
    groupId,
    isCaseMgmtProvider: isCaseManagementProvider(state),
    // CORE-WORK - Need to resolve user.groups migration work
    isCC: isCoordinationCenter(_.get(currentGroup, 'networks', []), groupId),
    isFetching: _.get(state, 'dashboard.openCases.isFetching', false),
    networkGroups: _.get(state, 'networks.groups', {}),
    networkId: _.get(network, 'id', null),
    openCases: _.get(state, 'dashboard.openCases', {}),
    pays5604AuthorizationForInternalCases: pays5604AuthorizationForInternalCases(state),
    refetch: _.get(state, 'dashboard.refetch'),
    serviceTypes: _.wget(state, 'serviceTypes.allNetworksAndPrograms', []),
    token: _.get(state, 'session.token', ''),
    user,
    userId: _.wget(user, 'id', ''),
    useV4toSearchNetworkGroups: useV4toGetOrganizations(state),
    labels: labelCustomization(state),
    sortAscending: crtb1290SortReferralsByOldest(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      fetchDashboardCases,
      fetchGroupsPrograms,
      searchNetworkGroups,
      fetchProvidersUserCore,
    }, dispatch),
    dispatch,
  };
}

CasesOpenCases.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CasesOpenCases);
