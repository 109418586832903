import moment from 'moment';

const PERSON_WHITELIST = [
  'citizenship',
  'communication_preferences',
  'date_of_birth',
  'email_addresses',
  'ethnicity',
  'first_name',
  'gender',
  'gross_monthly_income',
  'household',
  'last_name',
  'marital_status',
  'middle_name',
  'military',
  'nicknames',
  'phone_numbers',
  'preferred_name',
  'race',
  'suffix',
  'title',
];
const ADDRESS_WHITELIST = [
  'address_type',
  'city',
  'country',
  'county',
  'is_primary',
  'latitude',
  'line_1',
  'line_2',
  'longitude',
  'postal_code',
  'state',
];
const CONTACT_PREFERENCE_WHITELIST = [
  'contact_notes', 'preferred_contact_methods', 'timeslots', 'voice_mail_ok',
];

export const serializeFromWhitelist = (obj, whitelist) => Object.keys(obj)
    .filter((key) => whitelist.includes(key))
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});

export const serializeAddress = (address) => {
  const serializedAddress = serializeFromWhitelist(address.attributes, ADDRESS_WHITELIST);

  return {
    data: {
      id: address.id,
      type: 'address',
      attributes: serializedAddress,
    },
  };
};

const serializeTimeslots = (serializedContactPreference) => {
  const daysOfWeek = Object.keys(serializedContactPreference?.timeslots || []);
  const serializedWithTimeslots = { ...serializedContactPreference };

  if (!daysOfWeek.length) {
    return { ...serializedWithTimeslots, timeslots: undefined };
  }

  daysOfWeek.forEach((day) => {
    const serializedTimeslots = serializedWithTimeslots.timeslots[day].map((timeslot) => {
      const { id, ...serializedTimeslot } = timeslot;
      return serializedTimeslot;
    });
    serializedWithTimeslots.timeslots[day] = serializedTimeslots;
  });

  return serializedWithTimeslots;
};

export const serializeContactPreference = ({ attributes }) => {
  let serializedContactPreference = serializeFromWhitelist(attributes, CONTACT_PREFERENCE_WHITELIST);
  serializedContactPreference = serializeTimeslots(serializedContactPreference);

  return {
    data: {
      attributes: { ...serializedContactPreference },
    },
  };
};

export const serializeMergeData = (mergeData, defaultFields = {}) => {
  const serializedPersonData = serializeFromWhitelist(mergeData, PERSON_WHITELIST);
  let dateOfBirth;

  if (mergeData.date_of_birth) {
    dateOfBirth = moment.utc(mergeData.date_of_birth).format('YYYY-MM-DD');
  }

  const name_fields = mergeData.name ? mergeData.name : undefined;
  const sexuality = mergeData.sexual_orientation ? mergeData.sexual_orientation.sexuality : undefined;
  const sexualityOther =
    mergeData.sexual_orientation &&
    mergeData.sexual_orientation.sexuality?.includes('other') ?
      mergeData.sexual_orientation.sexuality_other :
      undefined;
  const contactPreference = mergeData?.contact_preference;
  const hasContactPreference = contactPreference && Object.keys(contactPreference).length > 0;

  return {
    data: {
      attributes: {
        ...serializedPersonData,
        ...defaultFields,
        communication_preferences: serializedPersonData?.communication_preferences || undefined,
        date_of_birth: dateOfBirth,
        sexuality,
        sexuality_other: sexualityOther,
        ...name_fields,
      },
      relationships: {
        addresses: mergeData.addresses ? mergeData.addresses.map((address) => (serializeAddress(address))) : [],
        contact_preference: hasContactPreference ? serializeContactPreference(contactPreference) : undefined,
      },
    },
  };
};
