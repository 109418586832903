import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import {
  Table,
  TableHeader,
  TableBody,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import AddCirclePlusButton from 'common/display/Profile/components/AddCirclePlusButton';
import { browserHistory } from 'src/common/utils/browserHistory';
import DropdownMenu from 'src/common/Menu/DropdownMenu';
import useFormSubmissions from './useFormSubmissions';
import useAvailableForms from './useAvailableForms';
import LinkButton from '../../common/display/Profile/components/LinkButton';
import './FormSubmissions.scss';

const FormSubmissions = ({
  context,
  contextType,
  providerId,
  serviceId,
  editable,
}) => {
  const [size, setSize] = useState(10);

  const { formSubmissions, paging, isFetching } = useFormSubmissions({
    context,
    contextType,
    size,
    providerId,
  });

  const { forms } = useAvailableForms({
    providerId,
    usageTypes: 'referral_assessment',
    relevantServices: serviceId,
  });
  const actions = forms.map((f) => ({ label: f.name, value: f.id }));

  const isReferralContext = contextType === 'referral';
  const isCaseContext = contextType === 'case';
  const isAssistanceRequestContext = contextType === 'assistance_request';

  const handleSelectForm = (item) => {
    if (isReferralContext) {
      browserHistory.push(`/referrals/${context}/assessments/${item.value}/submissions/new`);
    } else if (isCaseContext) {
      browserHistory.push(`/cases/${context}/assessments/${item.value}/submissions/new`);
    }
  };

  const handleViewFormSubmission = (formSubmission) => {
    if (isReferralContext) {
      browserHistory.push(
        `/referrals/${context}/assessments/${formSubmission.form.id}/submissions/${formSubmission.id}`,
      );
    } else if (isCaseContext) {
      browserHistory.push(
        `/cases/${context}/assessments/${formSubmission.form.id}/submissions/${formSubmission.id}`,
      );
    } else if (isAssistanceRequestContext) {
      browserHistory.push(
        `/assistance-requests/${context}/assessments/${formSubmission.form.id}/submissions/${formSubmission.id}`,
      );
    }
  };

  const visibleShowMore = size < paging.total_count;
  return (
    <div className="form-submissions">
      <div className="flex justify-end" aria-hidden="true">
        <h2 className="w-9/12 mb-4">Form Submissions</h2>
        <div className="w-3/12 flex justify-end">
          {editable && (
          <DropdownMenu
            options={actions}
            onChange={handleSelectForm}
            placeholder={(
              <AddCirclePlusButton
                id="add-new-submission"
                labelText="Add New Form Submission"
              />
            )}
          />
          )}
        </div>
      </div>
      <div>
        <BaseCard>
          <BaseCardBody>
            <Table
              noBorder
            >
              <TableHeader className="form-submissions-header">
                <TableRow>
                  <TableHeaderColumn>
                    Form
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Submitter
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Organization
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    Submission Date
                  </TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {formSubmissions.map((formSubmission) => (
                  <TableRow
                    key={`table-row-${formSubmission.id}`}
                    hoverable={false}
                    clickable
                    onCellClick={() => handleViewFormSubmission(formSubmission)}
                  >
                    <TableRowColumn>
                      {get(formSubmission, 'form.name')}
                    </TableRowColumn>
                    <TableRowColumn>
                      {get(formSubmission, 'submitter.full_name')}
                    </TableRowColumn>
                    <TableRowColumn>
                      {formSubmission.provider.name}
                    </TableRowColumn>
                    <TableRowColumn>
                      {moment(get(formSubmission, 'created_at')).format('MMMM DD, YYYY')}
                    </TableRowColumn>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {isFetching && (
              <div className="flex justify-center p-6">
                <p aria-live="polite" aria-label="Form submissions">
                  Loading...
                </p>
              </div>
            )}
            {!isFetching && isEmpty(formSubmissions) && (
              <div className="flex justify-center p-6">
                <p>
                  {isReferralContext && 'No form submissions on this referral'}
                  {(isCaseContext || isAssistanceRequestContext) && 'No form submissions on this case'}
                </p>
              </div>
            )}
            {!isFetching && (
              <div aria-live="polite" aria-label="form submissions loading complete" />
            )}
          </BaseCardBody>
        </BaseCard>
        {visibleShowMore && (
          <LinkButton
            className="m-2"
            labelText="Show More"
            icon="IconChevronDown"
            onClick={() => {
              setSize(size + 10);
            }}
            aria-hidden="true"
          />
        )}
      </div>
    </div>
  );
};

FormSubmissions.propTypes = {
  providerId: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  contextType: PropTypes.string.isRequired,
  serviceId: PropTypes.string,
  editable: PropTypes.bool,
};
FormSubmissions.defaultProps = {
  serviceId: null,
  editable: true,
};

export default FormSubmissions;
