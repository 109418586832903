import PropTypes from 'prop-types';

export const mergeEmailAddresses = (formValues, comparison, newestClientIndex) => {
  if (!formValues.email_addresses) {
    return comparison?.email_addresses?.[3] || [];
  }

  const equalValues = comparison.email_addresses?.[3] || [];
  const selectedEmailAddresses = formValues.email_addresses || [];
  const allEmails = [...selectedEmailAddresses, ...equalValues];

  const findPrimaryEmail = (clientIndex) => (
    formValues?.email_addresses?.find((email) => comparison?.email_addresses[clientIndex]?.some(
        (e) => e.email_address === email.email_address && e.is_primary,
    ))
  );

  let primaryEmail = findPrimaryEmail(newestClientIndex);

  if (!primaryEmail) {
    // if the newest client has no primary email, use the primary email from equalValues
    // and if that has no primary email, use the email from the oldest client
    // (if the newest client has index 1, the oldest client must have index 0, and vice versa)
    const oldestClientIndex = newestClientIndex ? 0 : 1;
    primaryEmail =
      equalValues.find((email) => email.is_primary) ||
      findPrimaryEmail(oldestClientIndex);
  }

  // Merge all emails
  const mergedEmails = allEmails.map((email) => ({
    ...email,
    is_primary: email.email_address === primaryEmail?.email_address,
  }));

  return mergedEmails;
};

mergeEmailAddresses.propTypes = {
  formValues: PropTypes.object.isRequired,
  comparison: PropTypes.object.isRequired,
  newestClientIndex: PropTypes.number.isRequired,
};
