import { consentIsPending } from 'src/components/InformedConsent/utils';
import _ from 'lodash';

export function openable(type = {}) {
  return _.get(type, 'permissions.can_open.authorization', false);
}

export function viewable(type = {}) {
  return _.get(type, 'permissions.can_view.authorization', false);
}

export function editable(type = {}, caseType = '', currentProviderId = '', isCoordinationGroup = false) {
  const canEdit = _.get(type, 'permissions.can_edit.authorization', false);
  const isNotExternalCase = !['closedExternalCases', 'externalCases', 'allExternalCases'].includes(caseType);
  const isOffPlatformCase = ['oonCases'].includes(caseType) && _.get(type, 'state', '') === 'off_platform';
  const isProviderMatch = currentProviderId ? _.isEqual(currentProviderId, _.get(type, 'provider.id', '')) : true;

  const canEditBasedOnCaseType = (isCoordinationGroup || isNotExternalCase || isOffPlatformCase);
  const canEditBasedOnProvider = (isProviderMatch || isCoordinationGroup);

  return canEdit && canEditBasedOnCaseType && canEditBasedOnProvider;
}

export function closeable(type = {}) {
  return _.get(type, 'permissions.can_close.authorization', false);
}

export function generatePermissionMessage(referral) {
  if (consentIsPending(referral.contact)) {
    return 'You will gain access to this referral after consent is received from the client.';
  }
  return 'You do not have permission to access this referral.';
}
