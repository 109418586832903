import React from 'react';
import { ENROLLMENT_STATUSES } from 'src/pages/enrollments/constants';
import EnrollmentWorkqueue from 'src/pages/enrollments/pages/EnrollmentWorkqueue';

const OpenEnrollments = () => (
  <EnrollmentWorkqueue
    showEnrollmentAging
    enrollmentStatus={ENROLLMENT_STATUSES.open}
    showSelectMultipleEnrollments
  />
);

export default OpenEnrollments;
