import { searchAPIClient as axios } from 'src/api/config';
import { SEARCH_API } from 'src/config/env/env.config';

export function retrieveTypeAheadCities(cityQuery = '', existingCities = [], includeState, pageSize = 10) {
    return axios.get('/cities/typeahead', {
        baseURL: SEARCH_API,
        params: {
            'filter[query]': cityQuery,
            'page[size]': pageSize,
         },
    }).then((response) => [
        ...response.data.data.map((city) => ({
            value: city.id,
            label: city.document.full_name,
            display_name: city.document.full_name,
            ...(includeState && { state: city.document.state }),
        })), ...existingCities]);
}

export function retrieveTypeAheadPostalCodes(postalCodeQuery = '', existingPostalCodes = []) {
    return axios.get('/postal_codes/typeahead', {
        baseURL: SEARCH_API,
        params: { 'filter[query]': postalCodeQuery },
    }).then((response) => [
        ...response.data.data.map((code) => ({
            value: code.id,
            label: code.document.postal_code,
            display_name: code.document.postal_code,
        })), ...existingPostalCodes]);
}

export function retrieveTypeAheadCounties(countyQuery = '', existingCounties = [], includeState, pageSize = 10) {
    return axios.get('/counties/typeahead', {
        baseURL: SEARCH_API,
        params: {
            'filter[query]': countyQuery,
            'page[size]': pageSize,
        },
    }).then((response) => [
        ...response.data.data.map((county) => ({
            value: county.id,
            label: county.document.full_name,
            display_name: county.document.full_name,
            ...(includeState && { state: county.document.state }),
        })), ...existingCounties]);
}
