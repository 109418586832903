import moment from 'moment';
import { TIME_FORMAT_DISPLAY } from "./constants";

/**
 * Get indexes of overlapping time slots.
 * @param {{ start_time: { value: string }, end_time: { value: string } }} dayTimeslots array of objects that represent time slots
 * @return {{ index: boolean }} indexes of time slots that overlap, i.e. {1: true}
 */
var getOverlaps = function getOverlaps(dayTimeslots) {
  var timeslots = dayTimeslots.map(function (item, index) {
    var start_time = item.start_time,
      end_time = item.end_time;
    return {
      start_time: (start_time === null || start_time === void 0 ? void 0 : start_time.value) || '',
      end_time: (end_time === null || end_time === void 0 ? void 0 : end_time.value) || '',
      index: index
    };
  }).filter(function (item) {
    return item.start_time && item.end_time;
  }).sort(function (a, b) {
    var dateA = moment(a.start_time, TIME_FORMAT_DISPLAY);
    var dateB = moment(b.start_time, TIME_FORMAT_DISPLAY);
    if (dateA.isSameOrBefore(dateB)) {
      return -1;
    }
    return 1;
  });
  var i = 0;
  var overlaps = {};
  while (i < timeslots.length - 1) {
    var j = i + 1;
    while (j < timeslots.length) {
      var momentA = moment(timeslots[j].start_time, TIME_FORMAT_DISPLAY);
      var momentB = moment(timeslots[i].end_time, TIME_FORMAT_DISPLAY);
      if (momentA.isBefore(momentB)) {
        overlaps[timeslots[j].index] = true;
      }
      j += 1;
    }
    i += 1;
  }
  return overlaps;
};
export default getOverlaps;