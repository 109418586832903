import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { caseCloseFields } from 'src/components/Cases/utils/fields';
import { resolvedOptions, resolvedOptionsYesNo } from 'src/components/Cases/utils/selectOptions';
import { CLOSE_CASE_FORM } from 'src/components/Cases/constants';
import * as CaseCloseHelper from 'src/components/Cases/utils/caseCloseHelpers';
import { closeServiceCase } from 'actions/Case/Contact/Group';
import { fetchGroupCaseOutcomes } from 'actions/Outcome/ServiceType/Group';
import { fetchNetworkCaseOutcomes } from 'actions/Outcome/ServiceType/Network';
import { setDashboardRefetch } from 'actions/Dashboard';
import { goToClosedCasesIndex } from 'src/components/Cases/utils/routing';
import _ from 'lodash';
import moment from 'moment';
import { validateReduxForm } from 'common/form';
import { validations } from '@unite-us/client-utils';
import { OverlaySpinner, Spinner } from 'common/spinners';
import getClosingParams from 'src/common/utils/Closing/getClosingParams';
import getOutcomesOptions from 'src/common/utils/Outcomes/utils/getOutcomesOptions';
import getOutcomesForServiceType from 'src/common/utils/Outcomes/utils/getOutcomesForServiceType';
import today from 'src/common/utils/today';
import callOrLog from 'src/common/utils/callOrLog';
import { SERVICE_CASE } from 'common/utils/EventTracker/utils/eventConstants';
import NoteDisclaimer from 'common/Disclaimer/NoteDisclaimer';
import {
  Button,
  SelectField,
  TextField,
  DateField,
  RadioField,
} from '@unite-us/ui';
import {
  crtb1349VariableAWebApp as crtb1349VariableAWebAppSelector,
  crtb1350VariableBWebApp as crtb1350VariableBWebAppSelector,
  crtb1351VariableCWebApp as crtb1351VariableCWebAppSelector,
  crtb824AddOpenCloseDateOffPlatformCase,
} from 'common/utils/FeatureFlags/flags';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './CaseCloseForm.scss';

export class CloseCase extends Component {
  constructor(props) {
    super(props);

    this.clearOutcomesValue = this.clearOutcomesValue.bind(this);
    this.fetchOutcomes = this.fetchOutcomes.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      showSkeleton: false,
      showFields: false,
    };
    this.clientName = _.get(this.props.contact, 'full_name', 'client name not found');
  }

  componentDidMount() {
    this.props.resetForm();
    const { serviceTypeId } = this.props;
    if (!_.isEmpty(serviceTypeId)) {
      this.fetchOutcomes();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.fields?.resolved?.value !== this.props?.fields?.resolved?.value) {
      if (this.props?.fields?.resolved?.value) {
        this.setState({ showSkeleton: true, showFields: false });
        setTimeout(() => {
          this.setState({ showSkeleton: false, showFields: true });
        }, 500);
      }
    }
  }

  onSubmit(values) {
    const {
      groupId,
      contact,
      caseToClose,
      invalidateQueries,
      addOpenCloseDateOffPlatformCase,
    } = this.props;

    const closingParams = getClosingParams(values, addOpenCloseDateOffPlatformCase);

    return this.props.closeServiceCase(groupId, contact.id, caseToClose, closingParams)
      .then(() => {
        invalidateQueries('notes');
        this.props.setDashboardRefetch();
        callOrLog(() => this.context.eventTracker(SERVICE_CASE.close, null, {
          serviceCase: caseToClose,
        }));
        goToClosedCasesIndex(_.get(caseToClose, 'state'), _.get(caseToClose, 'provider.id'), groupId);
      });
  }

  onCancel() {
    this.props.resetForm();
    this.props.closeDialog();
    this.setState({ showSkeleton: false, showFields: false });
  }

  getFieldValues(crtb1349VariableAWebApp, crtb1351VariableCWebApp, isOON) {
    const areVariablesON = crtb1349VariableAWebApp || crtb1351VariableCWebApp;

    const resolvedLabel = areVariablesON && !isOON ?
      `Did ${this.clientName} receive help?` :
      'Is Resolved?';

    const isResolvedOptions = areVariablesON && !isOON ?
      resolvedOptionsYesNo :
      resolvedOptions;

    const outcomeLabel = areVariablesON && !isOON ?
      'What was the outcome?' :
      'Outcome';

    return {
      resolvedLabel,
      isResolvedOptions,
      outcomeLabel,
    };
  }

  getMaxDateForOpenedDate() {
    const exited_at = moment.unix(this.props?.fields?.exited_at?.value).utc();
    return exited_at.isValid() ? exited_at.format('MM/DD/YYYY') : null;
  }

  getMinDateForExitedAt() {
    const opened_date = moment.unix(this.props?.fields?.opened_date?.value).utc();
    return opened_date.isValid() ? opened_date.subtract(1, 'day').format('MM/DD/YYYY') : null;
  }

  fetchOutcomes() {
    const { caseToClose, serviceTypeId } = this.props;
    if (caseToClose) {
      const applicable_states = [caseToClose.state];
      if (caseToClose.network) {
        const networkId = _.get(caseToClose, 'network.id');
        this.props.fetchNetworkCaseOutcomes(networkId, serviceTypeId, { applicable_states });
      } else {
        const groupId = _.get(caseToClose, 'group.id');
        this.props.fetchGroupCaseOutcomes(groupId, serviceTypeId, { applicable_states });
      }
    }
  }

  clearOutcomesValue() {
    this.props.fields.outcome_id.onChange();
  }

  render() {
    const {
      caseToClose,
      contact,
      contactCases,
      fields: {
        exited_at, opened_date, note, outcome_id, resolved,
      },
      handleSubmit,
      outcomes,
      registerField,
      submitting,
      addOpenCloseDateOffPlatformCase,
      crtb1349VariableAWebApp,
      crtb1350VariableBWebApp,
      crtb1351VariableCWebApp,
    } = this.props;

    const isOON = _.get(caseToClose, 'state') === 'off_platform';

    const {
      resolvedLabel,
      isResolvedOptions,
      outcomeLabel,
    } = this.getFieldValues(crtb1349VariableAWebApp, crtb1351VariableCWebApp, isOON);

    const shouldFixModalSize =
      !isOON &&
      (crtb1350VariableBWebApp || crtb1351VariableCWebApp) &&
      !this.state.showFields;

    if (_.isEmpty(contact) || _.isEmpty(caseToClose)) {
      return <Spinner />;
    }
    const minDate = _.get(caseToClose, 'opened_date') !== null ?
      moment.unix(_.get(caseToClose, 'opened_date') - 1).format('MM/DD/YYYY') :
      null;

    const dateInput = (
      <DateField
        field={exited_at}
        id="exitDateInput"
        label="Closed Date"
        ref={registerField}
        validations={validations.isRequired}
        inline={false}
        required
        maxDate={moment().utc().format('MM/DD/YYYY')}
        minDate={this.getMinDateForExitedAt() || minDate}
        customErrorMessage="Closed date cannot be later than today or before opened date"
      />
    );
    const dateOpened = moment.unix(_.get(caseToClose, 'opened_date')).format('MM/DD/YYYY');
    const dateDiv = CaseCloseHelper.showExitedAt(contactCases, _.get(caseToClose, 'id')) && !isOON ? dateInput : null;
    const off_platform_closed_date = isOON && addOpenCloseDateOffPlatformCase ? (
      <div className="date-div">
        <DateField
          field={opened_date}
          id="openedDateInput"
          label="Opened Date"
          ref={registerField}
          validations={validations.isRequired}
          inline={false}
          maxDate={this.getMaxDateForOpenedDate() || today()}
          value={dateOpened}
          customErrorMessage="Opened date cannot be later than closed date"
          required
        />
        {dateInput}
      </div>
    ) : null;

    return (
      <form
        onSubmit={handleSubmit(this.onSubmit)}
        className="content-with-actions"
      >
        <div className="content-container">
          <OverlaySpinner text="Closing Case..." show={submitting} />
          <div className={shouldFixModalSize ? 'fix-modal-size' : ''}>
            <SelectField
              field={resolved}
              id="resolvedInput"
              forceObjectValue
              label={resolvedLabel}
              onChange={this.clearOutcomesValue}
              options={isResolvedOptions}
              ref={registerField}
              required
              searchEnabled={false}
              validations={validations.isRequired}
              labelKey="label"
              valueKey="value"
            />

            {(!isOON && (crtb1350VariableBWebApp || crtb1351VariableCWebApp)) ? (
              <>
                {this.state.showSkeleton && (
                <>
                  <Skeleton height={30} width={200} style={{ marginTop: 10 }} />
                  {getOutcomesOptions(outcomes, _.get(resolved, 'value')).map((index) => (
                    <Skeleton
                      key={index}
                      height={25}
                      width={300}
                      style={{ marginTop: 10 }}
                    />
                  ))}
                  <Skeleton height={30} width={200} style={{ marginTop: 30 }} />
                  <Skeleton height={100} width={500} style={{ marginTop: 10 }} />
                  <Skeleton height={30} width={200} style={{ marginTop: 30 }} />
                </>
              )}
                {this.state.showFields && (
                <>
                  <RadioField
                    field={outcome_id}
                    id="outcomeInput"
                    label={outcomeLabel}
                    options={getOutcomesOptions(outcomes, _.get(resolved, 'value'))}
                    required
                    ref={registerField}
                    validations={validations.isRequired}
                  />
                  <TextField
                    field={note}
                    id="noteInput"
                    label="Note"
                    afterLabelContent={<NoteDisclaimer />}
                    ref={registerField}
                    validations={validations.isRequired}
                    required
                  />
                  {dateDiv}
                  {off_platform_closed_date}
                </>
              )}
              </>
            ) : (
              <>
                <SelectField
                  field={outcome_id}
                  id="outcomeInput"
                  forceObjectValue
                  label={outcomeLabel}
                  options={getOutcomesOptions(outcomes, _.get(resolved, 'value'))}
                  ref={registerField}
                  required
                  shouldSort={false}
                  validations={validations.isRequired}
                  labelKey="label"
                  valueKey="value"
                />
                <TextField
                  field={note}
                  id="noteInput"
                  label="Note"
                  afterLabelContent={<NoteDisclaimer />}
                  ref={registerField}
                  validations={validations.isRequired}
                  required
                />
                {dateDiv}
                {off_platform_closed_date}
              </>
            )}
          </div>
        </div>
        <div className="actions">
          <span className="action-item">
            <Button
              id="close-case-cancel-btn"
              label="Cancel"
              onClick={this.onCancel}
            />
          </span>
          <span className="action-item">
            <Button
              id="close-case-submit-btn"
              onClick={handleSubmit(this.onSubmit)}
              label="Close Case"
              disabled={submitting}
              primary
            />
          </span>
        </div>
      </form>
    );
  }
}

CloseCase.propTypes = {
  caseToClose: PropTypes.shape({
    id: PropTypes.string.isRequired,
    network: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    group: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    state: PropTypes.string,
  }).isRequired,
  closeDialog: PropTypes.func.isRequired,
  closeServiceCase: PropTypes.func.isRequired,
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
    full_name: PropTypes.string.isRequired,
  }).isRequired,
  contactCases: PropTypes.arrayOf(
    PropTypes.shape({
      program: PropTypes.object.isRequired,
    }).isRequired,
  ),
  fetchGroupCaseOutcomes: PropTypes.func.isRequired,
  fetchNetworkCaseOutcomes: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  outcomes: PropTypes.array,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  serviceTypeId: PropTypes.string.isRequired,
  setDashboardRefetch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalidateQueries: PropTypes.func.isRequired,
  addOpenCloseDateOffPlatformCase: PropTypes.bool,
  crtb1349VariableAWebApp: PropTypes.bool,
  crtb1350VariableBWebApp: PropTypes.bool,
  crtb1351VariableCWebApp: PropTypes.bool,
};

CloseCase.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

CloseCase.defaultProps = {
  contactCases: [],
  outcomes: [],
  addOpenCloseDateOffPlatformCase: false,
  crtb1349VariableAWebApp: false,
  crtb1350VariableBWebApp: false,
  crtb1351VariableCWebApp: false,
};

function mapStateToProps(state, ownProps) {
  const contact = ownProps.contact;
  const contactCaseObj = _.get(state.serviceCase, contact.id);
  const contactCases = _.get(contactCaseObj, 'data', []);
  const caseToClose = ownProps.serviceCase;
  const isNetworkCase = !_.isEmpty(caseToClose.network);

  const serviceTypeOutcomes = _.get(state, [
    'outcomes',
    isNetworkCase ? 'networkOutcomes' : 'groupOutcomes',
  ], []);

  const serviceTypeId = _.get(caseToClose, 'service_type.id');
  const openedDate = _.get(caseToClose, 'opened_date');
  const outcomes = getOutcomesForServiceType(serviceTypeId, serviceTypeOutcomes);

  return {
    groupId: _.get(state, 'session.groupId'),
    contact,
    contactCases,
    caseToClose,
    crtb1349VariableAWebApp: crtb1349VariableAWebAppSelector(state),
    crtb1350VariableBWebApp: crtb1350VariableBWebAppSelector(state),
    crtb1351VariableCWebApp: crtb1351VariableCWebAppSelector(state),
    outcomes,
    initialValues: {
      exited_at: today(),
      opened_date: openedDate,
    },
    serviceTypeId,
    addOpenCloseDateOffPlatformCase: crtb824AddOpenCloseDateOffPlatformCase(state),
  };
}

export default validateReduxForm({
  form: CLOSE_CASE_FORM,
  fields: caseCloseFields,
}, mapStateToProps, {
  closeServiceCase,
  fetchGroupCaseOutcomes,
  fetchNetworkCaseOutcomes,
  setDashboardRefetch,
})(CloseCase);
