import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { get, isEmpty, noop } from 'lodash';
import {
  hasInvoicesAccess,
  hasPaysClaimsCodes,
  hasPayerInvoicesRole,
} from 'src/common/utils/FeatureFlags/flags';
import { connect } from 'react-redux';
import { dollarAmount } from 'src/common/display/Money/DollarAmount';
import InvoiceDetailSingleCard from 'src/pages/invoices/components/InvoiceDetailSingleCard';
import { useIsCBOProvider } from 'common/contexts/CurrentProviderContext';
import { useFind, usePopulate, useFindRecord } from 'src/api/APIHooks';
import formatDate from 'src/common/utils/Dates/formatDate';
import moment from 'moment';
import { INSURANCE_STATUSES } from 'src/components/Insurance/constants';
import {
  useInvoiceVersions,
  useProvidedServiceToFindInvoices,
  usePlaceOfService,
  useZcodes,
  useProcedureCodes,
  useProcedureCodeModifiers,
} from '../hooks';
import isMostRecentInvoice from '../utils/isMostRecentInvoice';
import InvoiceDrawer from './InvoiceDrawer';

export const InvoiceDetails = ({
  currentEmployee,
  deselectAll,
  fullInvoiceView,
  getNextInvoice,
  getPrevInvoice,
  groupId,
  invoice,
  isNetworkLead,
  networkId,
  onClose,
  path,
  payerWQInteractiveView,
  showClaimsCodes,
  showPayerInvoices,
  useInvoiceUserRole,
}) => {
  usePopulate('invoice_rejection_reason', 'invoice_rejection_reason', invoice, {
    queryConfig: { placeholderData: undefined },
  });
  usePopulate('invoice_dispute_reason', 'invoice_dispute_reason', invoice, {
    queryConfig: { placeholderData: undefined },
  });
  usePopulate('invoice_dispute_resolution_reason', 'invoice_dispute_resolution_reason', invoice, {
    queryConfig: { placeholderData: undefined },
  });
  usePopulate(
    'provided_service',
    'provided_service',
    invoice,
    { queryConfig: { placeholderData: undefined } },
  );
  const insuranceFromServiceAuthorization =
    usePopulate('service_authorization.insurance', 'insurance', invoice, {
      queryConfig: {
        enabled: !!invoice?.service_authorization?.insurance,
        placeholderData: undefined,
      },
    });

  const { data: previousInvoiceAPIData } = useFind(
    'invoices',
    {
      provider: groupId,
      original_version: invoice.original_version?.id,
      created_before: invoice.created_at,
    },
    {
      queryConfig: {
        enabled: !!invoice.original_version,
        placeholderData: undefined,
      },
    },
  );

  const invoicesFromProvidedServiceId = useProvidedServiceToFindInvoices(invoice.provided_service.id);
  const isMostRecent = isMostRecentInvoice(invoicesFromProvidedServiceId, invoice);
  const isCBOProvider = useIsCBOProvider();

  const { data: feeScheduleResponse } = useFindRecord('fee_schedule', invoice.fee_schedule_id, {
    queryConfig: { placeholderData: undefined },
  });
  const feeSchedule = get(feeScheduleResponse, 'data.data', {});

  let insurance;
  insurance = get(invoice, 'service_authorization.insurance');
  const planId = get(invoice, 'provided_service.plan.id');
  const clientId = get(invoice, 'client_id');
  const insuranceData = useFind(
    'insurance',
    {
      plan: planId,
      person: clientId,
    },
    {
      queryConfig: {
        enabled: !!(!insurance && isMostRecent && planId && clientId),
        placeholderData: undefined,
      },
    },
  );

  const insuranceLoaded = get(insuranceData, 'isSuccess') || get(insuranceFromServiceAuthorization, 'isSuccess');

  if (!insurance) {
    const insurances = get(insuranceData, 'data.data.data', []);
    const candidateInsurances = insurances.filter((ins) => {
      const startsBeforeServiceStarts = new Date(ins.enrolled_at) <= new Date(invoice.provided_service_starts_at);
      const notExpiredOrIgnore = feeSchedule.ignore_social_care_expired_dates ||
        !ins.expired_at || new Date(ins.expired_at) >= new Date(invoice.provided_service_ends_at);
      return startsBeforeServiceStarts && notExpiredOrIgnore;
    });
    const sortByEnrollmentDateDesc = (a, b) => new Date(b.enrolled_at) - new Date(a.enrolled_at);

    if (candidateInsurances.length) {
      insurance = candidateInsurances.sort(sortByEnrollmentDateDesc)[0];
    } else if (insurances.length) {
      insurance = insurances.sort(sortByEnrollmentDateDesc)[0];
    } else {
      insurance = null;
    }
  }

  if (insurance) {
    const providedServiceStartDate = moment(invoice?.provided_service_starts_at);
    const providedServiceEndDate =
      invoice?.provided_service_ends_at ? moment(invoice?.provided_service_ends_at) : providedServiceStartDate;
    const insuranceStartDate = moment(insurance?.enrolled_at);
    const insuranceEndDate = insurance?.expired_at && moment(insurance.expired_at);
    if (providedServiceStartDate.isSameOrAfter(insuranceStartDate) && (
      !insuranceEndDate || providedServiceEndDate.isSameOrBefore(insuranceEndDate)
      )
    ) {
      insurance.insurance_status = INSURANCE_STATUSES.enrolled;
    }
  }

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const previouslyRejectedInvoice = !isEmpty(get(invoice, 'invoice_rejection_reason'));
  const networkLeadSubmitButtonDisabled = (isNetworkLead && previouslyRejectedInvoice);
  const disableInvoiceSubmitButton = networkLeadSubmitButtonDisabled || disableSubmitButton;

  const { data: fileResponse, isFetching, isLoading } = useFind(
    'file_upload',
    {
      'record.type': 'provided_service',
      record: invoice.provided_service.id,
    },
    {
      queryConfig: { placeholderData: undefined },
    },
  );

  const providedServiceId = get(invoice, 'provided_service.id', '');
  const providedServiceQuery = useFindRecord('provided_service', providedServiceId, {
    queryConfig: { enabled: showClaimsCodes && !!providedServiceId, placeholderData: undefined },
  });
  const { data: providedServiceData } = providedServiceQuery;
  const providedService = get(providedServiceData, 'data.data', {});

  const placeOfServiceId = get(providedService, 'place_of_service.id', '');
  const zCodeIds = get(providedService, 'zcodes', []).map((zcode) => zcode.id);
  const procedureCodeIds = get(providedService, 'procedure_codes', []).map((pCode) => pCode.id);
  const procedureCodeModifierIds = get(providedService, 'procedure_code_modifiers', []).map((pcmCode) => pcmCode.id);

  const placeOfService = usePlaceOfService(placeOfServiceId, showClaimsCodes);
  const zCodes = useZcodes(zCodeIds, showClaimsCodes);
  const procedureCodes = useProcedureCodes(procedureCodeIds, showClaimsCodes);
  const procedureCodeModifiers = useProcedureCodeModifiers(procedureCodeModifierIds, showClaimsCodes);

  const { data: versions } = useInvoiceVersions(invoice.id);

  const previousInvoices = get(previousInvoiceAPIData, 'data.data', []);

  const files = get(fileResponse, 'data.data', []);
  const providerAddress = invoice.provider_address ? invoice.provider_address : {};

  const metadata = invoice.provided_service_metadata || [];
  const serviceDescription = get(
    metadata.find(({ field }) => field === 'service_description'),
    'value',
    '',
  );

  const totalAmountInvoiced = invoice.total_amount_invoiced ?
    dollarAmount(invoice.total_amount_invoiced, true) : '';
  const totalAmountPaid = invoice.amount_paid ?
    dollarAmount(invoice.amount_paid, true) : '';
  const displayAmount = (!isEmpty(totalAmountPaid) && (invoice.invoice_status === 'paid')) ?
    totalAmountPaid : totalAmountInvoiced;

  const resolved = get(invoice, 'invoice_dispute_resolution_reason.id');
  const showFixAndMakeNewInvoice = get(invoice, 'invoice_dispute_resolution_reason.display_fix_invoice') &&
    resolved &&
    !isNetworkLead &&
    !showPayerInvoices;

  const handleDisableSubmitButton = (value) => {
    setDisableSubmitButton(value);
  };

  if (fullInvoiceView) {
    return (
      <InvoiceDetailSingleCard
        displayAmount={displayAmount}
        createdAt={formatDate(invoice.created_at, false)}
        currentEmployee={currentEmployee}
        disableSubmitButton={disableInvoiceSubmitButton}
        feeSchedule={feeSchedule}
        files={files}
        insurance={insurance}
        invoice={invoice}
        invoicesFromProvidedServiceId={invoicesFromProvidedServiceId}
        isFetching={isFetching}
        isLoading={isLoading}
        insuranceLoaded={insuranceLoaded}
        isNetworkLead={isNetworkLead}
        isCBOProvider={isCBOProvider}
        metadata={metadata}
        onDisableSubmitButtonChange={handleDisableSubmitButton}
        previousInvoices={previousInvoices}
        providerAddress={providerAddress}
        providerId={groupId}
        placeOfService={placeOfService}
        procedureCodes={procedureCodes}
        procedureCodeModifiers={procedureCodeModifiers}
        serviceDescription={serviceDescription}
        showFixAndMakeNewInvoice={showFixAndMakeNewInvoice}
        totalAmountInvoiced={totalAmountInvoiced}
        totalAmountPaid={totalAmountPaid}
        useInvoiceUserRole={useInvoiceUserRole}
        versions={versions}
        zCodes={zCodes}
        payerWQInteractiveView={payerWQInteractiveView}
      />
    );
  }

  return (
    <InvoiceDrawer
      deselectAll={deselectAll}
      getNextInvoice={getNextInvoice}
      getPrevInvoice={getPrevInvoice}
      invoice={invoice}
      isNetworkLead={isNetworkLead}
      onClose={onClose}
      path={path}
      showClaimsCodes={showClaimsCodes}
      showPayerInvoices={showPayerInvoices}
      useInvoiceUserRole={useInvoiceUserRole}
      disableSubmitButton={disableInvoiceSubmitButton}
      files={files}
      isFetching={isFetching}
      isLoading={isLoading}
      isCBOProvider={isCBOProvider}
      insuranceLoaded={insuranceLoaded}
      providerAddress={providerAddress}
      placeOfService={placeOfService}
      procedureCodes={procedureCodes}
      procedureCodeModifiers={procedureCodeModifiers}
      serviceDescription={serviceDescription}
      showFixAndMakeNewInvoice={showFixAndMakeNewInvoice}
      totalAmountInvoiced={totalAmountInvoiced}
      totalAmountPaid={totalAmountPaid}
      versions={versions}
      zCodes={zCodes}
      networkId={networkId}
      payerWQInteractiveView={payerWQInteractiveView}
      currentEmployee={currentEmployee}
      groupId={groupId}
      handleDisableSubmitButton={handleDisableSubmitButton}
      insurance={insurance}
      feeSchedule={feeSchedule}
      isMostRecent={isMostRecent}
    />
  );
};

InvoiceDetails.propTypes = {
  currentEmployee: PropTypes.object.isRequired,
  fullInvoiceView: PropTypes.bool,
  getNextInvoice: PropTypes.func,
  getPrevInvoice: PropTypes.func,
  groupId: PropTypes.string.isRequired,
  invoice: PropTypes.object.isRequired,
  isNetworkLead: PropTypes.bool,
  networkId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  showClaimsCodes: PropTypes.bool,
  showPayerInvoices: PropTypes.bool,
  useInvoiceUserRole: PropTypes.bool,
  payerWQInteractiveView: PropTypes.bool,
  deselectAll: PropTypes.func,
  path: PropTypes.string.isRequired,
};

InvoiceDetails.defaultProps = {
  getNextInvoice: undefined,
  getPrevInvoice: undefined,
  fullInvoiceView: false,
  isNetworkLead: false,
  onClose: noop,
  showClaimsCodes: false,
  showPayerInvoices: false,
  useInvoiceUserRole: false,
  payerWQInteractiveView: false,
  deselectAll: () => {},
};

InvoiceDetails.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const groupId = state.session.groupId;
  const useInvoiceUserRole = hasInvoicesAccess(state);
  const networkId = state.networks.networkId;
  const currentEmployee = get(state, 'globalState.currentEmployee', {});

  return {
    currentEmployee,
    groupId,
    networkId,
    useInvoiceUserRole,
    showClaimsCodes: hasPaysClaimsCodes(state),
    showPayerInvoices: hasPayerInvoicesRole(state),
  };
}

export default connect(mapStateToProps)(InvoiceDetails);
